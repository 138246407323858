import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { ProductFilteringService } from '../../../services/ProductServices/ProductFilteringService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { NAME_FILTER, NAME_FILTER_ID } from '../../../provider/cloudshelf/filter/CloudshelfFilters';
import { BarcodeDetectionMethod, FilterType } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { RoutesHelperService } from '../../../services/RoutesService/RoutesHelperService';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import { BasketService } from '../../../services/BasketService/BasketService';
import _ from 'lodash';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export const BarcodeInput: FunctionalComponentWithChildren = props => {
    const history = useHistory();
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);

    const [barcodeDetectionMethod, setBarcodeDetectionMethod] = useState<BarcodeDetectionMethod>(
        BarcodeDetectionMethod.None,
    );
    const [barcodePrefix, setBarcodePrefix] = useState<string>('');
    const [barcodePostfix, setBarcodePostfix] = useState<string>('');
    const currentKeydownBarcode = useRef<string>('');
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const barcodeDetectionMethod = configService.config()?.barcodeDetectionMethod;
        if (barcodeDetectionMethod) {
            setBarcodeDetectionMethod(barcodeDetectionMethod);
        }

        const barcodePrefix = configService.providerConfig().find(c => c.key === 'BARCODE_PREFIX');
        if (barcodePrefix) {
            if (barcodePrefix.values.length > 0) {
                setBarcodePrefix(barcodePrefix.values[0]);
            }
        }

        const barcodePostfix = configService.providerConfig().find(c => c.key === 'BARCODE_POSTFIX');
        if (barcodePostfix) {
            if (barcodePostfix.values.length > 0) {
                setBarcodePostfix(barcodePostfix.values[0]);
            }
        }
    }, []);

    //setup the function to be called when a barcode is scanned
    (window as any).barcodeScanResult = async function barcodeScanResult(barcode: string) {
        //This used to use matchingVariants, but we have removed that in favour of matchingProducts... so we wont add to basket directly anymore.
        //I know giles will want this back, but we need to do it in a different way, and I dont have the time to do it right now.
        const productResult = await filteringService.matchingProducts(
            'Barcode Input',
            null,
            [
                {
                    definitionId: NAME_FILTER_ID,
                    mergeDefinitionId: NAME_FILTER_ID,
                    name: NAME_FILTER,
                    type: FilterType.ProductTitle,
                    values: [barcode],
                },
            ],
            { limit: 1 },
            false,
        );

        if (productResult.items.length === 0) {
            console.log('No products found for barcode: ' + barcode);
            return;
        } else {
            if (productResult.items.length > 1) {
                console.log('Multiple products found for barcode: ' + barcode);
                filteringService.setStringValue(NAME_FILTER_ID, NAME_FILTER, FilterType.ProductTitle, barcode, true);
                filteringService.commitSelection();
                const urlForProducts = RoutesHelperService.toCategoryProductsViaHandle('INTERNAL_ALL');
                history.push(urlForProducts);
            } else {
                const product = productResult.items[0];
                const urlForProducts = RoutesHelperService.toProductDetailViaHandle(product.handle);
                history.push(urlForProducts);
            }
        }

        //how we used to add to basket
        // const existingQuantity = await basketService.getItemQuantity(localProductVariant.id, []);
        // await basketService.setItemQuantity(localProduct, localProductVariant, existingQuantity + 1, []);
    };

    //This is the Android Kisok Zerba stuff, commented out for now
    // useEffect(() => {
    //     //Register to get barcode scan results
    //     if (typeof (window as any).Android != 'undefined') {
    //         //tell KB to return results via JS function
    //         (window as any).Android.useJavaScriptCallbackZebraScanner(true);
    //
    //         // const extras = [['com.symbol.datawedge.api.SOFT_SCAN_TRIGGER', 'TOGGLE_SCANNING']];
    //         // const result = (window as any).Android.broadcastIntent(
    //         //     'com.symbol.datawedge.api.ACTION',
    //         //     true,
    //         //     extras.toString(),
    //         // );
    //         // alert('intent sent:');
    //     }
    //
    //     return () => {
    //         //unregister from getting barcode scan results
    //         (window as any).Android.useJavaScriptCallbackZebraScanner(false);
    //     };
    // }, []);

    //a typescript function called handlePasteEvent that will handle th epaste event
    const handlePasteEvent = async (e: ClipboardEvent) => {
        //get the clipboard data as text
        const text = e.clipboardData?.getData('text');

        console.log(
            `[BarcodeInput] handlePasteEvent. Event Text: ${text}. Required Prefix: ${barcodePrefix}. Required Postfix: ${barcodePostfix}`,
        );
        let startsWith = true;

        if (barcodePrefix !== '' && !text?.startsWith(barcodePrefix)) {
            startsWith = false;
        }

        let endsWith = true;

        if (barcodePostfix !== '' && !text?.endsWith(barcodePostfix)) {
            endsWith = false;
        }

        if (!startsWith || !endsWith) {
            console.log('paste does not match required prefix or postfix for barcodes');
            return;
        }

        const barcode = text?.replace(barcodePrefix, '').replace(barcodePostfix, '');

        //call the barcodeScanResult function with the text
        (window as any).barcodeScanResult(barcode);
    };

    //a typescript function called handleKeyPress that will handle the keydown event, and return the keys that pre pressed between a the barcodePrefix and barcodePostfix and reset the current keys if more than 500ms passes and the postfix is not pressed
    const handleKeyPress = async (e: KeyboardEvent) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        const key = e.key;
        const isAlphanumericOrSymbol = /^[a-zA-Z0-9!=@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/.test(key);

        if (e.target instanceof HTMLInputElement) {
            currentKeydownBarcode.current = '';
            return;
        }

        if (isAlphanumericOrSymbol) {
            //if the key is not the barcodePrefix or barcodePostfix, then add the key to the currentKeydownBarcode
            currentKeydownBarcode.current += key;
            console.log('currentKeydownBarcode: ' + currentKeydownBarcode.current);
        }

        //if the key is not the barcodePrefix or barcodePostfix, then set a timeout for 500ms to reset the currentKeydownBarcode
        timeoutRef.current = setTimeout(() => {
            console.log(
                `[BarcodeInput] keypressTimeout. Event Text: ${currentKeydownBarcode.current}. Required Prefix: ${barcodePrefix}. Required Postfix: ${barcodePostfix}`,
            );
            let startsWith = true;
            if (barcodePrefix !== '' && !currentKeydownBarcode.current?.startsWith(barcodePrefix)) {
                startsWith = false;
            }

            let endsWith = true;
            if (barcodePostfix !== '' && !currentKeydownBarcode.current?.endsWith(barcodePostfix)) {
                endsWith = false;
            }

            if (!startsWith || !endsWith) {
                console.log('timeout does not match required prefix or postfix for barcodes');
                currentKeydownBarcode.current = '';
                return;
            }

            const barcode = currentKeydownBarcode.current?.replace(barcodePrefix, '').replace(barcodePostfix, '');

            (window as any).barcodeScanResult(barcode);
            currentKeydownBarcode.current = '';
        }, 500);
    };

    useEffect(() => {
        if (configService.config()?.barcodeDetectionMethod === BarcodeDetectionMethod.Keyboard) {
            document.addEventListener('paste', handlePasteEvent);
            document.addEventListener('keydown', handleKeyPress);
        }

        return () => {
            if (configService.config()?.barcodeDetectionMethod === BarcodeDetectionMethod.Keyboard) {
                document.removeEventListener('paste', handlePasteEvent);
                document.removeEventListener('keydown', handleKeyPress);
            }
        };
    }, [configService, barcodePrefix, barcodePostfix]);

    return <></>;
};
