import _ from 'lodash';
import { FilterableProduct, FilterableProductVariant } from './FilterableProductTypes';

export enum ProductVariantAvailability {
    Unavailable,
    InStock,
    LimitedAvailability,
    OnOrder,
}

export const getVariantAvailability = (variant: FilterableProductVariant): ProductVariantAvailability => {
    let productAvailability: ProductVariantAvailability = ProductVariantAvailability.Unavailable;

    if (!variant.availableForSale) {
        productAvailability = ProductVariantAvailability.Unavailable;
    } else if (variant.currentlyNotInStock) {
        productAvailability = ProductVariantAvailability.OnOrder;
    } else if (variant.availableForSale) {
        productAvailability = ProductVariantAvailability.InStock;
    }

    return productAvailability;
};

export const getProductAvailability = (product: FilterableProduct): ProductVariantAvailability => {
    let productAvailability: ProductVariantAvailability = ProductVariantAvailability.Unavailable;

    const variants = product.variants;

    const availableForSale = _.some(variants, variant => variant.availableForSale);
    const limitedAvailability = !_.every(variants, variant => variant.availableForSale);
    const orderOnly = _.every(variants, variant => variant.currentlyNotInStock);

    if (!availableForSale) {
        productAvailability = ProductVariantAvailability.Unavailable;
    } else if (limitedAvailability) {
        productAvailability = ProductVariantAvailability.LimitedAvailability;
    } else if (orderOnly) {
        productAvailability = ProductVariantAvailability.OnOrder;
    } else if (availableForSale) {
        productAvailability = ProductVariantAvailability.InStock;
    }

    return productAvailability;
};
