import './index.scss';
import 'reflect-metadata';
import 'finally-polyfill';
import './services/I18n/I18n';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import '@sentry/tracing';
import Loader from './components/shared/Loader/Loader';
import { createBrowserHistory, History } from 'history';
import { getCloudshelfInfo, getPayloadTypeBasedOnURL, getShouldRenderVersionApp } from './hooks/UseCloudshelfInfo';
import * as Sentry from '@sentry/react';
import StrippedApp from './components/apps/StrippedApp/StrippedApp';
import { BrowserTracing } from '@sentry/tracing';
import {
    clearContainer,
    dependenciesContainer,
    initConfigService,
    initDependencies,
} from './dependancyInjection/DependenciesInitializer';
import { getDeviceInfo } from './hooks/UseDeviceInfo';
import { ConfigurationService } from './services/ConfigurationService/ConfigurationService';
import DependencyType from './dependancyInjection/DependencyType';
import { VersionCheckerResponse } from './workers/versionCheck/VersionChecker.WorkerPayloads';
import _, { debounce } from 'lodash';
import { SentryUtil } from './utils/Sentry.Util';
import { LogUtil } from './utils/Logging.Util';
import { CloudshelfEngineConfig } from './services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { setSafariIconMask } from './utils/Safari.Util';
import { setManifest } from './utils/Manifest.Util';
import { configureCloudshelfTheme } from './utils/CloudshelfTheme.Util';
import BaseApp from './components/apps/BaseApp/BaseApp';
import PairingApp from './components/apps/PairingApp/PairingApp';
import InteractiveApp from './components/apps/InteractiveApp/InteractiveApp';
import HandoffApp from './components/apps/HandoffApp/HandoffApp';
import RequiredStyles from './RequiredStyles';
import FullPageErrorComponent from './components/shared/FullPageError/FullPageError';
import { calculateDPI, setupOneVh, setupResponsiveUnits } from './utils/Responsive.Util';
import {
    CloudshelfPayloadStatus,
    CloudshelfPayloadType,
    EngineType,
} from './provider/cloudshelf/graphql/generated/cloudshelf_types';
import registerServiceWorker from './registerServiceWorker';
import { CloudshelfBridge } from './utils/CloudshelfBridge.Utils';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

import { CustomEase } from 'gsap/CustomEase';
import { RoughEase, ExpoScaleEase, SlowMo } from 'gsap/EasePack';

/* The following eases are Club GSAP perks */
import { CustomBounce } from 'gsap/CustomBounce'; // extends CustomEase
import { CustomWiggle } from 'gsap/CustomWiggle'; // extends CustomEase

import { Flip } from 'gsap/Flip';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Observer } from 'gsap/Observer';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Draggable } from 'gsap/Draggable';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { EaselPlugin } from 'gsap/EaselPlugin';
import { PixiPlugin } from 'gsap/PixiPlugin';
import { TextPlugin } from 'gsap/TextPlugin';

/* The following plugins are Club GSAP perks */
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { GSDevTools } from 'gsap/GSDevTools';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import { MotionPathHelper } from 'gsap/MotionPathHelper';
import { Physics2DPlugin } from 'gsap/Physics2DPlugin';
import { PhysicsPropsPlugin } from 'gsap/PhysicsPropsPlugin';
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(
    useGSAP,
    Flip,
    ScrollTrigger,
    Observer,
    ScrollToPlugin,
    Draggable,
    MotionPathPlugin,
    EaselPlugin,
    PixiPlugin,
    TextPlugin,
    DrawSVGPlugin,
    ScrollSmoother,
    GSDevTools,
    InertiaPlugin,
    MorphSVGPlugin,
    MotionPathHelper,
    Physics2DPlugin,
    PhysicsPropsPlugin,
    ScrambleTextPlugin,
    SplitText,
    RoughEase,
    ExpoScaleEase,
    SlowMo,
    CustomEase,
    CustomBounce,
    CustomWiggle,
);

let history: History;
let IS_APPLICATION_VISIBLE = true;
let uptimeWorker: Worker | undefined = undefined;
let versionCheckerWorker: Worker | undefined = undefined;
// let preloadContentWorker: Worker | undefined = undefined;

const ERROR_RELOAD_INTERVAL_MS = 15 * 1000; // 5 seconds
const UPDATE_CONFIG_INTERVAL_MS = 120 * 1000; //120 seconds (2 minutes)
const UPDATE_CONFIG__NO_CLOUDSHELF_INTERVAL_MS = 5 * 1000; //5 seconds
export let SCREEN_SIZE = 7;

let configInterval: NodeJS.Timeout | undefined = undefined;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// export const startPreloadContentWorker = (tileSize: number, imageAnchor: string) => {
//     if (preloadContentWorker) {
//         preloadContentWorker.postMessage({
//             tileSize,
//             imageAnchor,
//         });
//     }
// };

const reactRender = (component: JSX.Element, appType: 'baseApp' | 'strippedApp' | 'loadingApp' = 'baseApp') => {
    if (appType === 'baseApp') {
        root.render(
            <>
                <Suspense fallback={null}>
                    <RequiredStyles />
                </Suspense>
                <Suspense fallback={<Loader variant="stars" />}>
                    <BaseApp history={history}>{component}</BaseApp>
                </Suspense>
            </>,
        );
    } else if (appType === 'strippedApp') {
        root.render(
            <>
                <Suspense fallback={null}>
                    <RequiredStyles />
                </Suspense>
                <Suspense fallback={<Loader variant="stars" />}>
                    <StrippedApp history={history}>{component}</StrippedApp>
                </Suspense>
            </>,
        );
    } else if (appType === 'loadingApp') {
        root.render(
            <>
                <Suspense fallback={null}>
                    <RequiredStyles />
                </Suspense>
                <Suspense fallback={<Loader variant="stars" />}>
                    <>{component}</>
                </Suspense>
            </>,
        );
    }

    registerServiceWorker();
};

function postMessageToUptime() {
    if (uptimeWorker) {
        const localDeviceInfo = getDeviceInfo();

        let width = 0;
        let height = 0;

        if (window.visualViewport) {
            width = window.visualViewport.width;
            height = window.visualViewport.height;
        } else {
            width = window.innerWidth;
            height = window.innerHeight;
        }

        const userAgent = window.navigator.userAgent;

        const payloadType = getPayloadTypeBasedOnURL();

        if (localDeviceInfo && !_.isEmpty(localDeviceInfo.id) && payloadType === CloudshelfPayloadType.Device) {
            uptimeWorker.postMessage({
                applicationVisible: IS_APPLICATION_VISIBLE,
                deviceId: localDeviceInfo.id,
                userAgent: userAgent,
                screenWidth: width,
                screenHeight: height,
            });
        }
    }
}

export function getDeviceDPI() {
    return calculateDPI(SCREEN_SIZE, window.innerWidth, window.innerHeight);
}

function handleVisibilityChange() {
    IS_APPLICATION_VISIBLE = document.visibilityState === 'visible';
    postMessageToUptime();
}

const handlePageVisibility = (shown: boolean) => {
    IS_APPLICATION_VISIBLE = shown;
    postMessageToUptime();
};

const renderApp = (
    isDevice: boolean,
    deviceNeedsPairing: boolean,
    status: CloudshelfPayloadStatus | undefined,
    config: CloudshelfEngineConfig | undefined,
) => {
    setSafariIconMask();

    if (!config) {
        throw new Error('Cloudshelf Configuration did not load');
    } else {
        SCREEN_SIZE = config.screenSize;
        setupResponsiveUnits(getDeviceDPI(), 'App Render');
        if (config.inMaintenanceMode) {
            reactRender(<FullPageErrorComponent variant="maintenance" />, 'strippedApp');
        }
        // we need to check if we are online as giles wanted to remove these checks as we should
        // now just allow cloudshelf to run in an offline state
        // else if (!isOnline) {
        //     reactRender(<FullPageErrorComponent variant="noConnection" />, 'strippedApp');
        // }
        else if (status === CloudshelfPayloadStatus.Frozen) {
            reactRender(<FullPageErrorComponent variant="frozen" />, 'strippedApp');
        } else if (isDevice && (deviceNeedsPairing || window.location.pathname === '/new')) {
            setManifest(isDevice);
            reactRender(<PairingApp />, 'strippedApp');
        } else {
            //Okay, so we are online and we are not in maintenance mode & we are not a device that needs pairing
            if (status === CloudshelfPayloadStatus.DeviceWithoutLocation) {
                reactRender(<FullPageErrorComponent variant="noLocation" />, 'strippedApp');
            } else if (status === CloudshelfPayloadStatus.Notfound) {
                reactRender(<FullPageErrorComponent variant="notFoundCloudshelf" />, 'strippedApp');
            } else if (status === CloudshelfPayloadStatus.DeviceNoCloudshelf) {
                reactRender(<FullPageErrorComponent variant="deviceNoCloudshelf" />, 'strippedApp');
            } else if (status === CloudshelfPayloadStatus.DeviceRemoved) {
                reactRender(<PairingApp />, 'strippedApp');
            } else if (
                status === CloudshelfPayloadStatus.DeviceWithCloudshelf ||
                status === CloudshelfPayloadStatus.CloudshelfPreview ||
                status === CloudshelfPayloadStatus.Cached ||
                status === CloudshelfPayloadStatus.MobileHandoff
            ) {
                setManifest(isDevice, config.normalizedName, config.theme.primaryColor);
                configureCloudshelfTheme(config);

                if (status === CloudshelfPayloadStatus.MobileHandoff) {
                    reactRender(<HandoffApp />);
                } else {
                    if (config.deviceMode === EngineType.Interactive) {
                        reactRender(<InteractiveApp />);
                    } else if (config.deviceMode === EngineType.DisplayOnly) {
                        reactRender(
                            <FullPageErrorComponent
                                variant="custom"
                                title="Display Mode"
                                subtitle="This feature is no longer supported. Please use the manager app to switch to interactive mode and use the attract loop to display content."
                            />,
                            'strippedApp',
                        );
                    } else {
                        throw new Error('Device Mode did not match any required conditions');
                    }
                }
            } else {
                throw new Error('Cloudshelf Configuration Status did not match any required conditions');
            }
        }
    }
};

(async () => {
    try {
        const shouldRenderVersionApp = getShouldRenderVersionApp();

        if (shouldRenderVersionApp) {
            reactRender(<>{process.env.REACT_APP_PACKAGE_VERSION}</>, 'loadingApp');
            return;
        }

        const cloudshelfInfo = getCloudshelfInfo();
        history = createBrowserHistory({
            basename:
                cloudshelfInfo.payloadType === CloudshelfPayloadType.Device
                    ? ''
                    : `/${cloudshelfInfo.cloudshelfOrDeviceId}`,
        });

        let sentryReleaseType = process.env.REACT_APP_RELEASE_TYPE_SENTRY;

        if (!sentryReleaseType) {
            sentryReleaseType = process.env.REACT_APP_RELEASE_TYPE;
        }

        console.log('Using Sentry Release Type', sentryReleaseType);

        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                new BrowserTracing({
                    tracingOrigins: ['localhost', /^\//, process.env.REACT_APP_BACKEND_HOST ?? ''],
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                }),
            ],
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.',
                'You reached Weglot limitation. Please upgrade your plan.',
            ],
            environment: sentryReleaseType,
            release: process.env.REACT_APP_PACKAGE_VERSION,
            sampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLERATE ?? '1.0'),
            tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACESAMPLERATE ?? '1.0'),
        });

        SentryUtil.StartInfoTransaction(
            'Main:Startup',
            JSON.stringify({
                version: process.env.REACT_APP_PACKAGE_VERSION ?? 'Unknown',
                hasCloudshelfPlayer: CloudshelfBridge.isAvailable(),
            }),
        ).newTransaction.finish();

        let hasUptimeWorker = false;
        let hasVersionCheckWorker = false;
        // let hasPreloadContentWorker = false;
        window.addEventListener('pageshow', () => handlePageVisibility(true), false);
        window.addEventListener('pagehide', () => handlePageVisibility(false), false);
        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        window.addEventListener(
            'resize',
            debounce(function () {
                setupOneVh('Window Resize');
                setupResponsiveUnits(getDeviceDPI(), 'Window Resize');
            }, 50),
        );

        window.addEventListener(
            'orientationchange',
            debounce(function () {
                setupOneVh('Orientation Changed');
                setupResponsiveUnits(getDeviceDPI(), 'Orientation Changed');
            }, 50),
        );
        setupOneVh('Initial Setup');
        // To improve our Time to First Contentful Paint, we render the loading screen first,
        // only then we do start the real loading
        reactRender(<Loader variant="stars" text={'Waiting on UptimeWorker'} />, 'loadingApp');

        while (!hasUptimeWorker) {
            try {
                uptimeWorker = new Worker(
                    new URL(/*webpackChunkName: "UptimeWorker"*/ './workers/uptime/Uptime.Worker.ts', import.meta.url),
                );
                hasUptimeWorker = true;
            } catch (e) {
                console.log('UptimeWorker not yet accessible, retrying in 1s');
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }

        reactRender(<Loader variant="stars" text={'Setting up'} text2={'Checking for updates'} />, 'loadingApp');

        while (!hasVersionCheckWorker) {
            try {
                versionCheckerWorker = new Worker(
                    /*webpackChunkName: "VersionCheckerWorker"*/
                    new URL('./workers/versionCheck/VersionChecker.Worker.ts', import.meta.url),
                );
                hasVersionCheckWorker = true;
            } catch (e) {
                console.log('VersionCheckerWorker not yet accessible, retrying in 1s');
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }

        if (!versionCheckerWorker) {
            throw new Error('VersionCheckerWorker not initialized');
        }

        versionCheckerWorker.onmessage = e => {
            const versionCheckResponse = e.data as VersionCheckerResponse;
            (window as any).LATEST_VERSION = versionCheckResponse.remoteVersion;
            if (versionCheckResponse.shouldUpdate) {
                console.log('Reloading because engine has updated');
                window.location.reload();
            }
        };

        versionCheckerWorker.postMessage({
            currentVersion: process.env.REACT_APP_PACKAGE_VERSION ?? '',
            blockUpdates: process.env.REACT_APP_DONT_UPDATE === 'true' ?? false,
        });

        // reactRender(<Loader variant="stars" text={'Waiting on PreloadContentWorker'} />, 'loadingApp');
        //
        // while (!hasPreloadContentWorker) {
        //     try {
        //         preloadContentWorker = new Worker(
        //             /*webpackChunkName: "ImagePreloadWorker"*/
        //             new URL('./workers/imagePreload/ImagePreload.Worker.ts', import.meta.url),
        //         );
        //         hasPreloadContentWorker = true;
        //     } catch (e) {
        //         console.log('ImagePreload not yet accessible, retrying in 1s');
        //         await new Promise(resolve => setTimeout(resolve, 1000));
        //     }
        // }

        reactRender(
            <Loader variant="stars" text={'Just a moment'} text2={'Loading Cloudshelf Configuration'} />,
            'loadingApp',
        );

        await initConfigService();
        await initDependencies();

        const localDeviceInfo = getDeviceInfo();
        const configService = dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService);
        configService.setup(localDeviceInfo);
        let isDevice = configService.isDevice();
        let deviceNeedsPairing = configService.deviceNeedsPairing();
        let status = configService.status();

        (window as any).ENV_VERSION = process.env.REACT_APP_PACKAGE_VERSION;

        if (!uptimeWorker) {
            throw new Error('UptimeWorker not initialized');
        }

        postMessageToUptime();

        const observer = configService.observe();
        const updateConfig = () => {
            LogUtil.Log('Running updateConfig');
            const trans = SentryUtil.StartTransaction('ConfigurationService.RefreshConfig', false);
            configService.refreshConfig(localDeviceInfo).finally(() => {
                LogUtil.Log('Running updateConfig -> refreshConfig.finally');
                SentryUtil.EndSpan(trans.newTransaction);
            });
        };

        //
        observer.subscribe(async () => {
            await clearContainer();
            isDevice = configService.isDevice();
            deviceNeedsPairing = configService.deviceNeedsPairing();
            status = configService.status();
            await initDependencies();
            renderApp(isDevice, deviceNeedsPairing, status, configService.config());
        });

        let configPullInterval = UPDATE_CONFIG_INTERVAL_MS;

        if (
            status === CloudshelfPayloadStatus.DeviceNoCloudshelf ||
            status === CloudshelfPayloadStatus.DeviceWithoutLocation
        ) {
            configPullInterval = UPDATE_CONFIG__NO_CLOUDSHELF_INTERVAL_MS;
        }

        if (configInterval) {
            clearInterval(configInterval);
        }

        configInterval = setInterval(() => {
            console.log('Running configInterval for status', status);
            if (status === CloudshelfPayloadStatus.DeviceWithCloudshelf) {
                if (configInterval) {
                    clearInterval(configInterval);
                    configInterval = setInterval(() => {
                        updateConfig();
                    }, UPDATE_CONFIG_INTERVAL_MS);
                }
            } else {
                updateConfig();
            }
        }, configPullInterval);
        renderApp(isDevice, deviceNeedsPairing, status, configService.config());
    } catch (error) {
        console.log('Error in main render', error);
        Sentry.captureException(error, {
            extra: {
                operationName: 'Main render',
            },
        });

        setTimeout(() => {
            console.log('Reloading because of error');
            window.location.reload();
        }, ERROR_RELOAD_INTERVAL_MS);

        //
        if (
            _.includes(_.toLower(_.toString(error)), 'failed to fetch config') ||
            _.includes(_.toLower(_.toString(error)), 'configuration did not load')
        ) {
            reactRender(
                <FullPageErrorComponent
                    variant="custom"
                    title="Oh no!"
                    subtitle="This Cloudshelf is having trouble loading its config.</br>This is usually a temporary issue that will resolve itself within a few minutes."
                />,
                'strippedApp',
            );
        } else {
            reactRender(<FullPageErrorComponent variant="unknownError" exception={error as Error} />, 'strippedApp');
        }
    }
})();
