import { injectable } from 'inversify';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
    AttributeValue,
    AttributeValueOverride,
    EngineProductWithAdditionalInfo,
    EngineProductWithAdditionalInfoEdge,
    EngineType,
    FilterType,
    GetEngineProductsDocument,
    GetEngineProductsQuery,
    GetEngineProductsQueryVariables,
    OnlineFilterSearchDocument,
    OnlineFilterSearchQuery,
    OnlineFilterSearchQueryVariables,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import {
    CATEGORY_FILTER_ID,
    CLEAR_ALL_FILTERS,
    CLEAR_ALL_FILTERS_ID,
    NAME_FILTER,
    NAME_FILTER_ID,
} from '../../provider/cloudshelf/filter/CloudshelfFilters';
import { List } from 'immutable';
import { StorageService } from '../StorageService/StorageService';
import * as Sentry from '@sentry/react';
import { Observable, Subject } from 'rxjs';
import { LogUtil } from '../../utils/Logging.Util';
import { StorageKey } from '../StorageService/StorageKeys.enum';
import loki from 'lokijs';
import {
    FilterableProduct,
    FilterableProductDiscount,
    FilterableProductImage,
    FilterableProductVariant,
    FilterableProductVariantOption,
    FilterableProductWithCursor,
    SearchResult,
} from './FilterableProductTypes';
import { ImageTransformInput } from '../../provider/shopify/graphql/generated/shopify_types';
import {
    CloudshelfEngineFilter,
    EngineAttributeValue,
} from '../ConfigurationService/types/filters/CloudshelfEngineFilter';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { Category } from '../CategoryService/entities/Category';
import { convertEcommercePlatformProvidedId } from '../../utils/ProductBinary.Util';
import {
    buildBuiltInSortOrder_PriceAsc,
    buildBuiltInSortOrder_PriceDesc,
    buildBuiltInSortOrder_Random,
    buildBuiltInSortOrder_Relevance,
    buildOrderByFilter,
    getPriceRange,
} from '../../utils/EngineFilter.Util';
import { SentryUtil } from '../../utils/Sentry.Util';
import { generateMatchingAllInputRegex } from '../../utils/String.Util';
import { GlobalIDUtils } from '../../utils/GlobalID.Util';
import { CloudshelfBridge } from '../../utils/CloudshelfBridge.Utils';
import { UniqueDirectiveNamesRule } from 'graphql';

export type ProductsFetchOptions = {
    cursor?: number | string;
    limit: number;
    imageTransform?: ImageTransformInput;
    compoundSort?: [keyof FilterableProduct, boolean][];
    jsSort?: 'price_asc' | 'price_desc' | 'random';
};

// export type ProductsFetchResult = {
//     products: FilterableProductWithCursor[];
//     hasMore: boolean;
// };

export interface FilterSelection {
    mergeDefinitionId: string;
    definitionId: string;
    name: string;
    type?: FilterType;
    values: string[];
}

export const RANGE_FILTER_TYPES = [FilterType.Price];

@injectable()
export class ProductFilteringService {
    private _matchingCount = -1;
    private _filterSelectionSubject: Subject<FilterSelection[]> = new Subject<FilterSelection[]>();
    private _filterViewSelectionSubject: Subject<FilterSelection[]> = new Subject<FilterSelection[]>();
    private _meaningfulChangedSubject: Subject<void> = new Subject<void>();
    private _lokiStateSubject: Subject<void> = new Subject<void>();
    private _lokiCacheForCloudshelfId: string | undefined = undefined;
    private _lokiCacheProductCount = 0;
    private _lokiDatabase: loki | undefined;
    private _lokiProductCache: Collection<FilterableProduct>;
    private _overrideMeaningfulAttributeValues: {
        filterName: string;
        visibleAttributeValues: EngineAttributeValue[];
    }[] = [];
    private _meaningFULFilters: CloudshelfEngineFilter[] = [];
    private _storedSearchTerm: string | undefined = undefined;
    private _onlineSearchData:
        | {
              query: string;
              results: FilterableProductWithCursor[];
              totalCount: number;
          }
        | undefined = undefined;

    constructor(
        private readonly _apolloClient: ApolloClient<NormalizedCacheObject>,
        private readonly _configService: ConfigurationService,
        private readonly _storageService: StorageService,
        private filterSelection: FilterSelection[] = [],
        private filterViewSelection: FilterSelection[] = [],
        private _preselection: FilterSelection[] = [],
        private _previousFilterSelection: FilterSelection[] = [],
    ) {
        this.clearSelection(true);
        this._configService.observe().subscribe(() => {
            // Update the preselection if a new config comes in and we don't have any filters selected
            if (this._preselection.length === 0 && this.filterSelection.length === 0) {
                this.clearSelection(true);
            }
        });
        this._lokiDatabase = new loki('cloudshelf.db');
        this._lokiProductCache = this._lokiDatabase.addCollection<FilterableProduct>('filterable_products');
        this._overrideMeaningfulAttributeValues = [];
        this._meaningFULFilters = this._configService.displayableFilters;
    }

    public get onlineData() {
        return this._onlineSearchData;
    }

    public clearSearchTerm() {
        this._storedSearchTerm = undefined;
        this._onlineSearchData = undefined;
    }

    public storeSearchTerm(term: string) {
        this._storedSearchTerm = term;
    }

    public get searchTerm(): string | undefined {
        return this._storedSearchTerm;
    }

    public clearPrice() {
        // Remove price
        this._overrideMeaningfulAttributeValues = _.filter(
            this._overrideMeaningfulAttributeValues,
            v => v.filterName !== 'Price',
        );
    }

    public get isCacheValidForCloudshelf(): boolean {
        return this._lokiCacheForCloudshelfId === this._configService.cloudshelfId;
    }

    public get isLocalCachePopulated(): boolean {
        return this._lokiCacheProductCount !== 0;
    }

    public get lokiCacheForCloudshelfId(): string | undefined {
        return this._lokiCacheForCloudshelfId;
    }

    public get searchValue(): string {
        //get the filter from the list that has the id: NAME_FILTER_ID
        const nameFilter = this.filterSelection.find(f => f.definitionId === NAME_FILTER_ID);
        if (!nameFilter) {
            return '';
        }

        return nameFilter.values[0];
    }
    public observeFilterSelectionState(): Observable<FilterSelection[]> {
        return this._filterSelectionSubject.asObservable();
    }

    public observeFilterViewSelectionState(): Observable<FilterSelection[]> {
        return this._filterViewSelectionSubject.asObservable();
    }

    public observeMeaningfulFilterState(): Observable<void> {
        return this._meaningfulChangedSubject.asObservable();
    }

    public observeLokiState(): Observable<void> {
        return this._lokiStateSubject.asObservable();
    }

    private async findAndSetProductCustomiserPriceModifierVariant() {
        const product = await this.getFilterableProductByHandle('product-customizer-item-customizations');

        this._configService.setProductCustomiserPriceModifierProduct(product);
        if ((product?.variants ?? []).length > 1) {
            this._configService.setProductCustomiserPriceModifierVariant(product?.variants[0]);
        }
    }

    private async mapFromEngineProductsToFilterableProducts(nodes: EngineProductWithAdditionalInfo[]) {
        const filterableProducts: FilterableProduct[] = [];

        for (const node of nodes) {
            const filterableProduct = await this.mapFromEngineProductToFilterableProduct(
                node,
                this._configService.config()?.filters ?? [],
            );
            if (filterableProduct) {
                filterableProducts.push(filterableProduct);
            }
        }

        return filterableProducts;
    }

    private async getMappedOptionsFromFilters(
        filtersForOption: CloudshelfEngineFilter[],
        originalKey: string,
        originalValue: string | string[],
        includeOriginal = true,
    ) {
        const returnableOptions: string[] = [];
        if (!Array.isArray(originalValue)) {
            if (includeOriginal) {
                returnableOptions.push(`${originalKey}:${originalValue}`);
            }
            for (const filterForOption of filtersForOption) {
                for (const override of filterForOption.valueOverrides) {
                    if (override.originalValue === originalValue) {
                        returnableOptions.push(`${originalKey}:${override.displayValue}`);
                    }
                }
            }
        } else {
            if (includeOriginal) {
                originalValue.map(val => {
                    returnableOptions.push(`${originalKey}:${val}`);
                });
            }

            for (const filterForOption of filtersForOption) {
                for (const override of filterForOption.valueOverrides) {
                    if (originalValue.includes(override.originalValue)) {
                        returnableOptions.push(`${originalKey}:${override.displayValue}`);
                    }
                }
            }
        }

        return returnableOptions;
    }

    private async mapFromEngineProductToFilterableProduct(
        node: EngineProductWithAdditionalInfo,
        filters: CloudshelfEngineFilter[],
    ) {
        if (node) {
            const newFilterableProductDiscounts: FilterableProductDiscount[] = [];
            const newMappedMetadata: string[] = [];
            const newMappedOptions: Set<string> = new Set();
            const newFilterableProductVariants: FilterableProductVariant[] = [];
            const newFilterableProductImages: FilterableProductImage[] = [];

            //mapped metadata
            const originalMetafieldValues = node.metadata.map(d => d.data);
            const filterForMetafields = filters.filter(
                f => f.type === FilterType.Metadata && node.metadata.find(mf => mf.key === f.metafieldKey),
            );

            _.forEach(originalMetafieldValues, (value: string) => {
                newMappedMetadata.push(value);
            });

            _.forEach(filterForMetafields, (filter: CloudshelfEngineFilter) => {
                _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                    newMappedMetadata.push(override.displayValue);
                });
            });

            for (const nodeVariant of node.variants) {
                const newFilterableProductVariantOptions: FilterableProductVariantOption[] = [];

                for (const opt of nodeVariant.options) {
                    const option: FilterableProductVariantOption = {
                        key: opt.key,
                        value: opt.value,
                    };
                    newFilterableProductVariantOptions.push(option);
                    if (!newMappedOptions.has(`${opt.key}:${opt.value}`)) {
                        newMappedOptions.add(`${opt.key}:${opt.value}`);
                    }

                    //Now make sure we include all the filter overrides too
                    for (const mappedOption of await this.getMappedOptionsFromFilters(
                        filters.filter(f => f.ecommProviderFieldName === opt.key),
                        opt.key,
                        opt.value,
                        false,
                    )) {
                        if (!newMappedOptions.has(mappedOption)) {
                            newMappedOptions.add(mappedOption);
                        }
                    }
                }

                //mapped vendor
                for (const mappedOption of await this.getMappedOptionsFromFilters(
                    filters.filter(f => f.type === FilterType.Vendor),
                    'Vendor',
                    node.vendor,
                )) {
                    if (!newMappedOptions.has(mappedOption)) {
                        newMappedOptions.add(mappedOption);
                    }
                }

                //mapped product type
                for (const mappedOption of await this.getMappedOptionsFromFilters(
                    filters.filter(f => f.type === FilterType.ProductType),
                    'Product Type',
                    node.type,
                )) {
                    if (!newMappedOptions.has(mappedOption)) {
                        newMappedOptions.add(mappedOption);
                    }
                }

                //mapped tags
                // newFilterableProductVariantMappedOptions.push(
                //     ...(await this.getMappedOptionsFromFilters(
                //         filters.filter(f => f.type === FilterType.Tag),
                //         'Tags',
                //         node.tags,
                //     )),
                // );

                const newFilterableProductVariant: FilterableProductVariant = {
                    id: GlobalIDUtils.stripGlobalID(nodeVariant.id),
                    eCommercePlatformProvidedId:
                        nodeVariant.eCommercePlatformProvidedId !== undefined
                            ? convertEcommercePlatformProvidedId(nodeVariant.eCommercePlatformProvidedId)
                            : GlobalIDUtils.stripGlobalID(nodeVariant.id),
                    position: nodeVariant.position ?? undefined,
                    sku: nodeVariant.sku,
                    barcode: nodeVariant.barcode,
                    title: nodeVariant.displayName,
                    price: nodeVariant.price,
                    originalPrice: nodeVariant.originalPrice,
                    hasSalePrice: nodeVariant.hasSalePrice ?? false,
                    availableForSale: nodeVariant.availableForSale,
                    currentlyNotInStock: nodeVariant.currentlyNotInStock,
                    sellableOnlineQuantity: nodeVariant.sellableOnlineQuantity,
                    options: newFilterableProductVariantOptions,
                    // mappedOptions: newFilterableProductVariantMappedOptions,
                };

                newFilterableProductVariants.push(newFilterableProductVariant);
            }

            for (const nodeImage of node.images) {
                const image: FilterableProductImage = {
                    url: nodeImage.url,
                    variantId: nodeImage.variantId ? GlobalIDUtils.stripGlobalID(nodeImage.variantId) : undefined,
                    preferred: nodeImage.preferred,
                };
                newFilterableProductImages.push(image);
            }

            const newFilterableProduct: FilterableProduct = {
                id: GlobalIDUtils.stripGlobalID(node.id),
                eCommercePlatformProvidedId:
                    node.eCommercePlatformProvidedId !== undefined
                        ? convertEcommercePlatformProvidedId(node.eCommercePlatformProvidedId)
                        : GlobalIDUtils.stripGlobalID(node.id),
                remoteUpdatedAt: new Date(node.remoteUpdatedAt),
                title: node.title,
                handle: node.handle,
                type: node.type,
                vendor: node.vendor,
                description: node.descriptionHtml,
                tags: node.tags,
                images: newFilterableProductImages,
                variants: newFilterableProductVariants,
                metadata: node.metadata,
                mappedMetadata: newMappedMetadata,
                mappedOptions: Array.from(newMappedOptions),
                discounts: newFilterableProductDiscounts,
                //
                categoryIds: node.categoryIds,
                categoryHandles: node.categoryHandles,
                categoryOrderByHandles: node.categoryOrderByHandles.reduce((acc, { categoryHandle, order }) => {
                    acc[categoryHandle] = order;
                    return acc;
                }, {} as { [categoryHandle: string]: number }),
            };

            return newFilterableProduct;
        } else {
            return null;
        }
    }

    public async onlineSearch(
        query: string,
        limit: number,
        afterCursor?: number | string,
        reason?: string,
    ): Promise<SearchResult<FilterableProductWithCursor>> {
        try {
            console.log('ONLINE SEARCH, AFTER CURSOR', afterCursor);
            let cursor: string | undefined = undefined;
            if (afterCursor) {
                if (_.isNumber(afterCursor)) {
                    cursor = btoa(`arrayconnection:${afterCursor}`);
                } else {
                    cursor = afterCursor;
                }
            }

            // if (this._onlineSearchData && this._onlineSearchData.query === query) {
            //     if (afterCursor === undefined) {
            //         return {
            //             hasMore: this._onlineSearchData.totalCount > this._onlineSearchData.results.length, //maybe? we don't know
            //             totalCount: -1,
            //             items: this._onlineSearchData.results,
            //         };
            //     }
            // }

            const cloudshelfId = this._configService.cloudshelfId;
            let includeMetafieldKeys: string[] = [];
            let includeMetafieldPartialKeys: string[] = ['product_customizer', 'bookthatapp'];

            const metafieldFilters = this._configService.config()?.filters.filter(f => f.type === FilterType.Metadata);

            _.map(metafieldFilters ?? [], f => {
                if (f.metafieldKey) {
                    includeMetafieldKeys.push(f.metafieldKey);
                }
            });

            const pdpBlocks = this._configService.config()?.pdpBlocks ?? [];

            const pdpMetafields = pdpBlocks.filter(b => b.__typename === 'PDPMetadataBlock');

            _.map(pdpMetafields ?? [], f => {
                if (f.key) {
                    includeMetafieldKeys.push(f.key);
                }
            });

            includeMetafieldKeys = _.uniq(includeMetafieldKeys);
            includeMetafieldPartialKeys = _.uniq(includeMetafieldPartialKeys);

            const debounceKey = `onlineSearch-${query}-${limit}-${cursor}`;
            console.warn(
                'Calling Online Search with Limit',
                limit,
                'reason:',
                reason,
                'query:',
                query,
                'debounceKey:',
                debounceKey,
            );
            const { data, errors } = await this._apolloClient.query<
                OnlineFilterSearchQuery,
                OnlineFilterSearchQueryVariables
            >({
                variables: {
                    cloudshelfId,
                    includeMetafieldKeys,
                    includeMetafieldPartialKeys,
                    searchQuery: query,
                    first: limit,
                    after: cursor,
                },
                query: OnlineFilterSearchDocument,
                fetchPolicy: 'cache-first',

                context: {
                    debounceKey: debounceKey,
                },
            });

            const resultsWithCursor: FilterableProductWithCursor[] = [];

            for (const edge of data.onlineFilterSearch.edges ?? []) {
                if (edge.node && edge.cursor) {
                    const filterableProds = await this.mapFromEngineProductsToFilterableProducts([
                        edge.node as EngineProductWithAdditionalInfo,
                    ]);

                    if (filterableProds.length === 1) {
                        const firstFilterableProd: FilterableProduct = filterableProds[0];
                        resultsWithCursor.push({
                            cursor: edge.cursor,
                            ...firstFilterableProd,
                        });
                    }
                }
            }

            this._onlineSearchData = {
                query,
                results: resultsWithCursor,
                totalCount: data.onlineFilterSearch.totalCount,
            };

            // if (this._onlineSearchData === undefined || this._onlineSearchData.query !== query) {
            //     this._onlineSearchData = {
            //         query,
            //         results: [],
            //         totalCount: data.onlineFilterSearch.totalCount,
            //     };
            // }

            // this._onlineSearchData.results.push(...resultsWithCursor);

            return {
                hasMore: data.onlineFilterSearch.pageInfo?.hasNextPage ?? false,
                totalCount: data.onlineFilterSearch.totalCount,
                items: resultsWithCursor,
            };
        } catch (e) {
            console.error(e);
            return {
                hasMore: false,
                totalCount: 0,
                items: [],
            };
        }
    }

    public async updateLokiCache(
        loadFromLocallyCachedVersion = true,
        progressCallback?: (progress: number, translationKey: string, translationOptions?: any) => void,
        explicitProductHandle?: string,
    ): Promise<void> {
        this._configService.setReloadConfigBlockedReason('Updating Loki Product Cache');

        const sendProgress = (progress: number, translationKey: string, translationOptions?: any) => {
            console.info(`[Loki Product Cache]: ${progress}% - ${translationKey}`);
            if (progressCallback) {
                progressCallback(progress, translationKey, translationOptions);
            }
        };

        try {
            const cloudshelfId = this._configService.cloudshelfId;

            if (!cloudshelfId) {
                // Can't get products until the cloudshelf id is known,
                // I don't think this should ever happen... but just to be safe
                LogUtil.Log('No cloudshelf ID');
                this._lokiStateSubject.next();

                return;
            }

            sendProgress(1, 'Loading products from local cache');
            if (loadFromLocallyCachedVersion) {
                const loadTrans = SentryUtil.StartTransaction('FilterService.LoadCacheIntoLokiCache', false);
                this._lokiCacheForCloudshelfId = this._storageService.get(StorageKey.CLOUDSHELF_ID);
                await this.loadCacheIntoLokiCache();
                SentryUtil.EndSpan(loadTrans.newTransaction);
            }
            sendProgress(10, 'Checking for product updates');
            let cursor: string | undefined = undefined;
            let hasMore = true;
            let totalProductsOnBackend = 0;
            let totalLoadedProducts = 0;
            let loadedFilterableProducts: FilterableProduct[] = [];
            let includeMetafieldKeys: string[] = [];
            let includeMetafieldPartialKeys: string[] = ['product_customizer', 'bookthatapp'];

            const metafieldFilters = this._configService.config()?.filters.filter(f => f.type === FilterType.Metadata);

            _.map(metafieldFilters ?? [], f => {
                if (f.metafieldKey) {
                    includeMetafieldKeys.push(f.metafieldKey);
                }
            });

            const pdpBlocks = this._configService.config()?.pdpBlocks ?? [];

            const pdpMetafields = pdpBlocks.filter(b => b.__typename === 'PDPMetadataBlock');

            _.map(pdpMetafields ?? [], f => {
                if (f.key) {
                    includeMetafieldKeys.push(f.key);
                }
            });

            includeMetafieldKeys = _.uniq(includeMetafieldKeys);
            includeMetafieldPartialKeys = _.uniq(includeMetafieldPartialKeys);

            do {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { data, errors } = await this._apolloClient.query<
                    GetEngineProductsQuery,
                    GetEngineProductsQueryVariables
                >({
                    variables: {
                        cloudshelfId,
                        includeMetafieldKeys,
                        includeMetafieldPartialKeys,
                        isDisplayMode: this._configService.deviceMode === EngineType.DisplayOnly,
                        explicitProductHandle,
                        first: 250,
                        after: cursor,
                    },
                    query: GetEngineProductsDocument,
                });

                cursor = data.engineProducts.pageInfo.endCursor ?? undefined;
                hasMore = data.engineProducts.pageInfo?.hasNextPage ?? false;
                totalProductsOnBackend = data.engineProducts.totalCount;

                const productNodesFromChunk = data.engineProducts.edges?.map(
                    (e: EngineProductWithAdditionalInfoEdge) => e.node,
                );

                loadedFilterableProducts.push(
                    ...(await this.mapFromEngineProductsToFilterableProducts(productNodesFromChunk)),
                );

                totalLoadedProducts = totalLoadedProducts + productNodesFromChunk.length;
                sendProgress(
                    10 + (70 * totalLoadedProducts) / totalProductsOnBackend,
                    `Loaded ${totalLoadedProducts}/${totalProductsOnBackend} products so far`,
                );

                if (errors) {
                    LogUtil.LogObject(['Something went wrong', errors]);
                    return;
                }
            } while (hasMore);

            sendProgress(82, `Checking for Product Customiser Product`);

            const { data: data2, errors } = await this._apolloClient.query<
                GetEngineProductsQuery,
                GetEngineProductsQueryVariables
            >({
                variables: {
                    cloudshelfId,
                    includeMetafieldKeys,
                    includeMetafieldPartialKeys,
                    isDisplayMode: this._configService.deviceMode === EngineType.DisplayOnly,
                    explicitProductHandle: 'product-customizer-item-customizations',
                },
                query: GetEngineProductsDocument,
            });

            const productCustomiserNodes: EngineProductWithAdditionalInfo[] = [];

            for (const edge of data2.engineProducts.edges ?? []) {
                if (edge.node) {
                    productCustomiserNodes.push(edge.node as EngineProductWithAdditionalInfo);
                }
            }

            loadedFilterableProducts.push(
                ...(await this.mapFromEngineProductsToFilterableProducts(productCustomiserNodes)),
            );

            sendProgress(85, `Saving ${totalProductsOnBackend} to local cache`);
            //Now we should save it to the cache
            const putTrans = SentryUtil.StartTransaction('FilterService.PutProductCache', false);
            //Save this into LocalStorage so it can be loaded for when the backend if offline
            await this._storageService.putProductCache(loadedFilterableProducts);
            SentryUtil.EndSpan(putTrans.newTransaction);
            sendProgress(90, 'Saved to local cache, now updating Loki cache');

            //Now we load it into the Loki cache
            const populateTrans = SentryUtil.StartTransaction('FilterService.PopulateLoki', false);
            console.log('Old Loki Cache Product Count: ' + this._lokiProductCache.count());
            this._lokiProductCache.clear();
            console.log('Cleared Loki Cache Product Count: ' + this._lokiProductCache.count());
            this._lokiProductCache.insert(loadedFilterableProducts);
            SentryUtil.EndSpan(populateTrans.newTransaction);
            sendProgress(95, 'Loki cache updated');

            //Set the cache product count AND the cloudshelfID so the SetupWrapper knows it can continue
            this._lokiCacheProductCount = this._lokiProductCache.count();
            console.log('New Loki Cache Product Count: ' + this._lokiProductCache.count());
            this._lokiCacheForCloudshelfId = cloudshelfId;
            this._storageService.put(StorageKey.CLOUDSHELF_ID, this._configService.cloudshelfId ?? '');
            LogUtil.Log(
                '[Loki Product Cache] Updated Cache from server. Loki Cache Size: ' + this._lokiCacheProductCount,
            );
            sendProgress(98, 'Wrapping things up');
            loadedFilterableProducts = [];
            sendProgress(100, 'Wrapping things up');

            this.findAndSetProductCustomiserPriceModifierVariant();
            this._lokiStateSubject.next();
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'updateLokiCache',
                },
            });
        } finally {
            this._configService.setReloadConfigBlockedReason(undefined);
        }
    }

    public totalNumberOfProductsInCache(): number {
        return this._lokiCacheProductCount;
    }

    async loadCacheIntoLokiCache() {
        const filterableProductVariants = await this._storageService.getProductCache();
        if (filterableProductVariants.length === 0) {
            return;
        }
        this._lokiProductCache.clear();
        this._lokiProductCache.insert(filterableProductVariants);
        this._lokiCacheProductCount = this._lokiProductCache.count();
        LogUtil.Log(`[Loki Product Cache] Loaded ${filterableProductVariants.length} from IndexedDB via Dexie`);
        this.findAndSetProductCustomiserPriceModifierVariant();
        this._lokiStateSubject.next();
    }

    public visibleFilterOptions(): CloudshelfEngineFilter[] {
        return this._meaningFULFilters;
    }

    public get preselection() {
        return _.cloneDeep(this._preselection);
    }

    clearSelection(commit = false) {
        const filters = this._configService.config()?.filters;
        const preselectionToCommit: FilterSelection[] = [];
        if (filters) {
            const stockFilter = _.find(filters, filter => filter.type === FilterType.StockLevel);
            const sortFilter = _.find(filters, filter => filter.type === FilterType.SortOrder);
            if (!stockFilter && !sortFilter) {
                this.filterViewSelection = [];
            } else {
                if (stockFilter) {
                    preselectionToCommit.push({
                        definitionId: stockFilter.id,
                        name: stockFilter.displayName,
                        type: FilterType.StockLevel,
                        values: stockFilter.attributeValues.map(av => av.value),
                        mergeDefinitionId: stockFilter.id,
                    });
                }

                if (sortFilter) {
                    preselectionToCommit.push({
                        definitionId: sortFilter.id,
                        name: sortFilter.displayName,
                        type: FilterType.SortOrder,
                        values: ['relevance'],
                        mergeDefinitionId: sortFilter.id,
                    });
                }

                this._preselection = preselectionToCommit;
                this.filterViewSelection = this.preselection;
            }
        } else {
            this.filterViewSelection = [];
        }
        this._matchingCount = -1;
        if (commit) {
            this.commitSelection();
        }
        this._meaningFULFilters = this._configService.displayableFilters;
        this._previousFilterSelection = [];
        this.debounceFilterViewSelectionSubject();
    }

    commitSelection() {
        this.filterSelection = [...this.filterViewSelection];
        this.debounceFilterSelectionSubject();
    }

    clearSingleFilter(definitionId: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        _.remove(currentViewSelection, filter => filter.definitionId === definitionId);
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    get getSubcategoryFilterItem(): CloudshelfEngineFilter | undefined {
        const currentSelection = this.filterSelection ?? [];
        const filters = _.orderBy(this._meaningFULFilters ?? [], value => value.priority);

        //Find the first filter that is does not have any values in the currentSelection and has at least 2 values
        const subCategoryFilter = _.find(filters, filter => {
            const isBlacklistedType = _.includes(
                [FilterType.Price, FilterType.StockLevel, FilterType.SortOrder],
                filter.type,
            );

            const isMerged = filter.isMergedChild;

            const matchingSelection = _.find(currentSelection, selection => selection.definitionId === filter.id);
            return (
                !isMerged && !isBlacklistedType && matchingSelection === undefined && filter.attributeValues.length > 1
            );
        });

        return subCategoryFilter;
    }

    async getFilterableProductByHandle(handle: string): Promise<FilterableProduct | undefined> {
        let results: SearchResult<FilterableProductWithCursor> = {
            hasMore: false,
            items: [],
            totalCount: 0,
        };

        //Always try and use online data if it exists
        if ((this._onlineSearchData?.results ?? []).length > 0) {
            const foundFromOnline = this._onlineSearchData?.results.find(r => r.handle === handle);
            if (foundFromOnline) {
                return foundFromOnline;
            }
        } else {
            //offline seearch
            results = await this.matchingProducts(
                'getFilterableProductByHandle (offline)',
                null,
                [
                    {
                        mergeDefinitionId: '1',
                        definitionId: '1',
                        values: [handle],
                        type: FilterType.ProductHandle,
                        name: 'handle',
                    },
                ],
                { limit: 1 },
                false,
                [],
                true,
            );
        }

        console.log('results', results);
        if (results.items.length !== 0) {
            return results.items[0];
        }

        return undefined;
    }

    get getSubcategoryFilterSelection(): FilterSelection | undefined {
        const subCategoryFilter = this.getSubcategoryFilterItem;

        if (!subCategoryFilter) {
            return undefined;
        }

        return _.find(
            this.getCurrentSelection(),
            selectedFilter => selectedFilter.definitionId === subCategoryFilter.id,
        );
    }

    get isUsingSubcategoryFilter(): boolean {
        return this.getSubcategoryFilterItem !== undefined;
    }

    getChipDisplayValue(definitionId: string, internalValue: string): string {
        const filters = this._configService.config()?.filters ?? [];

        const matchingFilter = _.find(filters, filter => filter.id === definitionId);
        if (matchingFilter) {
            const matchingValue = _.find(
                matchingFilter.valueOverrides,
                override => override.originalValue === internalValue,
            );

            if (matchingValue && !_.isEmpty(matchingValue.displayValue)) {
                return matchingValue.displayValue;
            }

            return internalValue;
        } else {
            if (definitionId === CATEGORY_FILTER_ID) {
                const categoryByHandle = this._configService.categories.find(c => c.handle === internalValue);
                if (categoryByHandle) {
                    return categoryByHandle.title;
                } else {
                    return internalValue;
                }
            }
        }

        return internalValue;
    }

    findChildDefinitionsByParentAndValue(parentDefinitionId: string, value: string): CloudshelfEngineFilter[] {
        const filters = this._configService.config()?.filters ?? [];

        const childrenOfParent = _.filter(filters, f => f.isMergedChild && f.parentId === parentDefinitionId);

        return _.filter(childrenOfParent, child => _.some(child.attributeValues, av => av.value === value));
    }

    findDefinitions(definitionId: string): CloudshelfEngineFilter[] {
        const filters = this._configService.config()?.filters ?? [];

        return _.filter(filters, filter => filter.id === definitionId);
    }

    toggleValue(mergeDefinitionId: string, definitionId: string, filterName: string, type: FilterType, value: string) {
        let currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            if (_.includes(existingFilterSelection.values, value)) {
                existingFilterSelection.values = _.filter(existingFilterSelection.values, eV => eV !== value);
                if (existingFilterSelection.values.length === 0) {
                    currentViewSelection = _.filter(
                        currentViewSelection,
                        filter => filter.definitionId !== definitionId,
                    );
                }
            } else {
                existingFilterSelection.values = _.concat(existingFilterSelection.values, value);
            }
        } else {
            currentViewSelection.push({
                mergeDefinitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            });
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    toggleSingleValue(
        mergeDefinitionId: string,
        definitionId: string,
        filterName: string,
        type: FilterType,
        value: string,
    ) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            if (_.includes(existingFilterSelection.values, value)) {
                this.removeSpecificValue(definitionId, filterName, value);
            } else {
                existingFilterSelection.values = [value];
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        } else {
            currentViewSelection.push({
                mergeDefinitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            });
            this.filterViewSelection = currentViewSelection;
            this.debounceFilterViewSelectionSubject();
        }
    }

    setStringValue(definitionId: string, filterName: string, type: FilterType, value: string, pushToFront?: boolean) {
        if (definitionId === NAME_FILTER_ID && filterName === NAME_FILTER && type === FilterType.ProductTitle) {
            if (value.toLowerCase() === 'cs:settings') {
                CloudshelfBridge.exitEngine();
                return;
            }

            if (value.toLowerCase() === 'cs:console') {
                const existingEruda = document.getElementById('eruda');

                if (!existingEruda) {
                    const script = document.createElement('script');
                    script.src = 'https://cdn.jsdelivr.net/npm/eruda';
                    document.body.append(script);
                    script.onload = function () {
                        (window as any).eruda.init();
                    };
                }
                return;
            }

            if (value.toLowerCase() === 'cs:bridge') {
                alert(
                    `Bridge Available: ${CloudshelfBridge.isAvailable()}. Extra: ${JSON.stringify(
                        window.CloudshelfBridge,
                        null,
                        2,
                    )}`,
                );
                return;
            }
        }
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            existingFilterSelection.values = [value];
        } else {
            const newFilter = {
                mergeDefinitionId: definitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            };
            if (pushToFront) {
                currentViewSelection.unshift(newFilter);
            } else {
                currentViewSelection.push(newFilter);
            }
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    setRangeValue(definitionId: string, filterName: string, type: FilterType, min: number, max: number) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection) {
            existingFilterSelection.values = [min.toString(), max.toString()];
        } else {
            currentViewSelection.push({
                mergeDefinitionId: definitionId,
                definitionId,
                name: filterName,
                type,
                values: [min.toString(), max.toString()],
            });
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    refreshViewSelection() {
        this.filterViewSelection = _.cloneDeep(this.filterSelection);
        if (this.filterViewSelection.length === 0 && this.preselection.length > 0) {
            this.filterViewSelection = _.cloneDeep(this.preselection);
        }
        this.debounceFilterViewSelectionSubject();
    }

    getCurrentViewSelection(): FilterSelection[] {
        return _.cloneDeep(this.filterViewSelection);
    }

    getCurrentSelection(): FilterSelection[] {
        return _.cloneDeep(this.filterSelection);
    }

    getSelectedRangeFilters(): FilterSelection[] {
        const cloned = this.getCurrentSelection();
        return _.filter(cloned, filter => _.includes(RANGE_FILTER_TYPES, filter.type));
    }

    getAllFilterItemsAsSingleValues(): FilterSelection[] {
        const allValueFilterItems = _.chain(this.getSelectedValueFilters())
            .map(filter =>
                _.map(filter.values, val => ({
                    mergeDefinitionId: filter.mergeDefinitionId,
                    definitionId: filter.definitionId,
                    name: filter.name,
                    values: [val],
                    type: filter.type,
                })),
            )
            .flatten()
            .value();
        const allRangeFilterItems = _.chain(this.getSelectedRangeFilters())
            .map(filter => ({
                mergeDefinitionId: filter.mergeDefinitionId,
                definitionId: filter.definitionId,
                name: filter.name,
                values: [getPriceRange(filter.values, 2)],
                type: filter.type,
            }))
            .value();

        return _.concat(
            [
                ...(allRangeFilterItems.length > 0 || allValueFilterItems.length > 0
                    ? [
                          {
                              definitionId: CLEAR_ALL_FILTERS_ID,
                              name: CLEAR_ALL_FILTERS,
                              values: ['Clear All'],
                              mergeDefinitionId: CLEAR_ALL_FILTERS_ID,
                              type: undefined,
                          },
                      ]
                    : []),
            ],
            allValueFilterItems,
            allRangeFilterItems,
        );
    }

    getBreadcrumbFilterItems(): FilterSelection[] {
        const preselection = this.preselection;
        const cloned = this.getCurrentSelection();
        const returnable: FilterSelection[] = [];

        _.map(cloned, filter => {
            if (filter.mergeDefinitionId !== filter.definitionId) {
                return null;
            }

            const isClearAll = filter.name === CLEAR_ALL_FILTERS;
            const isSearch = filter.name === NAME_FILTER;

            if (isClearAll || isSearch) {
                return null;
            }

            const preselectedFilter = _.find(preselection, s => s.definitionId === filter.definitionId);
            if (preselectedFilter) {
                return null;
            }

            returnable.push(filter);
        });

        return returnable;
    }

    getSelectedValueFilters(): FilterSelection[] {
        const cloned = this.getCurrentSelection();
        return _.filter(cloned, filter => !_.includes(RANGE_FILTER_TYPES, filter.type) && filter.values.length > 0);
    }

    removeSpecificValue(definitionId: string, filterName: string, value: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );

        if (existingFilterSelection) {
            if (existingFilterSelection.values?.length === 1 && existingFilterSelection.values[0] === value) {
                // Handle single values (e.g. search term) by removing filter entirely
                this.clearSingleFilter(definitionId);
            } else if (_.includes(RANGE_FILTER_TYPES, existingFilterSelection.type)) {
                // Handle range selections by removing filter entirely
                this.clearSingleFilter(definitionId);
            } else {
                // Handle all other cases by only removing the requested value
                existingFilterSelection.values = _.filter(existingFilterSelection.values, val => val !== value);
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        }
    }

    removeSpecificValueAndMerged(mergeDefinitionId: string, value: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelections = _.filter(
            currentViewSelection,
            filterSelection => filterSelection.mergeDefinitionId === mergeDefinitionId,
        );

        _.map(existingFilterSelections, selection => {
            if (selection.values?.length === 1 && selection.values[0] === value) {
                // Handle single values (e.g. search term) by removing filter entirely
                this.clearSingleFilter(selection.definitionId);
            } else if (_.includes(RANGE_FILTER_TYPES, selection.type)) {
                // Handle range selections by removing filter entirely
                this.clearSingleFilter(selection.definitionId);
            } else {
                // Handle all other cases by only removing the requested value
                selection.values = _.filter(selection.values, val => val !== value);
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        });
    }

    removeAfterSpecificDefinition(mergeDefinitionId: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const foundIndex = _.findIndex(
            currentViewSelection,
            filterSelection => filterSelection.mergeDefinitionId === mergeDefinitionId,
        );

        currentViewSelection.splice(foundIndex + 1, currentViewSelection.length - foundIndex);
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    private debounceFilterViewSelectionSubject = _.debounce(() => {
        const selection = this.getCurrentViewSelection();
        this._filterViewSelectionSubject.next(selection);
    }, 200);

    private debounceFilterSelectionSubject = _.debounce(() => {
        const selection = this.getCurrentSelection();
        this._filterSelectionSubject.next(selection);
    }, 200);

    get matchingProductCount() {
        return this._matchingCount;
    }

    set matchingProductCount(val: number) {
        this._matchingCount = val;
    }

    static addCategoryFilter(category: Category | undefined | null, filters: FilterSelection[]): FilterSelection[] {
        if (category) {
            return _.concat(filters, {
                mergeDefinitionId: CATEGORY_FILTER_ID,
                definitionId: CATEGORY_FILTER_ID,
                type: FilterType.CategoryHandle,
                name: 'Category Handle',
                values: [category.handle],
            });
        }
        return filters;
    }

    static addCategoryFilters(categories: List<Category> | undefined, filters: FilterSelection[]): FilterSelection[] {
        if (categories) {
            const handles = _.map(categories.valueSeq().toArray(), category => category.handle);
            return _.concat(filters, {
                mergeDefinitionId: CATEGORY_FILTER_ID,
                definitionId: CATEGORY_FILTER_ID,
                type: FilterType.CategoryHandle,
                name: 'Category Handle',
                values: handles,
            });
        }
        return filters;
    }

    async countMatchingProducts(
        filterReason: string,
        category: Category | undefined | null,
        filters: FilterSelection[],
        setMeaningful: boolean,
    ): Promise<number> {
        const trans = SentryUtil.StartTransaction('FilterService.CountMatching', false);
        const filtersWithCategory = ProductFilteringService.addCategoryFilter(category, filters);
        const matchingProductsResult = await this.filterProducts(
            filterReason,
            filtersWithCategory,
            { limit: 0 },
            setMeaningful,
        );
        this.matchingProductCount = matchingProductsResult.totalCount;
        SentryUtil.EndSpan(trans.newTransaction);

        return matchingProductsResult.totalCount;
    }

    async matchingProducts(
        filterReason: string,
        category: Category | undefined | null,
        filters: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
        excludeProductHandles?: string[],
        onlineSearchBypass?: boolean,
    ): Promise<SearchResult<FilterableProductWithCursor>> {
        const trans = SentryUtil.StartTransaction('FilterService.MatchingProducts', false);
        const filtersWithCategory = ProductFilteringService.addCategoryFilter(category, filters);
        const ret = this.filterProducts(
            filterReason,
            filtersWithCategory,
            options,
            setMeaningful,
            excludeProductHandles,
            onlineSearchBypass,
        );
        SentryUtil.EndSpan(trans.newTransaction);

        return ret;
    }

    private async filterProducts(
        filterReason: string,
        filters: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
        excludeHandles?: string[],
        onlineSearchBypass?: boolean,
    ): Promise<SearchResult<FilterableProductWithCursor>> {
        const config = this._configService.config();

        if (!config) {
            return {
                hasMore: false,
                items: [],
                totalCount: 0,
            };
        }
        const currentCategoryFilter = filters.find(f => f.type === FilterType.CategoryHandle && f.values.length > 0);
        const currentCategoryHandle =
            currentCategoryFilter !== undefined ? currentCategoryFilter.values[0] : 'INTERNAL_ALL';

        if (currentCategoryHandle === 'INTERNAL_ALL' && this._configService.shouldUseOnlineSearch) {
            if (!onlineSearchBypass) {
                console.log('Using online search...');
                const queryFilter = filters.find(f => f.type === FilterType.ProductTitle);
                const queryTerm = queryFilter
                    ? queryFilter.values.length > 0
                        ? queryFilter.values[0]
                        : undefined
                    : undefined;

                if (queryTerm) {
                    return this.onlineSearch(queryTerm, options.limit, options.cursor, filterReason);
                } else {
                    console.error(
                        'No search term for online search inside filter products.',
                        this._onlineSearchData,
                        queryTerm,
                    );
                    //No query term?
                    return {
                        hasMore: false,
                        totalCount: 0,
                        items: [],
                    };
                }
            }
        }

        console.log('Using offline search...');
        this._onlineSearchData = undefined;
        const trans = SentryUtil.StartTransaction('FilterService.filterProducts', false);
        let jsSortFunction: (obj1: FilterableProduct, obj2: FilterableProduct) => number = () => 0;

        // setMeaningful = false;
        if (!options.compoundSort) {
            //Set the default sort order in order to not break existing functionality
            options.compoundSort = buildBuiltInSortOrder_Relevance(currentCategoryHandle);
        }

        //find the filter for ordering
        const sortFilter = _.find(filters, filter => filter.definitionId === 'sort-by');
        if (sortFilter && sortFilter.values && sortFilter.values.length > 0) {
            const sortByKey = sortFilter.values[0];
            if (sortByKey === 'relevance') {
                options.compoundSort = buildBuiltInSortOrder_Relevance(currentCategoryHandle);
                options.jsSort = undefined;
            } else if (sortByKey === 'price-desc') {
                jsSortFunction = buildBuiltInSortOrder_PriceDesc;
                options.jsSort = 'price_desc';
            } else if (sortByKey === 'price-asc') {
                jsSortFunction = buildBuiltInSortOrder_PriceAsc;
                options.jsSort = 'price_asc';
            } else if (sortByKey === 'random') {
                jsSortFunction = buildBuiltInSortOrder_Random;
                options.jsSort = 'price_asc';
            }
        }

        // For each preselection, ensure it is added to filters if not already present
        for (const preselected of this._preselection) {
            if (!_.find(filters, filter => filter.definitionId === preselected.definitionId)) {
                filters.push(preselected);
            }
        }

        let cursor = 0;
        if (_.isNumber(options.cursor)) {
            cursor = +options.cursor;
        }

        const startTime = Date.now();
        let timeToFilter = -1;
        let timeToSlice = -1;
        let totalCount = -1;
        let sliceCount = -1;

        try {
            const query: LokiQuery<FilterableProduct> = { $and: [] };

            if (excludeHandles && excludeHandles.length > 0) {
                query.$and.push({ productHandle: { $nin: excludeHandles } });
            }

            const mergedFilters = _.cloneDeep(filters);

            const mappedOptionsFilter = [
                FilterType.Basic,
                FilterType.Size,
                FilterType.Material,
                FilterType.Colour,
                FilterType.Vendor,
                FilterType.Tag,
                FilterType.ProductType,
            ];

            const metafieldOptionsFilter = [FilterType.Metadata];
            const stockFilter = mergedFilters.find(f => f.type === FilterType.StockLevel);

            const includeInStock = stockFilter ? _.includes(stockFilter.values, 'In Stock') : true;
            let includeOrderOnly = stockFilter ? _.includes(stockFilter.values, 'Order Only') : false;
            let includeOutOfStock = stockFilter ? _.includes(stockFilter.values, 'Out of Stock') : false;

            if (!this._configService.config()?.includeProductsOutOfStock) {
                includeOutOfStock = false;
            }

            if (!this._configService.config()?.includeProductsLimitedAvailability) {
                includeOrderOnly = false;
            }

            //Merge child and parent values
            for (const filter of mergedFilters) {
                if (filter.definitionId === filter.mergeDefinitionId && _.includes(mappedOptionsFilter, filter.type)) {
                    const childFilters = _.filter(
                        mergedFilters,
                        f => f.mergeDefinitionId === filter.definitionId && f.mergeDefinitionId !== f.definitionId,
                    );

                    const childValues = _.flatten(
                        _.map(childFilters, childFilter =>
                            _.map(childFilter.values, childValue => {
                                return `${childFilter.name}:${childValue}`;
                            }),
                        ),
                    );
                    const parentValues = _.map(filter.values, parentValue => {
                        return `${filter.name}:${parentValue}`;
                    });

                    filter.values = _.uniq([...parentValues, ...childValues]);

                    _.map(childFilters, childFilter => {
                        childFilter.values = filter.values;
                    });
                }
            }

            //exclude product customizer product from results
            query.$and.push({ handle: { $ne: 'product-customizer-item-customizations' } });

            for (const filter of mergedFilters) {
                if (filter.type === FilterType.CategoryHandle) {
                    if (filter.values[0] !== 'INTERNAL_ALL') {
                        query.$and.push({
                            categoryHandles: { $containsAny: filter.values },
                        });
                    }
                } else if (filter.type === FilterType.CategoryId) {
                    if (filter.values[0] !== 'INTERNAL_ALL') {
                        query.$and.push({
                            categoryIds: { $containsAny: filter.values },
                        });
                    }
                } else if (filter.type === FilterType.ProductTitle) {
                    const searchRegex = generateMatchingAllInputRegex(filter.values[0]);

                    console.log('Search regex: ', searchRegex);

                    query.$and.push({
                        $or: _.compact([
                            //always search on titles
                            ...[
                                {
                                    'variants.title': { $regex: searchRegex },
                                },
                            ],
                            ...[
                                {
                                    title: { $regex: searchRegex },
                                },
                            ],
                            //Then the optional ones
                            ...[
                                this._configService.config()?.textSearchSku
                                    ? { 'variants.sku': { $regex: searchRegex } }
                                    : null,
                            ],
                            ...[
                                this._configService.config()?.textSearchBarcode
                                    ? { 'variants.barcode': { $regex: searchRegex } }
                                    : null,
                            ],
                            ...[
                                this._configService.config()?.textSearchProductType
                                    ? { type: { $regex: searchRegex } }
                                    : null,
                            ],
                            ...[
                                this._configService.config()?.textSearchProductHandle
                                    ? { handle: { $regex: searchRegex } }
                                    : null,
                            ],
                            ...[
                                this._configService.config()?.textSearchProductVendor
                                    ? { vendor: { $regex: searchRegex } }
                                    : null,
                            ],
                            ...[
                                this._configService.config()?.textSearchDescription
                                    ? { description: { $regex: searchRegex } }
                                    : null,
                            ],
                            ...[
                                this._configService.config()?.textSearchTags ? { tags: { $regex: searchRegex } } : null,
                            ],
                            ...[
                                this._configService.config()?.textSearchMetadata
                                    ? { mappedMetadata: { $regex: searchRegex } }
                                    : null,
                            ],
                            //And then anything else
                            ...[
                                {
                                    mappedOptions: { $regex: searchRegex },
                                },
                            ],
                        ]),
                    });
                } else if (filter.type === FilterType.ProductHandle) {
                    const searchRegex = new RegExp(filter.values[0], 'i');
                    query.$and.push({ handle: { $regex: searchRegex } });
                } else if (filter.type === FilterType.Price) {
                    query.$and.push({
                        'variants.price': {
                            $and: [{ $gte: _.toNumber(filter.values[0]) }, { $lte: _.toNumber(filter.values[1]) }],
                        },
                    });
                } else if (filter.type === FilterType.Promotions) {
                    query.$and.push({ 'variants.hasSalePrice': { $eq: true } });
                    query.$and.push({ 'variants.originalPrice': { $gt: 0 } });
                } else if (filter.type === FilterType.StockLevel) {
                    const stockQuery: LokiQuery<FilterableProduct> = { $or: [] };

                    if (includeOutOfStock) {
                        stockQuery.$or.push({ 'variants.availableForSale': { $eq: false } });
                    }

                    if (includeInStock) {
                        stockQuery.$or.push({ 'variants.currentlyNotInStock': { $eq: false } });
                    }

                    if (includeOrderOnly) {
                        stockQuery.$or.push({
                            $and: [
                                { 'variants.availableForSale': { $eq: true } },
                                { 'variants.currentlyNotInStock': { $eq: true } },
                            ],
                        });
                    }

                    query.$and.push(stockQuery);
                } else if (_.includes(mappedOptionsFilter, filter.type)) {
                    if (filter.definitionId === filter.mergeDefinitionId) {
                        //parent
                        const childFilters = _.filter(
                            mergedFilters,
                            f => f.mergeDefinitionId === filter.definitionId && f.mergeDefinitionId !== f.definitionId,
                        );
                        query.$and.push({
                            $or: _.map([...childFilters, filter], mappedFilter => {
                                return { mappedOptions: { $containsAny: mappedFilter.values } };
                            }),
                        });
                    }
                } else if (_.includes(metafieldOptionsFilter, filter.type)) {
                    if (filter.definitionId === filter.mergeDefinitionId) {
                        //parent
                        const childFilters = _.filter(
                            mergedFilters,
                            f => f.mergeDefinitionId === filter.definitionId && f.mergeDefinitionId !== f.definitionId,
                        );
                        query.$and.push({
                            $or: _.map([...childFilters, filter], mappedFilter => {
                                return { mappedMetadata: { $containsAny: mappedFilter.values } };
                            }),
                        });
                    }
                }
            }

            console.log('quert', query);
            const queryBuilder = this._lokiProductCache.chain().find(query);

            if (!options.jsSort) {
                queryBuilder.compoundsort(options.compoundSort);
            } else {
                queryBuilder.sort(jsSortFunction);
            }

            let filtered = queryBuilder.data();

            //Now because we filter on products and not variants now, we might get products that have variants that dont match our filter results...
            //therefore we need to remove variants that dont match

            for (const filteredProduct of filtered) {
                if (filteredProduct.title === '2 roues lumineuses de roller en ligne enfant avec roulements 63mm 80A') {
                    console.log('filtered onto that prod');
                    console.log('includeInStock', includeInStock);
                    console.log('incclude');
                }
                const filteredVariants = filteredProduct.variants.map(v => {
                    if (includeOutOfStock && !v.availableForSale) {
                        return v;
                    }

                    if (includeInStock && v.availableForSale) {
                        return v;
                    }

                    if (includeOrderOnly && v.availableForSale && v.currentlyNotInStock) {
                        return v;
                    }

                    return null;
                });
                console.log('variants for that prod', filteredVariants);

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                filteredProduct.variants = filteredVariants.filter(v => v !== null);
            }

            filtered = filtered.filter(p => p.variants.length > 0);

            // Remembers what the previous selected filter was before this one, so each time a
            // new filter is selected for the first time we will know
            const changedFilters = _.filter(filters, filter => {
                return (
                    filter.type !== FilterType.CategoryHandle &&
                    // filter.type !== FilterType.StockLevel &&
                    !_.find(this._previousFilterSelection, mf => mf.name === filter.name)
                );
            });
            this._previousFilterSelection = filters;

            for (const changedFilter of changedFilters) {
                if (changedFilter) {
                    let filterForWhomWeNeedToRememberTheAttributes = _.find(
                        this._meaningFULFilters,
                        f => f.ecommProviderFieldName === changedFilter.name,
                    );
                    if (!filterForWhomWeNeedToRememberTheAttributes) {
                        // A merged filter
                        const allFiltersIncludingNonVisible = this._configService.config()?.filters ?? [];
                        const child = _.find(
                            allFiltersIncludingNonVisible,
                            f => f.ecommProviderFieldName === changedFilter.name,
                        );

                        if (child) {
                            filterForWhomWeNeedToRememberTheAttributes = _.find(
                                allFiltersIncludingNonVisible,
                                f => f.id === child.parentId,
                            );
                        }
                    }

                    if (filterForWhomWeNeedToRememberTheAttributes) {
                        const matchingMeaningfulFilter = _.find(this._meaningFULFilters, f => {
                            return (
                                f.ecommProviderFieldName ===
                                filterForWhomWeNeedToRememberTheAttributes?.ecommProviderFieldName
                            );
                        });
                        if (matchingMeaningfulFilter) {
                            this._overrideMeaningfulAttributeValues.push({
                                filterName: changedFilter.name,
                                visibleAttributeValues: matchingMeaningfulFilter.attributeValues,
                            });
                        }
                    }
                }
            }

            // Filter out any overrides that are no longer relevant in filters
            this._overrideMeaningfulAttributeValues = _.compact(
                _.filter(
                    this._overrideMeaningfulAttributeValues,
                    ov =>
                        this._preselection.find(f => f.name === ov.filterName) ||
                        (_.find(filters, f => f.name === ov.filterName) ?? null),
                ),
            ) as { filterName: string; visibleAttributeValues: AttributeValue[] }[];

            // Find the name of the filter that just changed by finding an element (if it exists) that isn't present
            // in meaningFULfilters
            const returnedAttributeValues: { [key: string]: string[] } = {};
            const priceMap: { [categoryId: string]: number[] } = {};
            for (const filteredProduct of filtered) {
                // For each mapped attribute, if the key exists in returnedAttributeValues, append the value to the
                // array, otherwise create a new array with the value

                for (const mappedAttribute of filteredProduct.mappedOptions) {
                    // Split the attribute on ':' - the key is the first part and the value is the second. If value is
                    // empty, ignore the attribute
                    // const [key, value] = mappedAttribute.split(':');
                    const splitValues = mappedAttribute.split(':');
                    const key = splitValues.length > 1 ? splitValues[0] : undefined;
                    if (!key) {
                        continue;
                    }
                    const valueSplits = _.cloneDeep(splitValues);
                    valueSplits.shift();
                    const value = valueSplits.join(':');
                    if (value) {
                        if (returnedAttributeValues[key]) {
                            returnedAttributeValues[key].push(value);
                        } else {
                            returnedAttributeValues[key] = [value];
                        }
                    }
                }

                for (const filteredProductVariant of filteredProduct.variants) {
                    // Price
                    // For each categoryid this productvariant is part of, check priceMap and update the min and max values
                    // if necessary
                    for (const categoryId of filteredProduct.categoryIds) {
                        if (priceMap[categoryId]) {
                            priceMap[categoryId][0] = Math.min(priceMap[categoryId][0], filteredProductVariant.price);
                            priceMap[categoryId][1] = Math.max(priceMap[categoryId][1], filteredProductVariant.price);
                        } else {
                            priceMap[categoryId] = [filteredProductVariant.price, filteredProductVariant.price];
                        }
                    }

                    // Promotions
                    if (filteredProductVariant.hasSalePrice && filteredProductVariant.originalPrice > 0) {
                        if (!returnedAttributeValues['Promotions']) {
                            returnedAttributeValues['Promotions'] = ['On Sale'];
                        }
                    }

                    // StockLevel
                    if (filteredProductVariant.availableForSale) {
                        if (filteredProductVariant.currentlyNotInStock) {
                            if (!returnedAttributeValues['Stock']) {
                                returnedAttributeValues['Stock'] = ['Order Only'];
                            } else if (!_.includes(returnedAttributeValues['StockLevel'], 'Order Only')) {
                                returnedAttributeValues['Stock'].push('Order Only');
                            }
                        } else {
                            if (!returnedAttributeValues['Stock']) {
                                returnedAttributeValues['Stock'] = ['In Stock'];
                            } else if (!_.includes(returnedAttributeValues['Stock'], 'In Stock')) {
                                returnedAttributeValues['Stock'].push('In Stock');
                            }
                        }
                    } else {
                        if (!returnedAttributeValues['Stock']) {
                            returnedAttributeValues['Stock'] = ['Out of Stock'];
                        } else if (!_.includes(returnedAttributeValues['Stock'], 'Out of Stock')) {
                            returnedAttributeValues['Stock'].push('Out of Stock');
                        }
                    }

                    //metafields
                    if (filteredProduct.metadata && filteredProduct.metadata.length > 0) {
                        _.map(filteredProduct.metadata, metafield => {
                            const ecommName = `${metafield.key}`;
                            if (!returnedAttributeValues[ecommName]) {
                                returnedAttributeValues[ecommName] = [metafield.data];
                            } else if (!_.includes(returnedAttributeValues[ecommName], metafield.data)) {
                                returnedAttributeValues[ecommName].push(metafield.data);
                            }
                        });
                    }
                }
            }
            // If pricemap has any keys
            if (Object.keys(priceMap).length > 0) {
                returnedAttributeValues['Price'] = ['.']; // dummy value
            }

            // For each key in returnedAttributeValues, _.uniq the array value
            for (const key in returnedAttributeValues) {
                returnedAttributeValues[key] = _.uniq(returnedAttributeValues[key]);
            }

            const allFilters = this._configService.displayableFilters;

            // For each key in returned, find the matching filter object from allFilters using ecommProviderFieldName,
            // and construct a new filter object using the old filter id and new values
            const returned: CloudshelfEngineFilter[] = [buildOrderByFilter()];
            for (const key in returnedAttributeValues) {
                // Find the matching filter object from allFilters using ecommProviderFieldName and key
                // (from returnedAttributeValues)
                let filter = _.find(allFilters, f => f.ecommProviderFieldName === key);
                if (!filter) {
                    // A merged filter
                    const allFiltersIncludingNonVisible = this._configService.config()?.filters ?? [];
                    const child = _.find(allFiltersIncludingNonVisible, f => f.ecommProviderFieldName === key);
                    if (!child) {
                        continue;
                    }
                    filter = _.find(allFiltersIncludingNonVisible, f => f.id === child.parentId);
                }
                if (filter) {
                    // If we have a filterselection override with the same name as the key, use the overridden attribute
                    // values from filter. Otherwise, use the attribute values from returnedAttributeValues. This lets
                    // us "remember" what the filter looked like when it was first selected
                    let attributeValues: EngineAttributeValue[] = [];

                    if (
                        key === 'Price' &&
                        !_.find(this._overrideMeaningfulAttributeValues, f => f.filterName === 'Price')
                    ) {
                        // Create two attributevalues for each categoryid in priceMap; one for min, one for max
                        for (const categoryId in priceMap) {
                            // Min
                            attributeValues.push({
                                value: priceMap[categoryId][0].toString(),
                                priority: -1,
                            });
                            // Max
                            attributeValues.push({
                                value: priceMap[categoryId][1].toString(),
                                priority: 0,
                            });
                        }
                    }

                    const overrideAttributes = _.find(
                        this._overrideMeaningfulAttributeValues,
                        o => o.filterName === key,
                    )?.visibleAttributeValues;

                    if (overrideAttributes) {
                        attributeValues = overrideAttributes;
                    } else {
                        if (key !== 'Price') {
                            attributeValues = _.filter(filter.attributeValues, v =>
                                _.includes(returnedAttributeValues[key], v.value),
                            );
                        }
                    }

                    const existingFilter = _.find(returned, f => f.id === filter?.id);
                    if (existingFilter) {
                        const mergedAttributeValues = _.concat(existingFilter.attributeValues, attributeValues);
                        // deduplicate mergedAttributeValues by joining the categoryIds and values, and parentFilterId
                        existingFilter.attributeValues = _.uniqBy(
                            mergedAttributeValues,
                            v => `${v.value}-${v.priority}-${v.parentFilterId}`,
                        );
                    } else {
                        const newFilter = {
                            ...filter,
                            attributeValues,
                        };
                        returned.push(newFilter);
                    }
                }
            }

            // Ensure each override filter is present in meaningful
            for (const overrideFilter of this._overrideMeaningfulAttributeValues) {
                const existingFilter = _.find(returned, f => f.ecommProviderFieldName === overrideFilter.filterName);
                if (!existingFilter) {
                    const filter = _.find(allFilters, f => f.ecommProviderFieldName === overrideFilter.filterName);
                    if (filter) {
                        const newFilter = {
                            ...filter,
                            attributeValues: overrideFilter.visibleAttributeValues,
                        };
                        returned.push(newFilter);
                    }
                }
            }

            if (setMeaningful) {
                this._meaningFULFilters = _.sortBy(returned, r => r.priority);
                this._meaningfulChangedSubject.next();
            }

            timeToFilter = Date.now() - startTime;

            const preSlice = Date.now();
            const slice = _.slice(filtered, cursor, (cursor ?? 0) + options.limit);
            sliceCount = slice.length;
            timeToSlice = Date.now() - preSlice;

            totalCount = filtered.length;

            return {
                hasMore: (cursor ?? 0) + options.limit < totalCount,
                totalCount,
                items: _.map(slice, (obj, index) => {
                    return {
                        cursor: (cursor ?? 0) + index,
                        ...obj,
                    };
                }),
            };
            // return makeSearchResult({
            //     hasMore: (cursor ?? 0) + options.limit < totalCount,
            //     totalCount,
            //     products: _.map(slice, (obj, index): ProductSearchResultWithCursor => {
            //         const discounts: ProductVariantDiscount[] = [];
            //         const allVariantsForProduct = _.filter(
            //             filtered,
            //             filtered => filtered.eCommercePlatformProductId === obj.eCommercePlatformProductId,
            //         );

            //         const minPrice = _.minBy(allVariantsForProduct, v => v.price)?.price ?? 0;
            //         const maxPrice = _.maxBy(allVariantsForProduct, v => v.price)?.price ?? 0;
            //         const minPriceOriginal = _.minBy(allVariantsForProduct, v => v.originalPrice)?.originalPrice ?? 0;
            //         const maxPriceOriginal = _.maxBy(allVariantsForProduct, v => v.originalPrice)?.originalPrice ?? 0;

            //         for (const variant of allVariantsForProduct) {
            //             const valueDiscount = (variant.originalPrice - variant.price).toFixed(2);
            //             const percentageDiscount = (
            //                 ((variant.originalPrice - variant.price) / variant.originalPrice) *
            //                 100
            //             ).toFixed(2);

            //             let valueDiscountFloat = parseFloat(valueDiscount);
            //             let percentageDiscountFloat = parseFloat(percentageDiscount);

            //             if (valueDiscountFloat < 0) {
            //                 valueDiscountFloat = 0;
            //             }

            //             if (percentageDiscountFloat < 0) {
            //                 percentageDiscountFloat = 0;
            //             }

            //             discounts.push({
            //                 percentage: percentageDiscountFloat,
            //                 value: valueDiscountFloat,
            //             });
            //         }

            //         return {
            //             cursor: (cursor ?? 0) + index,
            //             id: obj.eCommercePlatformProductId,
            //             handle: obj.productHandle,
            //             vendor: obj.productVendor,
            //             title: obj.productTitle,
            //             availableForSale: obj.availableForSale,
            //             productType: obj.productType,
            //             minPrice,
            //             maxPrice,
            //             minPriceOriginal,
            //             maxPriceOriginal,
            //             limitedAvailability: !_.every(allVariantsForProduct, v => v.availableForSale),
            //             orderOnly: _.every(allVariantsForProduct, v => v.currentlyNotInStock),
            //             images: obj.images,
            //             metadata: obj.productMetadata ?? [],
            //             tags: obj.productTags ?? [],
            //             variantsDiscounts: discounts,
            //         };
            //     }),
            // });
        } catch (e) {
            //if error occurs filtering products we want to throw the error so we request all products from Shopify.
            Sentry.captureException(e, {
                extra: {
                    operationName: 'filterProducts',
                },
            });
            throw e;
        } finally {
            LogUtil.Log(
                `Filter Products (Reason: ${filterReason}) took in total ${
                    Date.now() - startTime
                }ms. (${timeToFilter} to filter, ${timeToSlice} to slice), and returned ${sliceCount} products out of a possible ${totalCount} results.`,
            );
        }
        SentryUtil.EndSpan(trans.newTransaction);
        return {
            hasMore: false,
            totalCount: 0,
            items: [],
        };
    }
}
