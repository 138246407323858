import { ApolloClient, ApolloQueryResult, NormalizedCacheObject } from '@apollo/client';
import { CloudshelfInfo, getCloudshelfInfo } from '../../hooks/UseCloudshelfInfo';
import {
    BarcodeDetectionMethod,
    CheckoutExperience,
    ClearSalesAssistantRule,
    CloudshelfEnginePayloadDocument,
    CloudshelfEnginePayloadQuery,
    CloudshelfEnginePayloadQueryVariables,
    CloudshelfPayloadStatus,
    CloudshelfPayloadType,
    ContentType,
    ECommercePlatform,
    EngineType,
    FilterType,
    GetLatestVersionDocument,
    GetLatestVersionQuery,
    GetLatestVersionQueryVariables,
    ImageAnchor,
    KeyValuePair as KeyValuePair2,
    KnownPlugin,
    PdpDescriptionBlock,
    PdpLabelsBlock,
    PdpMetadataBlock,
    PdpProductDataBlock,
    PdpSpacerBlock,
    PdpVariantsBlock,
    PowerTileBackgroundType,
    QrCheckoutDestination,
    SalesAssistantNameRule,
    Size,
    TileSize,
    VisibilityType,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { DeviceInfo } from './DeviceService';
import {
    Banner,
    CheckoutFlow,
    CloudshelfEngineConfig,
    CloudshelfPDPBlock,
    Device,
    DisplayOnlyEngineConfig,
    Plugin,
    TeamMember,
} from './types/config/CloudshelfEngineConfig';
import { FilterValueType } from './types/filters/FilterValueType';
import { CloudshelfEngineFilter } from './types/filters/CloudshelfEngineFilter';
import { EmptyConfig, EmptyDisplayOnlyConfig } from './EmptyConfig';
import { LogLevel, LogUtil } from '../../utils/Logging.Util';
import DependencyType from '../../dependancyInjection/DependencyType';
import { dependenciesContainer } from '../../dependancyInjection/DependenciesInitializer';
import { CloudshelfEngineAttractScreen } from './types/config/CloudshelfEngineAttractScreen';
import * as Sentry from '@sentry/react';
import { TileConfig } from './types/config/TileConfig';
import { StorageService } from '../StorageService/StorageService';
import { StorageKey } from '../StorageService/StorageKeys.enum';
import { Category } from '../CategoryService/entities/Category';
import { SessionManagementService } from '../SessionManagementService/SessionManagementService';
import { buildOrderByFilter, buildStockLevelFilter } from '../../utils/EngineFilter.Util';
import { calculateDPI } from '../../utils/Responsive.Util';
import { decodeBase64StringIfNeeded } from '../../utils/String.Util';
import { KeyValuePair } from './types/config/KeyValuePair';
import { temp_hardcoded_collection_slip, temp_hardcoded_receipt } from './temp_hardcoded';
import { FilterableProduct, FilterableProductVariant } from '../ProductServices/FilterableProductTypes';

export class ConfigurationService {
    private _reloadConfigBlockedReason: string | undefined;
    private _config: CloudshelfEngineConfig | undefined;
    private _configCached: CloudshelfEngineConfig | undefined;
    private _shouldReportPageLoad = true;

    private _providerConfig: KeyValuePair[] = [];
    private _providerConfigCached: KeyValuePair[] = [];

    private _previousStatus: CloudshelfPayloadStatus | undefined;
    private _status: CloudshelfPayloadStatus | undefined;

    private _isDevice: boolean | undefined;

    private _deviceNeedsPairing: boolean | undefined;

    private _configUpdatedSubject: Subject<void> = new Subject<void>();
    private _displayableFilters: CloudshelfEngineFilter[] = [];
    private _productCustomizerPriceModifierVariant: FilterableProductVariant | undefined;
    private _productCustomizerPriceModifierProduct: FilterableProduct | undefined;

    constructor(
        private readonly _apolloClient: ApolloClient<NormalizedCacheObject>,
        private readonly _storageService: StorageService,
    ) {}

    public setup(localDeviceInfo?: DeviceInfo): CloudshelfInfo {
        const cloudshelfInfo = getCloudshelfInfo(localDeviceInfo);
        this._isDevice = cloudshelfInfo.payloadType === CloudshelfPayloadType.Device;
        this._deviceNeedsPairing = cloudshelfInfo.deviceNeedsPairing;
        return cloudshelfInfo;
    }

    private readCachedConfig() {
        LogUtil.Log('Reading cached config', LogLevel.Info);
        const cachedConfigString = this._storageService.get(StorageKey.CLOUDSHELF_CONFIG);
        const cachedProviderConfigString = this._storageService.get(StorageKey.CLOUDSHELF_PROVIDER_CONFIG);

        let cachedConfig: CloudshelfEngineConfig | undefined;
        let cachedProviderConfig: KeyValuePair[] = [];
        if (cachedConfigString && cachedProviderConfigString) {
            cachedConfig = JSON.parse(cachedConfigString) as CloudshelfEngineConfig;
            cachedProviderConfig = JSON.parse(cachedProviderConfigString) as KeyValuePair[];
        }
        this._configCached = cachedConfig;
        this._providerConfigCached = cachedProviderConfig;
    }

    private get hasCachedConfig() {
        return this._configCached !== undefined && this._providerConfigCached !== undefined;
    }

    private useCachedConfig() {
        this._config = this._configCached;
        this._providerConfig = this._providerConfigCached;
        this.setDisplayableFilters(this._config?.filters ?? []);
        this._status = CloudshelfPayloadStatus.Cached;
    }

    private setDisplayableFilters(filters: CloudshelfEngineFilter[]) {
        //We dont want hidden ones
        let sortedFilters = _.filter(filters, filter => !filter.isHidden);

        //We dont want merged children
        sortedFilters = _.filter(sortedFilters, filter => !filter.isMergedChild);

        //We dont want stockLevel if there is only one value
        sortedFilters = _.filter(
            sortedFilters,
            filter => !(filter.type === FilterType.StockLevel && filter.attributeValues.length === 1),
        );

        sortedFilters = _.sortBy(sortedFilters, filter => filter.priority, 'asc');
        this._displayableFilters = sortedFilters;
    }

    public getContentRequestType(): CloudshelfPayloadType {
        const info = getCloudshelfInfo();

        return info.payloadType;
    }

    public setReloadConfigBlockedReason(reason: string | undefined) {
        this._reloadConfigBlockedReason = reason;
    }

    public async refreshConfig(localDeviceInfo?: DeviceInfo): Promise<void> {
        if (this._reloadConfigBlockedReason !== undefined) {
            console.info(`[Config Service] Config reload blocked: ${this._reloadConfigBlockedReason}`);
            return;
        } else {
            console.info(`[Config Service] Reloading Config`);
        }

        this._previousStatus = this._status;
        const cloudshelfInfo = this.setup(localDeviceInfo);
        // Handle display mode preview
        const parsedQuery = new URLSearchParams(window.location.search);
        let payloadId = decodeBase64StringIfNeeded(cloudshelfInfo.cloudshelfOrDeviceId);
        if (payloadId.trim() === '') {
            //we need to force an ID because the backend function will fail validation if we don't
            //this is hacky and I don't like it... but time constraints and all that...
            //so we force an id we know is never going to exist (in the past)
            if (cloudshelfInfo.payloadType === CloudshelfPayloadType.Device) {
                payloadId = 'gid://cloudshelf/device/01H5SYS9AAAAAAAAAAAAAAAAAA';
            } else if (cloudshelfInfo.payloadType === CloudshelfPayloadType.Preview) {
                payloadId = 'gid://cloudshelf/cloudshelf/01H5SYS9AAAAAAAAAAAAAAAAAA';
            }
        }

        this.readCachedConfig();

        let queryTuple: ApolloQueryResult<CloudshelfEnginePayloadQuery>;
        try {
            queryTuple = await this._apolloClient.query<
                CloudshelfEnginePayloadQuery,
                CloudshelfEnginePayloadQueryVariables
            >({
                variables: {
                    payloadType: this.getContentRequestType(),
                    cloudshelfEnginePayloadId: payloadId,
                    reportPageLoad: this._shouldReportPageLoad,
                    engineVersion: process.env.REACT_APP_PACKAGE_VERSION ?? 'unknown',
                },
                query: CloudshelfEnginePayloadDocument,
            });

            if (queryTuple.errors) {
                LogUtil.Log('CloudshelfEnginePayloadQuery returned errors', LogLevel.Warn);
                LogUtil.LogObject(queryTuple.errors, LogLevel.Warn);
                // If some error occurred and we have a cached config we can just show that. If we don't have a cached
                // config, we should show an error.
                if (this.hasCachedConfig) {
                    this.useCachedConfig();
                    this._configUpdatedSubject.next();
                    return;
                }
                throw new Error('Failed to fetch config (1)');
            }
        } catch {
            if (this.hasCachedConfig && !this.isUsingCachedConfig) {
                if (
                    this._previousStatus === undefined ||
                    (this._status !== CloudshelfPayloadStatus.Cached &&
                        this._previousStatus !== CloudshelfPayloadStatus.DeviceWithCloudshelf &&
                        this._previousStatus !== CloudshelfPayloadStatus.CloudshelfPreview &&
                        this._previousStatus !== CloudshelfPayloadStatus.MobileHandoff)
                ) {
                    this.useCachedConfig();
                    this._configUpdatedSubject.next();
                }
                return;
            } else if (!this.hasCachedConfig) {
                throw new Error('Failed to fetch config (2)');
            }
            return;
        }

        this._status = queryTuple.data.cloudshelfEnginePayload.status;
        LogUtil.Log('this._status: ' + this._status, LogLevel.Info);

        if (this._status === CloudshelfPayloadStatus.Notfound) {
            // If not found we will show an error page. We don't even need to try parsing the config.
            this._storageService.delete(StorageKey.CLOUDSHELF_CONFIG);
            this._storageService.delete(StorageKey.CLOUDSHELF_PROVIDER_CONFIG);
            this._config = undefined;
            this._configUpdatedSubject.next();
            return;
        }

        if (this._status === CloudshelfPayloadStatus.Frozen) {
            this._config = undefined;
            this._configUpdatedSubject.next();
            return;
        }

        if (this._status === CloudshelfPayloadStatus.DeviceNoCloudshelf) {
            //The device exists, but we dont have a cloudshelf, so we have to remove the config as it's now invalid
            this._storageService.delete(StorageKey.CLOUDSHELF_CONFIG);
            this._storageService.delete(StorageKey.CLOUDSHELF_PROVIDER_CONFIG);
            this._config = {
                ...EmptyConfig,

                device: queryTuple.data.cloudshelfEnginePayload.device
                    ? {
                          id: queryTuple.data.cloudshelfEnginePayload.device?.id ?? '',
                          name: queryTuple.data.cloudshelfEnginePayload.device?.displayName ?? '',

                          owner: {
                              id: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.id ?? '',
                              name: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.displayName ?? '',
                          },
                          debugMode: false, //todo how to do debug mode
                          isCloudshelfInternalDevice:
                              queryTuple.data.cloudshelfEnginePayload.device?.visibilityType ===
                                  VisibilityType.CloudshelfInternal ?? false,
                      }
                    : undefined,
                deviceMode:
                    parsedQuery.get('previewDisplayMode') !== null
                        ? EngineType.DisplayOnly
                        : queryTuple.data.cloudshelfEnginePayload.device?.engineType ?? EngineType.Interactive,
                inMaintenanceMode: queryTuple.data.cloudshelfEnginePayload.inMaintenanceMode,
            };
            this._configUpdatedSubject.next();
            return;
        }

        if (this._status === CloudshelfPayloadStatus.DeviceWithoutLocation) {
            this._config = {
                ...EmptyConfig,
                device: queryTuple.data.cloudshelfEnginePayload.device
                    ? {
                          id: queryTuple.data.cloudshelfEnginePayload.device?.id ?? '',
                          name: queryTuple.data.cloudshelfEnginePayload.device?.displayName ?? '',

                          owner: {
                              id: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.id ?? '',
                              name: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.displayName ?? '',
                          },
                          debugMode: false, //todo how to do debug mode
                          isCloudshelfInternalDevice:
                              queryTuple.data.cloudshelfEnginePayload.device?.visibilityType ===
                                  VisibilityType.CloudshelfInternal ?? false,
                      }
                    : undefined,
                deviceMode:
                    parsedQuery.get('previewDisplayMode') !== null
                        ? EngineType.DisplayOnly
                        : queryTuple.data.cloudshelfEnginePayload.device?.engineType ?? EngineType.Interactive,
                inMaintenanceMode: queryTuple.data.cloudshelfEnginePayload.inMaintenanceMode,
            };
            this._configUpdatedSubject.next();
            return;
        }

        if (this._status === CloudshelfPayloadStatus.DeviceRemoved) {
            //The device was deleted (dont remove the saved config, because we want to show the whoops screen)
            this._config = {
                ...EmptyConfig,
                inMaintenanceMode: queryTuple.data.cloudshelfEnginePayload.inMaintenanceMode,
            };
            this._configUpdatedSubject.next();
            return;
        }

        const remoteDevice = queryTuple.data.cloudshelfEnginePayload.device;
        const remoteConfig = queryTuple.data.cloudshelfEnginePayload.cloudshelf;

        if (!remoteConfig) {
            if (this.hasCachedConfig) {
                this.useCachedConfig();
                this._configUpdatedSubject.next();
                return;
            }
            throw new Error('Failed to fetch config (3)');
        }

        const attractScreen = {
            callToAction: remoteConfig.homeFrameCallToAction,
            callToActionSize: remoteConfig.homeFrameCallToActionSize,
            callToActionAlignment: remoteConfig.homeFrameCallToActionAlignment,
            displayFrame: remoteConfig.displayHomeFrame,
        } as CloudshelfEngineAttractScreen;

        const tiles: TileConfig[] = [];
        const collectionIds: string[] = [];

        _.map(remoteConfig.content, pt => {
            let tile: TileConfig = {
                id: pt.id,
                priority: pt.position,
                type: pt.contentType,
                title: pt.displayName,
                configurationIssues: pt.configurationIssues,
                tileSize: pt.tileSize,
            };

            if (pt.contentType === ContentType.ProductGroup) {
                if (!pt.productGroup) {
                    return;
                }
                collectionIds.push(pt.productGroup.id);
                tile = {
                    ...tile,
                    title: pt.displayName || pt.productGroup.displayName || '',
                    backgroundImage:
                        (pt.productGroupUseAlternativeImage && pt.productGroupAlternativeImage
                            ? pt.productGroupAlternativeImage
                            : undefined) ||
                        pt.productGroup.featuredImage?.url ||
                        undefined,
                    handle: pt.productGroup.handle,
                    isAllCollectionTile: pt.productGroup.isAllProductGroup,
                    backgroundType: PowerTileBackgroundType.Image,
                    collectionStorefrontId: pt.productGroup.id,
                    collectionId: pt.productGroup.id,
                    productGroupIsUpsellContent: pt.productGroupIsUpsellContent ?? false,
                    productGroupBannerText: pt.productGroupBannerText ?? undefined,
                    productGroupBackgroundImageVertical: pt.productGroupBackgroundImageVertical ?? undefined,
                    productGroupBackgroundImageHorizontal: pt.productGroupBackgroundImageHorizontal ?? undefined,
                    productGroupVisibleInAttractLoop: pt.productGroupVisibleInAttractLoop ?? false,
                    productGroupCallToAction: pt.productGroupCallToAction ?? undefined,
                    productGroupCallToActionDisplayCTA: pt.productGroupCallToActionDisplayCTA ?? false,
                };
            } else if (pt.contentType === ContentType.PowerTile) {
                tile = {
                    ...tile,
                    backgroundImage: pt.powerTileBackgroundImage ?? undefined,
                    backgroundType: pt.powerTileBackgroundType ?? undefined,
                    backgroundPrimaryColor: pt.powerTileBackgroundPrimaryColour ?? undefined,
                    backgroundSecondaryColor: pt.powerTileBackgroundSecondaryColour ?? undefined,
                    title: pt.displayName,
                    icon: pt.powerTileIcon ?? undefined,
                    useIcon: pt.powerTileUseIcon ?? false,
                    callToAction: pt.powerTileCallToAction ?? undefined,
                    qrCodeText: pt.powerTileQRText ?? undefined,
                    qrCodeURL: pt.powerTileQRURL ?? undefined,
                };
            }

            tiles.push(tile);
        });

        let filters: CloudshelfEngineFilter[] = _.map(remoteConfig.filters, (filter): CloudshelfEngineFilter => {
            // Ensure we have a valid filter type. If not, error.
            const filterType = filter.type as FilterType;

            if (!filterType) {
                throw new Error(`Unknown filter type: ${filter.type}`);
            }

            return {
                attributeValues: filter.attributeValues,
                displayName: filter.displayName ?? filter.ecommProviderFieldName,
                hiddenAttributeValues: filter.hiddenAttributeValues,
                ecommProviderFieldName: filter.ecommProviderFieldName,
                expandedByDefault: filter.expandedByDefault,
                id: filter.id,
                type: filterType,
                isHidden: filter.isHidden,
                isMergedChild: filter.isMergedChild,
                parentId: filter.parentId ?? undefined,
                valueType: filterType === FilterType.Price ? FilterValueType.RANGE : FilterValueType.DISCRETE,
                options: filter.options ?? undefined,
                valueOverrides: filter.valueOverrides,
                priority: filter.priority,
                metafieldKey: filter.metafieldKey ?? undefined,
            };
        });

        filters.push(buildOrderByFilter());

        let stockFilterPos = -1;
        const existingStockFiler = filters.find(filter => filter.type === FilterType.StockLevel);

        if (existingStockFiler) {
            stockFilterPos = existingStockFiler.priority;
            //Remove the old stock filter if it exists
            filters = filters.filter(filter => filter.type !== FilterType.StockLevel);

            const stockFilter = buildStockLevelFilter(
                true,
                remoteConfig.includeOnOrderProducts,
                remoteConfig.includeOutOfStockProducts,
                stockFilterPos,
                existingStockFiler.displayName,
            );
            filters.push(stockFilter);
        }

        let device: Device | undefined;

        if (remoteDevice) {
            device = {
                id: remoteDevice.id,
                name: remoteDevice.displayName,
                owner: {
                    id: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.id ?? '',
                    name: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.displayName ?? '',
                },
                location: remoteDevice.location
                    ? {
                          id: remoteDevice.location.id,
                          name: remoteDevice.location.displayName,
                          address: remoteDevice.location.address,
                      }
                    : undefined,
                isCloudshelfInternalDevice: remoteDevice.visibilityType === VisibilityType.CloudshelfInternal ?? false,
                debugMode: false, //todo: debug mode
            };
        }

        const teamMembers: TeamMember[] = [];

        _.map(queryTuple.data.cloudshelfEnginePayload.salesAssistants, tm => {
            let displayName = '';

            if (
                queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.salesAssistantNameRule ===
                SalesAssistantNameRule.FullName
            ) {
                displayName = tm.displayName.trim();
            } else if (
                queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.salesAssistantNameRule ===
                SalesAssistantNameRule.Reference
            ) {
                const trimmedRef = tm.thirdPartyReference.trim();
                if (trimmedRef.length > 0) {
                    displayName = trimmedRef;
                } else {
                    displayName = tm.displayName;
                }
            }

            teamMembers.push({
                id: tm.id,
                displayName,
                reportingValue: `${tm.displayName}${
                    _.trim(tm.thirdPartyReference).length > 0 ? ` (${tm.thirdPartyReference})` : ''
                }`,
            });
        });

        //Handle sales person rules

        const clearSalesPersonRule =
            queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.salesAssistantClearRule ??
            ClearSalesAssistantRule.Never;

        if (clearSalesPersonRule !== ClearSalesAssistantRule.Daily) {
            this._storageService.delete(StorageKey.SALES_ASSOCIATE_EXPIRY);
        } else {
            const expiry = this._storageService.get(StorageKey.SALES_ASSOCIATE_EXPIRY);
            if (expiry) {
                const expiryUnix = parseInt(expiry, 10);
                if (expiryUnix < new Date().getTime()) {
                    this._storageService.delete(StorageKey.SALES_ASSOCIATE_ID);
                }
            }
        }

        const pdpBlocks: CloudshelfPDPBlock[] = [];

        _.map(remoteConfig.pdpBlocks, pdpBlock => {
            if (pdpBlock.__typename === 'PDPDescriptionBlock') {
                const typedBlock = pdpBlock as PdpDescriptionBlock;

                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    style: typedBlock.style,
                    __typename: typedBlock.__typename,
                    displayText: typedBlock.displayName,
                    removeThemeShortcodes: typedBlock.removeThemeShortcodes,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            } else if (pdpBlock.__typename === 'PDPProductDataBlock') {
                const typedBlock = pdpBlock as PdpProductDataBlock;

                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    style: typedBlock.style,
                    __typename: typedBlock.__typename,
                    displayText: typedBlock.displayName,
                    productDataType: typedBlock.productDataType,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            } else if (pdpBlock.__typename === 'PDPMetadataBlock') {
                const typedBlock = pdpBlock as PdpMetadataBlock;

                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    style: typedBlock.style,
                    displayText: typedBlock.displayName,
                    __typename: typedBlock.__typename,
                    key: typedBlock.key,
                    metafieldDisplayType: typedBlock.displayType,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            } else if (pdpBlock.__typename === 'PDPSpacerBlock') {
                const typedBlock = pdpBlock as PdpSpacerBlock;

                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    __typename: typedBlock.__typename,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            } else if (pdpBlock.__typename === 'PDPLabelsBlock') {
                const typedBlock = pdpBlock as PdpLabelsBlock;
                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    __typename: typedBlock.__typename,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            } else if (pdpBlock.__typename === 'PDPVariantsBlock') {
                const typedBlock = pdpBlock as PdpVariantsBlock;
                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    __typename: typedBlock.__typename,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            }
        });
        let forcedScreenSizeStr: string | null = null;
        let forcedScreenSize: number | null = null;

        if (this._status === CloudshelfPayloadStatus.CloudshelfPreview) {
            forcedScreenSizeStr = parsedQuery.get('forceScreenSize');
            if (!forcedScreenSizeStr) {
                forcedScreenSizeStr = this._storageService.get(StorageKey.STORED_PREVIEW_SCREENSIZE) ?? null;
            } else {
                this._storageService.put(StorageKey.STORED_PREVIEW_SCREENSIZE, forcedScreenSizeStr);
            }
        }

        if (process.env.REACT_APP_OVERRIDE_SCREEN_SIZE) {
            forcedScreenSizeStr = process.env.REACT_APP_OVERRIDE_SCREEN_SIZE;
        }

        if (forcedScreenSizeStr) {
            //convert to a number
            forcedScreenSize = parseFloat(forcedScreenSizeStr);
            if (isNaN(forcedScreenSize)) {
                forcedScreenSize = null;
            }
        }

        let calculatedDPI = 96;
        if (forcedScreenSize !== null) {
            calculatedDPI = calculateDPI(forcedScreenSize, window.innerWidth, window.innerHeight);
        } else {
            if (remoteDevice?.screenSizeInches !== undefined && remoteDevice?.screenSizeInches !== null) {
                const screenSize = remoteDevice.screenSizeInches;

                //Calculate DPI based on screen size
                calculatedDPI = calculateDPI(screenSize, window.innerWidth, window.innerHeight);
            }
        }
        const configScreenSize = forcedScreenSize !== null ? forcedScreenSize : remoteDevice?.screenSizeInches ?? 15;

        const plugins: Plugin[] = [];

        _.map(queryTuple.data.cloudshelfEnginePayload.plugins, plugin => {
            const newLocalPlugin: Plugin = {
                id: plugin.id,
                type: plugin.type,
                variables: _.map(plugin.variables, v => {
                    return {
                        key: v.key,
                        value: v.value,
                    };
                }),
            };

            plugins.push(newLocalPlugin);
        });

        console.log('remote:', remoteConfig.theme.imageAnchor);

        const config: CloudshelfEngineConfig = {
            productGridIncludeBrand:
                queryTuple.data.cloudshelfEnginePayload.cloudshelf?.productGridIncludeBrand ?? false,
            allowCustomCSS: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.allowCustomCSS ?? false,
            customCSS: remoteConfig.theme.customCSS ?? undefined,
            plugins: plugins,
            barcodeResultAddToBasket: remoteConfig.addScannedProductsToBasket ?? true,
            barcodeDetectionMethod: remoteDevice?.barcodeDetectionMethod ?? BarcodeDetectionMethod.None,
            barcodePrefix: '',
            barcodePostfix: '',
            tiles: tiles.sort((a, b) => a.priority - b.priority),
            pdpIncludeSuggestedItems:
                queryTuple.data.cloudshelfEnginePayload.cloudshelf?.pdpIncludeSuggestedItems ?? false,
            pdpBlocks: pdpBlocks.sort((a, b) => a.position - b.position),
            id: remoteConfig.id,
            showCloudshelfBranding: !remoteConfig.theme.removeCloudshelfBranding,
            retailerRules: {
                allocateSalesToAssignedSalesPerson:
                    queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.salesAssistantAllocation ?? false,
                salesPersonName:
                    queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.salesAssistantNameRule ??
                    SalesAssistantNameRule.FullName,
                clearSalesPerson:
                    queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.salesAssistantClearRule ??
                    ClearSalesAssistantRule.Never,
            },
            attractLoop: {
                placementX: remoteConfig.attractLoopHomeScreenPlacementX,
                includeHomeScreen: remoteConfig.attractLoopIncludeHomeScreen,
                includeBanners: remoteConfig.attractLoopIncludeBanners,
                includeProductGroups: remoteConfig.attractLoopIncludeProductGroups,
                screenMinimumDuration: remoteConfig.attractLoopItemMinimumDuration,
            },
            bannerDisplayRules: {
                display: {
                    displayMode: remoteConfig.nonInteractiveBannerDisplayMode,
                    duration: remoteConfig.nonInteractiveBannerShowDurationInSeconds,
                },
            },
            banners: (
                remoteConfig.banners?.map(banner => {
                    const mappedBanner: Banner = {
                        backgroundColour: banner.backgroundColour,
                        backgroundImageVertical: banner.backgroundImageVertical ?? undefined,
                        backgroundImageHorizontal: banner.backgroundImageHorizontal ?? undefined,
                        backgroundType: banner.backgroundType,
                        linkProductGroup: banner.linkProductGroup ?? undefined,
                        linkProduct: banner.linkProduct ?? undefined,
                        linkText: banner.linkText ?? undefined,
                        linkType: banner.linkType,
                        linkURL: banner.linkURL ?? undefined,
                        position: banner.position,
                        bannerText: banner.bannerText,
                        id: banner.id,
                    };

                    return mappedBanner;
                }) ?? []
            ).sort((a, b) => a.position - b.position),
            teamMembers,
            device,
            deviceMode:
                parsedQuery.get('previewDisplayMode') !== null
                    ? EngineType.DisplayOnly
                    : remoteDevice?.engineType ?? EngineType.Interactive,
            deviceDPI: calculatedDPI,
            screenSize: configScreenSize,
            forceDebugOverlay: false, //todo how to force debug overlay
            couponsEnabled: remoteConfig.displayDiscountCodeEntry,
            inMaintenanceMode: queryTuple.data.cloudshelfEnginePayload.inMaintenanceMode,
            inactivityTimeout: 60000, //remoteConfig.inactivityTimeout, //todo this in the backend?
            name: remoteConfig.displayName,
            normalizedName: remoteConfig.displayName,
            ownerId: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.id ?? '',
            ownerName: queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.displayName ?? '',
            eCommercePlatform:
                queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.eCommercePlatform ??
                ECommercePlatform.Unknown,
            checkoutFlowOptions: {
                id: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow.id ?? '',
                displayName: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow.displayName ?? '',
                allowPaymentsViaQRCode:
                    queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow.allowPaymentsViaQRCode ?? false,
                paymentQRCodeDestination:
                    queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow.paymentQRCodeDestination ??
                    QrCheckoutDestination.Unknown,
                paymentQRCodeDestinationTransferBasketURL:
                    queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow
                        .paymentQRCodeDestinationTransferBasketURL ?? undefined,
                paymentViaQRCodeAvailableForAcquisitionTypes:
                    queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow
                        .paymentViaQRCodeAvailableForAcquisitionTypes ?? [],
                allowPaymentsViaCards:
                    queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow.allowPaymentsViaCards ?? false,
                paymentViaCardsAvailableForAcquisitionTypes:
                    queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow
                        .paymentViaCardsAvailableForAcquisitionTypes ?? [],
                acquisitionOptions: (
                    queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow.acquisitionOptions ?? []
                ).map(acquisitionOption => {
                    return {
                        id: acquisitionOption.id,
                        position: acquisitionOption.position,
                        internalName: acquisitionOption.internalName,
                        displayName: acquisitionOption.displayName,
                        acquisitionType: acquisitionOption.acquisitionType,
                        availability: acquisitionOption.availability,
                        availabilityTag: acquisitionOption.availabilityTag ?? undefined,
                        availabilityMetadataKey: acquisitionOption.availabilityMetadataKey ?? undefined,
                        availabilityMetadataValue: acquisitionOption.availabilityMetadataValue ?? undefined,
                        priceType: acquisitionOption.priceType,
                        price: acquisitionOption.price,
                        basketValueThreshold: acquisitionOption.basketValueThreshold,
                    };
                }),
                paymentViaCardsProviderSecret:
                    queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutFlow.paymentViaCardsProvider
                        ?.posApiClientSecret ?? undefined,
                purchaseReceiptPrinterBlocks: temp_hardcoded_receipt,
                collectionSlipPrinterBlocks: temp_hardcoded_collection_slip,
            },
            checkoutExperience:
                queryTuple.data.cloudshelfEnginePayload.cloudshelf?.checkoutExperience ?? CheckoutExperience.None,
            displayInStockLabel: remoteConfig.displayInStockLabel,
            displayLimitedLabel: remoteConfig.displayLimitedSelectionLabel,
            displaySoldOutLabel: remoteConfig.displaySoldOutLabel,
            displayOutOfStockLabel: remoteConfig.displayOnOrderLabel,
            inStockLabel: remoteConfig.inStockLabel ?? '',
            includeProductsInStock: true, //we always show instock
            includeProductsOutOfStock: remoteConfig.includeOutOfStockProducts,
            includeProductsLimitedAvailability: remoteConfig.includeOnOrderProducts,
            limitedAvailabilityLabel: remoteConfig.limitedSelectionLabel ?? '',
            outOfStockLabel: remoteConfig.onOrderLabel ?? '',
            soldOutLabel: remoteConfig.soldOutLabel ?? '',
            showTotalStockCount: remoteConfig.displayStockCount,
            displayOnly: {
                displayLogo: remoteConfig.theme.logoSize !== Size.Hidden,
                maxPhotosPerProduct: device?.debugMode
                    ? Number.MAX_VALUE
                    : remoteConfig.nonInteractiveMaximumImagesPerProduct,
                maxProductsPerCollection: device?.debugMode
                    ? Number.MAX_VALUE
                    : remoteConfig.nonInteractiveMaximumProductsPerCollection,
                timePerPhoto: device?.debugMode ? 1 : remoteConfig.nonInteractiveProductImageDurationInSeconds,
                collectionType: remoteConfig.nonInteractiveCollectionType,
                logoSize: remoteConfig.theme.logoSize,
                includeProductName: remoteConfig.nonInteractiveIncludeProductName,
                includePrice: remoteConfig.nonInteractiveIncludeProductPrice,
                includeStock: remoteConfig.nonInteractiveIncludeProductStock,
                includeQRCode: remoteConfig.nonInteractiveIncludeProductQRCode,
            },
            theme: {
                imageAnchor: remoteConfig.theme.imageAnchor,
                logoSize: remoteConfig.theme.logoSize,
                logoForceWhite: remoteConfig.theme.logoForceWhite,
                tileSize: remoteConfig.theme.tileSize,
                dynamicProductGridIncludeSquare: remoteConfig.theme.dynamicProductGridIncludeSquare,
                dynamicProductGridIncludeHero: remoteConfig.theme.dynamicProductGridIncludeHero,
                dynamicProductGridIncludeTall: remoteConfig.theme.dynamicProductGridIncludeTall,
                dynamicProductGridIncludeWide: remoteConfig.theme.dynamicProductGridIncludeWide,
                collectionGridTileModifier: remoteConfig.theme.collectionGridTileModifier ?? 1,
                productGridTileModifier: remoteConfig.theme.productGridTileModifier ?? 1,
                attractScreen,
                primaryColor: remoteConfig.theme.primaryColor,
                primaryContrastColor: remoteConfig.theme.primaryContrastColor,
                mainTextColor: remoteConfig.theme.mainTextColor,
                purchaseTextColor: remoteConfig.theme.purchaseColor,
                attractLoopFontColor: remoteConfig.theme.attractLoopFontColor,
                attractLoopBackgroundColor: remoteConfig.theme.attractLoopBackgroundColor,
                saleOriginalColour: remoteConfig.theme.saleOriginalColour,
                saleColour: remoteConfig.theme.saleColour,
                useProductAnimations: true,
                radius: {
                    inputs: remoteConfig.theme.radius.inputs,
                    drawer: remoteConfig.theme.radius.drawer,
                    modal: remoteConfig.theme.radius.modal,
                    tiles: remoteConfig.theme.radius.tiles,
                },
                headingsFont: {
                    isCustomFont: remoteConfig.theme.headingFont.isCustomFont,
                    fontFamily: remoteConfig.theme.headingFont.fontFamily,
                    fontWeight: remoteConfig.theme.headingFont.fontWeightValue,
                    cdn: remoteConfig.theme.headingFont.fontFamilyCDN ?? undefined,
                },
                subheadingsFont: {
                    isCustomFont: remoteConfig.theme.subheadingFont.isCustomFont,
                    fontFamily: remoteConfig.theme.subheadingFont.fontFamily,
                    fontWeight: remoteConfig.theme.subheadingFont.fontWeightValue,
                    cdn: remoteConfig.theme.subheadingFont.fontFamilyCDN ?? undefined,
                },
                bodyFont: {
                    isCustomFont: remoteConfig.theme.bodyFont.isCustomFont,
                    fontFamily: remoteConfig.theme.bodyFont.fontFamily,
                    fontWeight: remoteConfig.theme.bodyFont.fontWeightValue,
                    cdn: remoteConfig.theme.bodyFont.fontFamilyCDN ?? undefined,
                },
                attractLoopFontSize: remoteConfig.theme.attractLoopFontSize,
                logoUrl: remoteConfig.theme.logoUrl ?? undefined,
                labelRules: remoteConfig.theme.labelRules,
                labelVerticalAlignment: remoteConfig.theme.labelVerticalAlignment,
                labelHorizontalAlignment: remoteConfig.theme.labelHorizontalAlignment,
            },
            trackers: [],
            updatedAt: remoteConfig.updatedAt,
            filters,
            handoff: queryTuple.data.cloudshelfEnginePayload.handoffPayload
                ? {
                      productHandle: queryTuple.data.cloudshelfEnginePayload.handoffPayload.productHandle,
                      productOptionId: queryTuple.data.cloudshelfEnginePayload.handoffPayload.productOptionId,
                  }
                : undefined,
            textSearchSku: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.textSearchSku ?? false,
            textSearchBarcode: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.textSearchBarcode ?? false,
            textSearchDescription: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.textSearchDescription ?? false,
            textSearchProductType: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.textSearchProductType ?? false,
            textSearchProductHandle:
                queryTuple.data.cloudshelfEnginePayload.cloudshelf?.textSearchProductHandle ?? false,
            textSearchProductVendor:
                queryTuple.data.cloudshelfEnginePayload.cloudshelf?.textSearchProductVendor ?? false,
            textSearchMetadata: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.textSearchMetadata ?? false,
            textSearchTags: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.textSearchTags ?? false,
            useOnlineSearch: queryTuple.data.cloudshelfEnginePayload.cloudshelf?.useOnlineSearch ?? false,
        };

        Sentry.setUser({
            username: config.ownerName,
        });
        console.log('Sentry Username:', config.ownerName);

        this._config = config;
        this.setDisplayableFilters(filters);

        if (
            queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.eCommercePlatform === ECommercePlatform.Shopify
        ) {
            let shopifyDomain = '';
            let shopifyStorefrontToken = '';
            const scopes: string[] = [];

            const eCommercePlatformConfiguration =
                queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.eCommercePlatformConfiguration ?? [];

            const scopeConfig = _.find(eCommercePlatformConfiguration, e => e.key === 'scopes');

            if (scopeConfig) {
                scopes.push(...scopeConfig.value.split(','));
            }

            const domainConfig = _.find(eCommercePlatformConfiguration, e => e.key === 'domain');

            if (domainConfig) {
                shopifyDomain = domainConfig.value;
            }

            const storefrontTokenConfig = _.find(
                eCommercePlatformConfiguration,
                e => e.key === 'storefrontAccessToken',
            );

            if (storefrontTokenConfig) {
                shopifyStorefrontToken = storefrontTokenConfig.value;
            }

            this._providerConfig.push({
                key: 'domain',
                values: [shopifyDomain],
            });

            this._providerConfig.push({
                key: 'storefrontToken',
                values: [shopifyStorefrontToken],
            });

            this._providerConfig.push({
                key: 'scopes',
                values: scopes,
            });
        }

        // queryTuple.data.cloudshelfEnginePayload.owningOrganisation?.variables.map(variable => {
        //     this._providerConfig.push({
        //         key: variable.key,
        //         values: [variable.value],
        //     });
        // });

        _.map(queryTuple.data.cloudshelfEnginePayload.plugins, plugin => {
            _.map(plugin.variables, v => {
                this._providerConfig.push({
                    key: v.key,
                    values: [v.value],
                });
            });
        });

        (window as any).CLOUDSHELF_CONFIG = this._config;
        (window as any).PROVIDER_CONFIG = this._providerConfig;
        this._shouldReportPageLoad = false;

        // If user has a session, don't update config
        let hasSession = false;
        try {
            // On initialisation of cloudshelf this won't have been bound yet so will throw an exception. In this case
            // we "assume" there is no session as there can't possibly be one during initialisation.
            const sessionManagementService = dependenciesContainer.get<SessionManagementService>(
                DependencyType.SessionManagementService,
            );
            LogUtil.Log('Got session management service');
            hasSession = sessionManagementService.isSessionActive;
        } catch (err) {
            // We don't care about the error as this will only ever happen on initialisation
        }

        const hasConfigChanged =
            this._status !== CloudshelfPayloadStatus.MobileHandoff &&
            JSON.stringify(this._configCached) !== JSON.stringify(config);
        const statusIsCached = this._status === CloudshelfPayloadStatus.Cached;
        const statusIsHandoff = this._status === CloudshelfPayloadStatus.MobileHandoff;
        const hasCachedConfig = this._configCached;
        const hasCachedProviderConfig = this._providerConfigCached;
        const previousStatusWasCached = this._previousStatus === CloudshelfPayloadStatus.Cached;
        const statusHasChanged = this._previousStatus !== this._status;
        const willRun =
            !statusIsCached &&
            !hasSession &&
            !statusIsHandoff &&
            (!hasCachedConfig ||
                !hasCachedProviderConfig ||
                hasConfigChanged ||
                (!previousStatusWasCached && statusHasChanged));

        console.log('hasConfigChanged: ', hasConfigChanged);
        console.log('cachedConfig: ', this._configCached);
        console.log('config: ', config);

        if (willRun) {
            LogUtil.Log('Config updated - performing update');
            this._storageService.put(StorageKey.CLOUDSHELF_CONFIG, JSON.stringify(this._config));
            this._storageService.put(StorageKey.CLOUDSHELF_PROVIDER_CONFIG, JSON.stringify(this._providerConfig));
            this._configUpdatedSubject.next();
        }
    }

    public async getLatestVersionString(): Promise<string> {
        (window as any).ENV_VERSION = process.env.REACT_APP_PACKAGE_VERSION;

        try {
            const queryTuple = await this._apolloClient.query<GetLatestVersionQuery, GetLatestVersionQueryVariables>({
                query: GetLatestVersionDocument,
            });

            if (queryTuple.errors || !queryTuple.data) {
                return process.env.REACT_APP_PACKAGE_VERSION ?? 'Unknown';
            }

            (window as any).LATEST_VERSION = queryTuple.data.getVersionByType.versionString;

            return queryTuple.data.getVersionByType.versionString;
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'getLatestVersion',
                },
            });
            return process.env.REACT_APP_PACKAGE_VERSION ?? 'Unknown';
        }
    }

    public isDevice(): boolean {
        return this._isDevice ?? false;
    }

    public isInternalDevice(): boolean {
        if (!this._isDevice) {
            return false;
        }

        return this._config?.device?.isCloudshelfInternalDevice ?? false;
    }

    public deviceNeedsPairing(): boolean {
        return this._deviceNeedsPairing ?? false;
    }

    public config(): CloudshelfEngineConfig | undefined {
        return this._config;
    }

    public providerConfig(): KeyValuePair[] {
        return this._providerConfig;
    }

    public status(): CloudshelfPayloadStatus | undefined {
        return this._status;
    }

    public get isUsingCachedConfig(): boolean {
        return this._status === CloudshelfPayloadStatus.Cached;
    }

    public observe(): Observable<void> {
        return this._configUpdatedSubject.asObservable();
    }

    public get cloudshelfId(): string | undefined {
        return this._config?.id;
    }

    public get categories(): Category[] {
        return _.compact(
            _.map(this._config?.tiles, tile => {
                if (tile.type !== ContentType.ProductGroup || !tile.collectionStorefrontId) {
                    return null;
                } else {
                    return {
                        id: tile.collectionStorefrontId,
                        internalId: tile.collectionId ?? '',
                        handle: tile.handle ?? '',
                        image: tile.backgroundImage,
                        title: tile.title,
                        isInternalAllCategory: tile.isAllCollectionTile ?? false,
                        useImageOverride: tile.useImage ?? false,
                        productGroupIsUpsellContent: tile.productGroupIsUpsellContent ?? false,
                    };
                }
            }),
        );
    }

    public get powerTiles(): TileConfig[] {
        return _.compact(
            _.map(this._config?.tiles ?? [], tile => {
                if (tile.configurationIssues.length === 0) {
                    return tile;
                } else {
                    return null;
                }
            }),
        );
    }

    public get displayableFilters(): CloudshelfEngineFilter[] {
        return this._displayableFilters;
    }

    public get shouldUseProductAnimations(): boolean {
        return this._config?.theme?.useProductAnimations ?? false;
    }

    public get imageAnchor(): ImageAnchor {
        return this._config?.theme.imageAnchor ?? ImageAnchor.None;
    }

    public get displayOnlyConfig(): DisplayOnlyEngineConfig {
        return this._config?.displayOnly ?? EmptyDisplayOnlyConfig;
    }
    public get deviceMode(): EngineType {
        return this._config?.deviceMode ?? EngineType.Interactive;
    }

    public get isInPreviewMode(): boolean {
        return this.status() === CloudshelfPayloadStatus.CloudshelfPreview;
    }

    public setProductCustomiserPriceModifierProduct(product: FilterableProduct | undefined): void {
        this._productCustomizerPriceModifierProduct = product;
    }

    public get productCustomiserPriceModifierProduct(): FilterableProduct | undefined {
        return this._productCustomizerPriceModifierProduct;
    }

    public setProductCustomiserPriceModifierVariant(variant: FilterableProductVariant | undefined): void {
        this._productCustomizerPriceModifierVariant = variant;
    }

    public get productCustomiserPriceModifierVariant(): FilterableProductVariant | undefined {
        return this._productCustomizerPriceModifierVariant;
    }

    public get upsellCategory(): Category | undefined {
        //this is a temp thing, it should come from the API.

        const cat = this.categories.find(c => c.productGroupIsUpsellContent);
        return cat;
    }

    public get defaultTileSize(): TileSize {
        return this._config?.theme.tileSize ?? TileSize.Square;
    }

    public get mixedTileSizeOptions() {
        return {
            includeSquare: this._config?.theme.dynamicProductGridIncludeSquare ?? true,
            includeHero: this._config?.theme.dynamicProductGridIncludeHero ?? true,
            includeTall: this._config?.theme.dynamicProductGridIncludeTall ?? true,
            includeWide: this._config?.theme.dynamicProductGridIncludeWide ?? true,
        };
    }

    public get builtInCustomAttributes(): KeyValuePair2[] {
        const kvps: KeyValuePair2[] = [];

        if (this.config()?.checkoutExperience === CheckoutExperience.Basket && this.upsellCategory) {
            kvps.push({
                key: 'CLOUDSHELF_UPSELL',
                value: 'true',
            });
        }

        return kvps;
    }

    public hasKnownPlugin(type: KnownPlugin) {
        const result = this.getKnownPlugin(type);

        if (result) {
            return true;
        }

        return false;
    }

    public getKnownPlugin(type: KnownPlugin) {
        return this.config()?.plugins.find(f => f.type === type);
    }

    public getKnownPluginVariable(type: KnownPlugin, key: string) {
        const plugin = this.getKnownPlugin(type);

        const v = plugin?.variables.find(x => x.key.toUpperCase() === key.toUpperCase());

        if (v) {
            const val = v.value;

            if (!val || val.trim() === '') {
                return null;
            }

            return val;
        }

        return null;
    }

    public checkoutFlow(): CheckoutFlow {
        if (!this._config?.checkoutFlowOptions) {
            console.log('checkoutFlowOptions not found / attempted use before config loaded');
            return EmptyConfig.checkoutFlowOptions;
        }

        console.log('checkoutFlow', this._config.checkoutFlowOptions);
        return this._config.checkoutFlowOptions;
    }

    public get shouldUseOnlineSearch(): boolean {
        if (!this._config?.useOnlineSearch) {
            return false;
        }

        const intAllCat = this.categories.find(f => f.isInternalAllCategory);
        if (intAllCat) {
            return false;
        }

        return true;
    }

    // public hardcodedCheckoutOptions(): CheckoutOptions {
    //     return {
    //         qrPayment: true,
    //         qrPaymentDestination: 'shopify',
    //         qrPaymentAvailability: ['delivery'],
    //         cardPayment: true,
    //         cardPaymentAvailability: ['store_collection', 'pickup'],
    //         acquisitionOptions: [
    //             {
    //                 id: '1',
    //                 position: 1,
    //                 name: 'standard',
    //                 type: 'delivery',
    //                 displayName: 'Delivery (3-5 days)',
    //                 availableFor: 'all_products',
    //                 priceType: 'fixed',
    //                 price: 0,
    //             },
    //             {
    //                 id: '2',
    //                 position: 2,
    //                 name: 'express',
    //                 type: 'store_collection',
    //                 displayName: '2H Collect',
    //                 availableFor: 'tagged_products',
    //                 tag: 'TAG_TEST_1',
    //                 priceType: 'fixed',
    //                 price: 9.99,
    //             },
    //             {
    //                 id: '3',
    //                 position: 3,
    //                 name: 'pickup',
    //                 type: 'pickup',
    //                 displayName: 'Pick-up & Go',
    //                 availableFor: 'in_stock_products',
    //                 priceType: 'fixed',
    //                 price: 0,
    //             },
    //         ],
    //     };
    // }
}
