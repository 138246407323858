import { BannerType } from '../components/apps/InteractiveApp/components/ProductCard/ProductCard';
import { FilterableProduct } from '../services/ProductServices/FilterableProductTypes';

export function getBannerType(product: FilterableProduct): BannerType | undefined {
    let bannerType: BannerType | undefined = undefined;

    const allNotAvailableForSale = product.variants.every(variant => !variant.availableForSale);
    const anyInStock = product.variants.some(variant => variant.currentlyNotInStock);
    const anyOutOfStock = product.variants.some(variant => !variant.currentlyNotInStock);

    //TODO: Check if this is correct
    if (allNotAvailableForSale) {
        bannerType = 'soldOut';
    } else if (anyInStock && anyOutOfStock) {
        bannerType = 'limitedAvailability';
    } else if (!anyInStock && anyOutOfStock) {
        bannerType = 'onOrder';
    } else {
        bannerType = 'inStock';
    }

    return bannerType;
}
