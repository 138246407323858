import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import ProductsLoader from '../../../../shared/ProductsLoader/ProductsLoader';
import BaseServiceScreen from '../../../../shared/ServiceScreens/BaseServiceScreen/BaseServiceScreen';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface HandOffLoadingScreenProps {
    onSetupComplete?: () => void;
    explicitHandle?: string;
}

const HandOffLoadingScreen: FunctionalComponentWithChildren<HandOffLoadingScreenProps> = props => {
    return (
        <BaseServiceScreen>
            <StyledText style={TextStyle.Heading} size={TextSize.Large} className={'HandOffLoadingScreen__text'}>
                Just a moment
            </StyledText>
            <div style={{ marginTop: '3vh' }}>
                <ProductsLoader
                    loadLocalCache={false}
                    explicitHandle={props.explicitHandle}
                    onComplete={() => props.onSetupComplete?.()}
                />
            </div>
        </BaseServiceScreen>
    );
};

export default HandOffLoadingScreen;
