import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import StyledText, { TextSize, TextStyle } from '../../../../../../shared/StyledText/StyledText';
import useStateRef from 'react-usestateref';
import { useTranslation } from 'react-i18next';
import SingleLineText from './fields/SingleLineText';
import MultilineText from './fields/MultilineText';
import ImageUpload from './fields/ImageUpload';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { LogLevel, LogUtil } from '../../../../../../../utils/Logging.Util';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';
import PriceService from '../../../../../../../services/PriceService/PriceService';
import PCSelect from './fields/Select';
import {
    CloudshelfPayloadStatus,
    KeyValuePair,
} from '../../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FilterableProductMetadata } from '../../../../../../../services/ProductServices/FilterableProductTypes';

export interface ProductCustomiserSectionProps {
    metadata: FilterableProductMetadata[];
    onValidate: (isValid: boolean) => void;
    onChange: (values: KeyValuePair[], priceModifier: number) => void;
    productHandle: string;
    showWarning?: boolean;
    handoffFieldId?: number;
    showWarningsWithPristineData?: boolean;
}

export type CustomAttributes = { [name: string]: string };

export interface ProductCustomiserField {
    char_count: number;
    description: string;
    fonts: string;
    label: string;
    name: string;
    option_id: number;
    placeholder: string;
    position: number;
    price: number;
    product_option_id: number;
    required: boolean;
    type: string;
    options: ProductCustomiserSelectOption[];
}

export interface ProductCustomiserSelectOption {
    label: string;
    price: number;
    color: string | undefined;
}
export interface ProductCustomiserCondition {
    operator: 'is' | 'isNot' | 'greaterThan' | 'lessThan' | 'startsWith' | 'endsWith' | 'contains';
    product_option_id: number;
    value: string;
}

export interface ProductCustomiserAction {
    action_type: 'show' | 'hide';
    product_option_id: number;
}

export interface ProductCustomiserConditionalOption {
    conditions: ProductCustomiserCondition[];
    actions: ProductCustomiserAction[];
    position: number;
    logic_match: 'all' | 'any' | 'none';
}

const SUPPORTED_TYPES = ['text', 'multiline', 'file', 'select', 'swatch'];

const ProductCustomiserSection = React.forwardRef<HTMLElement, ProductCustomiserSectionProps>((props, ref) => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const productCustomiserFields = _.filter(props.metadata, mf => mf.key.startsWith('product_customizer'));
    const productCustomiserConditions = _.filter(props.metadata, mf => mf.key.startsWith('product_customizer_x'));
    const [, setFieldPrices, fieldPrices] = useStateRef<{ [key: string]: number }>({});
    const [, setValues, valuesRef] = useStateRef<KeyValuePair[]>([]);
    const [isPristine, setIsPristine] = useState(true);
    const { t } = useTranslation();
    const getFieldKey = useCallback((field: ProductCustomiserField) => `${field.name}-${field.option_id}`, []);

    const setValue = (product_options_id: number, price: number, name: string, value: string) => {
        const productOptionId = product_options_id.toString();
        const newFieldPrices = {
            ...fieldPrices.current,
            [productOptionId]: price,
        };
        const newValues = [...valuesRef.current];

        const existingValue = valuesRef.current.find(v => v.key === name);
        if (existingValue) {
            existingValue.value = value;
        } else {
            newValues.push({ key: name, value });
        }

        if (value === '') {
            //If the value is empty, we need to remove it from the values and the prices
            const index = newValues.findIndex(v => v.key === name);
            if (index !== -1) {
                newValues.splice(index, 1);
            }

            delete newFieldPrices[productOptionId];
        }

        setValues(newValues);
        setFieldPrices(newFieldPrices);

        //We also need to ensure we keep a track of all the prices for the customiser fields

        setIsPristine(false);
    };

    const parsedFields: ProductCustomiserField[] = _.chain(productCustomiserFields)
        .map(mf => {
            const jsonObj = JSON.parse(mf.data);
            const options: ProductCustomiserSelectOption[] = [];

            const optionString = jsonObj.options ?? '';
            const optionPriceString = jsonObj.option_prices ?? '';
            const optionColorString = jsonObj.colors ?? '';

            const optionStrings = optionString.split(',');
            const optionPriceStrings = optionPriceString.split(',');
            const optionColorStrings = optionColorString.split(',');

            // if (optionStrings.length !== optionPriceStrings.length) {
            //     LogUtil.Log(
            //         'ProductCustomiserSection, optionStrings.length !== optionPriceStrings.length',
            //         LogLevel.Warn,
            //     );
            //     console.log('optionStrings', optionStrings);
            //     console.log('optionPriceStrings', optionPriceStrings);
            // } else {
            if (optionColorStrings.length !== 0 && optionColorStrings.length !== optionStrings.length) {
                LogUtil.Log(
                    'ProductCustomiserSection, optionColorStrings.length !== optionStrings.length',
                    LogLevel.Warn,
                );
            }

            //for each option string, we need to loop over them and create a ProductCustomiserSelectOption and fill in the price and color if it exists
            for (let i = 0; i < optionStrings.length; i++) {
                const option = optionStrings[i];

                //get price is the array is long enough, otherwise default to 0
                let price = optionPriceStrings.length > i ? optionPriceStrings[i] : '0';
                if (price.trim() === '') {
                    price = 0;
                }
                let color = optionColorStrings.length > i ? optionColorStrings[i] : undefined;
                if (color !== undefined && optionStrings.length > 1 && optionColorStrings.length === 1) {
                    color = undefined;
                }
                options.push({
                    label: option,
                    price: parseFloat(price),
                    color: color,
                });
            }
            // }
            return {
                char_count: parseFloat(jsonObj.char_count ?? '-1'),
                description: jsonObj.description,
                fonts: jsonObj.fonts,
                label: jsonObj.label,
                name: jsonObj.name,
                option_id: parseFloat(jsonObj.option_id ?? '-1'),
                placeholder: jsonObj.placeholder,
                position: parseFloat(jsonObj.position ?? '1000'),
                price: parseFloat(jsonObj.price ?? '0'),
                product_option_id: parseFloat(jsonObj.product_option_id ?? '-1'),
                required: jsonObj.required === '1',
                type: jsonObj.type,
                options,
            };
        })
        .uniqBy(f => f.product_option_id)
        .sortBy('position')
        .filter(
            field =>
                (SUPPORTED_TYPES.includes(field.type) && !props.handoffFieldId) ||
                field.product_option_id === props.handoffFieldId,
        )
        .value();

    LogUtil.LogObject({ parsedFields });

    // Product customiser only stores 1 value per product for conditions, so we can just get the first
    const parsedConditions =
        productCustomiserConditions.length > 0
            ? (JSON.parse(productCustomiserConditions[0].data) as ProductCustomiserConditionalOption[])
            : [];

    // We want to filter out any conditions for which the field that the condition is checking is not being rendered.
    // This is because we need to ignore conditions for fields which aren't rendered for handoffs.
    const conditions = _.sortBy(
        parsedConditions.filter(c =>
            _.find(parsedFields, f =>
                _.map(c.conditions, cond => cond.product_option_id).includes(f.product_option_id),
            ),
        ),
        condition => condition.position,
    );

    LogUtil.LogObject({ conditions });

    // Product customiser hides by default any field which has a condition that SHOWS it.
    // So if you have color and message,
    // and you have a condition that shows message if a colour is a certain value,
    // then message will be hidden until the
    // colour is selected.
    // So we have to be a bit tricky:
    // 1. Get the list of conditions (done above)
    // 2. For each condition, get the list of actions.
    //    For each action, if the action is "show" a given field, then we need to hide the
    //    field until its condition is met. If the action is "hide" a given field, then we need to show the field until
    //    its condition is met.
    // 3. Then, evaluate each condition, and apply any actions that are needed.
    const hiddenFields: ProductCustomiserField[] = [];
    const shownFields: ProductCustomiserField[] = [...parsedFields];
    if (conditions) {
        // Step 2 - apply default show/hide state
        for (const conditionalField of conditions) {
            for (const action of conditionalField.actions) {
                const fieldId = action.product_option_id;
                const shownField = shownFields.find(field => field.product_option_id === fieldId);
                const hiddenField = hiddenFields.find(field => field.product_option_id === fieldId);
                if (action.action_type === 'show' && shownField) {
                    hiddenFields.push(shownField);
                    shownFields.splice(shownFields.indexOf(shownField), 1);
                } else if (action.action_type === 'hide' && hiddenField) {
                    shownFields.push(hiddenField);
                    hiddenFields.splice(hiddenFields.indexOf(hiddenField), 1);
                }
            }
        }

        // Step 3 - evaluate the conditions
        for (const conditionalField of conditions) {
            let matchFn = _.every;
            if (conditionalField.logic_match === 'all') {
                matchFn = _.every;
            } else if (conditionalField.logic_match === 'any') {
                matchFn = _.some;
            } else if (conditionalField.logic_match === 'none') {
                matchFn = (a: any, b: any) => !_.some(a, b);
            }

            const matches = matchFn(conditionalField.conditions, condition => {
                const field = parsedFields.find(f => f.product_option_id === condition.product_option_id);
                if (!field) {
                    return false;
                }

                const fieldKey = getFieldKey(field);
                const currentAttr = valuesRef.current.find(attr => attr.key === fieldKey);
                const currentValue = fieldKey in valuesRef.current ? currentAttr?.value : '';

                if (!currentValue) {
                    return false;
                }

                if (condition.operator === 'is') {
                    if (field.type === 'file') {
                        return currentValue.length > 0;
                    }
                    return currentValue === condition.value;
                } else if (condition.operator === 'isNot') {
                    if (field.type === 'file') {
                        return currentValue.length === 0;
                    }
                    return currentValue !== condition.value;
                } else if (condition.operator === 'startsWith') {
                    return currentValue.startsWith(condition.value);
                } else if (condition.operator === 'endsWith') {
                    return currentValue.endsWith(condition.value);
                } else if (condition.operator === 'contains') {
                    return currentValue.includes(condition.value);
                } else if (condition.operator === 'greaterThan') {
                    try {
                        return parseFloat(currentValue) > parseFloat(condition.value);
                    } catch {
                        return currentValue.length > condition.value.length;
                    }
                } else if (condition.operator === 'lessThan') {
                    try {
                        return parseFloat(currentValue) < parseFloat(condition.value);
                    } catch {
                        return currentValue.length < condition.value.length;
                    }
                } else {
                    return false;
                }
            });
            if (matches) {
                // Apply the actions
                for (const action of conditionalField.actions) {
                    const fieldId = action.product_option_id;
                    const shownField = shownFields.find(field => field.product_option_id === fieldId);
                    const hiddenField = hiddenFields.find(field => field.product_option_id === fieldId);
                    if (action.action_type === 'show' && hiddenField) {
                        shownFields.push(hiddenField);
                        hiddenFields.splice(hiddenFields.indexOf(hiddenField), 1);
                    } else if (action.action_type === 'hide' && shownField) {
                        hiddenFields.push(shownField);
                        const key = getFieldKey(shownField);
                        const attr = valuesRef.current.find(attr => attr.key === key);
                        if (attr) {
                            attr.value = '';
                        }
                        shownFields.splice(shownFields.indexOf(shownField), 1);
                    }
                }
            }
        }
    }

    useEffect(() => {
        // Is valid if all required fields are filled
        const isValid = _.every(shownFields, field => {
            const key = getFieldKey(field);
            const attr = valuesRef.current.find(attr => attr.key === key);

            const value = attr?.value;
            return field.required ? value && value.length > 0 : true;
        });
        props.onValidate(isValid);

        // Map valuesRef.current to CustomAttributes, removing option id and keeping name in the key
        const values = valuesRef.current.map(attr => {
            const [name] = attr.key.split('-');
            return {
                key: name,
                value: attr.value,
            };
        });

        const additionalCost = _.reduce(
            fieldPrices.current,
            (acc, value, key) => {
                return acc + value;
            },
            0,
        );
        props.onChange(values, additionalCost);
    }, [props.metadata, valuesRef.current, JSON.stringify(shownFields)]);

    if (productCustomiserFields.length === 0) {
        return null;
    }

    const customisationTotalNumber = _.reduce(
        fieldPrices.current,
        (acc, value, key) => {
            return acc + value;
        },
        0,
    );
    const customisationTotal = priceService.convertLowestDenominationToFull(customisationTotalNumber);
    return (
        <section style={{ scrollMarginBottom: '150px' }} ref={ref}>
            <Flipper flipKey={JSON.stringify(shownFields)}>
                {_.map(
                    _.sortBy(shownFields, f => f.position),
                    field => {
                        const key = getFieldKey(field);
                        const currentAttr = valuesRef.current.find(attr => attr.key === key);

                        return (
                            <Flipped flipId={key} key={key}>
                                <div key={field.option_id} className={'ProductCustomiserSection__container'}>
                                    <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                        {field.name}
                                        {field.price !== 0 && (
                                            <StyledText
                                                style={TextStyle.Subheading}
                                                size={TextSize.Small}
                                                className={'ProductCustomiserSection__title__price'}
                                            >
                                                +{priceService.convertLowestDenominationToFull(field.price)}
                                            </StyledText>
                                        )}
                                    </StyledText>
                                    {field.description && (
                                        <StyledText style={TextStyle.Body} size={TextSize.Small}>
                                            {field.description}
                                        </StyledText>
                                    )}
                                    {field.type === 'text' && (
                                        <SingleLineText
                                            field={field}
                                            onChange={val => setValue(field.option_id, field.price, key, val)}
                                            errorText={
                                                field.required &&
                                                (currentAttr || props.showWarning) &&
                                                (!isPristine || props.showWarningsWithPristineData) &&
                                                !currentAttr?.value?.length
                                                    ? t('product_view.field_required')
                                                    : undefined
                                            }
                                        />
                                    )}
                                    {field.type === 'multiline' && (
                                        <MultilineText
                                            field={field}
                                            onChange={val => setValue(field.option_id, field.price, key, val)}
                                            errorText={
                                                field.required &&
                                                (currentAttr || props.showWarning) &&
                                                (!isPristine || props.showWarningsWithPristineData) &&
                                                !currentAttr?.value?.length
                                                    ? t('product_view.field_required')
                                                    : undefined
                                            }
                                        />
                                    )}
                                    {field.type === 'file' && (
                                        <ImageUpload
                                            field={field}
                                            productHandle={props.productHandle}
                                            onChange={val => setValue(field.option_id, field.price, key, val)}
                                            mode={
                                                configService.status() === CloudshelfPayloadStatus.MobileHandoff
                                                    ? 'handoff'
                                                    : 'device'
                                            }
                                            errorText={
                                                field.required &&
                                                (currentAttr || props.showWarning) &&
                                                (!isPristine || props.showWarningsWithPristineData) &&
                                                !currentAttr?.value?.length
                                                    ? t('product_view.field_required')
                                                    : undefined
                                            }
                                        />
                                    )}
                                    {(field.type === 'select' || field.type === 'swatch') && (
                                        <PCSelect
                                            field={field}
                                            onChange={(val, price) => setValue(field.option_id, price, key, val)}
                                            errorText={
                                                field.required &&
                                                (currentAttr || props.showWarning) &&
                                                (!isPristine || props.showWarningsWithPristineData) &&
                                                !currentAttr?.value?.length
                                                    ? t('product_view.field_required')
                                                    : undefined
                                            }
                                        />
                                    )}
                                </div>
                            </Flipped>
                        );
                    },
                )}
                {customisationTotalNumber !== 0 && (
                    <StyledText
                        style={TextStyle.Body}
                        size={TextSize.Small}
                        className={'ProductCustomiserSection__TotalCustomisationPrice'}
                    >
                        Customisation Total: +{customisationTotal}
                    </StyledText>
                )}
            </Flipper>
            <hr />
        </section>
    );
});

export default ProductCustomiserSection;
