import { CloudshelfEngineFilter } from '../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { FilterableProductVariant } from '../services/ProductServices/FilterableProductTypes';

export class ProductVariantUtil {
    static PRODUCT_VARIANT_LABELS_TO_UPPERCASE = [
        '3xs',
        'xxxs',
        'xxs',
        'xs',
        's',
        'm',
        'l',
        'xl',
        'xxl',
        'xxxl',
        '3xl',
        '4xl',
        '5xl',
        '6xl',
        '7xl',
        'o/s',
        'os',
    ];

    static forceOptionLabelCorrectness(value: string): string {
        if (ProductVariantUtil.PRODUCT_VARIANT_LABELS_TO_UPPERCASE.includes(value.toLowerCase())) {
            return value.toUpperCase();
        }
        return value;
    }

    static variantIsOnSale(variant: FilterableProductVariant): boolean {
        return variant.originalPrice !== variant.price && variant.originalPrice > 0;
    }

    static getDisplayNameFromFilters(name: string, filters: CloudshelfEngineFilter[]) {
        let nameToReturn = name.toLowerCase();

        for (const filter of filters) {
            if (filter.ecommProviderFieldName.toLowerCase() === name.toLowerCase()) {
                nameToReturn = filter.displayName;
                break;
            }
        }

        return nameToReturn;
    }
}
