import {
    FilterableProduct,
    FilterableProductVariant,
    FilterableProductMetadata,
} from '../../../../../services/ProductServices/FilterableProductTypes';
import {
    Alignment,
    LabelRuleDisplayType,
    LabelRuleType,
    VerticalAlignment,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import CurrencyService from '../../../../../services/CurrencyService/CurrencyService';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export type CloseButtonCallback = () => void;

export interface DiscountLabelProps {
    product?: FilterableProduct;
    variant?: FilterableProductVariant;
    metadata?: FilterableProductMetadata[];
    useLocation?: boolean;
}

export const DiscountLabel: FunctionalComponentWithChildren<DiscountLabelProps> = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const labelRules = configService.config()?.theme.labelRules ?? [];
    const location:
        | {
              vertical: VerticalAlignment;
              horizontal: Alignment;
          }
        | undefined = props.useLocation
        ? {
              vertical: configService.config()?.theme.labelVerticalAlignment ?? VerticalAlignment.Bottom,
              horizontal: configService.config()?.theme.labelHorizontalAlignment ?? Alignment.Right,
          }
        : undefined;
    const metadata = props.metadata ?? [];

    if (!props.product) {
        return null;
    }

    if (labelRules.length === 0) {
        return null;
    }

    const discountRules = labelRules.filter(r => r.ruleType === LabelRuleType.Discounted);

    if (discountRules.length === 0) {
        return null;
    }

    const buildLabel = (name: string, foregroundColour: string, backgroundColour: string, text: string) => {
        if (text.trim() === '') {
            return null;
        }

        return (
            <div
                className={`ProductLabel ${location ? 'ProductLabel__slim' : ''} ProductLabel__${name
                    .toLocaleLowerCase()
                    .replaceAll(' ', '_')}`}
                style={{ backgroundColor: backgroundColour, color: foregroundColour }}
            >
                {text}
            </div>
        );
    };

    const content = () => {
        return (
            <>
                {props.variant &&
                    props.variant.price !== props.variant.originalPrice &&
                    (() => {
                        const r1 = discountRules[0];
                        let discountLabelText = '';
                        if (r1.displayType === LabelRuleDisplayType.Text) {
                            discountLabelText = r1.text;
                        } else if (r1.displayType === LabelRuleDisplayType.CalculatePriceDiscount) {
                            const discount = props.variant!.price - props.variant!.originalPrice;
                            discountLabelText = `${CurrencyService.formatRounded(Math.abs(discount))} off`;
                        } else if (r1.displayType === LabelRuleDisplayType.CalculatePercentDiscount) {
                            const discountPercent =
                                ((props.variant!.originalPrice - props.variant!.price) / props.variant!.originalPrice) *
                                100;
                            discountLabelText = `${discountPercent.toFixed(0)}% off`;
                        }
                        return buildLabel(r1.name, r1.foregroundColour, r1.backgroundColour, discountLabelText);
                    })()}
            </>
        );
    };

    return (
        <div
            className={`ProductLabels ${
                location
                    ? `ProductLabels__stack ProductLabels__horizontal__${location.horizontal.toLocaleLowerCase()} ProductLabels__vertical__${location.vertical.toLocaleLowerCase()} ${
                          location &&
                          location.horizontal === Alignment.Center &&
                          location.vertical === VerticalAlignment.Center
                              ? 'ProductLabels__location__center'
                              : ''
                      }`
                    : ''
            }`}
        >
            {content()}
        </div>
    );
};
