import React, { FC, useEffect, useState } from 'react';
import { BasketItem } from '../../../../../../../services/BasketService/Basket.type';
import {
    FilterableProduct,
    FilterableProductImage,
    FilterableProductVariant,
} from '../../../../../../../services/ProductServices/FilterableProductTypes';
import { OnBasketItemRemoved } from '../../../Basket/BasketListItem';
import _ from 'lodash';
import NoImagePicture from '../../../../../../icons/no_image';
import StyledText, { TextSize, TextStyle } from '../../../../../../shared/StyledText/StyledText';
import CurrencyService from '../../../../../../../services/CurrencyService/CurrencyService';
import TextTransition from 'react-text-transition';
import Button, { ButtonSize, ButtonVariant } from '../../../../../../shared/inputs/Button/Button';
import { KeyValuePair } from '../../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { BasketService } from '../../../../../../../services/BasketService/BasketService';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import { PurchaseStep, usePurchase } from '../../../../../../../hooks/contexts/Purchase/Purchase';
import { EventsService } from '../../../../../../../services/EventsService/EventsService';
import { DiscountLabel } from '../../../ProductLabels/DiscountLabel';
import { FunctionalComponentWithChildren } from '../../../../../../../FCWithChildren';

export interface BasketListItemProps {
    item: BasketItem;
    onBasketItemRemoved: OnBasketItemRemoved;
    productCustomiserVariant: FilterableProductVariant | undefined;
    onVariantSelectorOpen: (
        product: FilterableProduct,
        variant: FilterableProductVariant,
        basketItem: BasketItem,
    ) => void;
}

const BasketPaneItem: FunctionalComponentWithChildren<BasketListItemProps> = props => {
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [images, setImages] = useState<FilterableProductImage[]>([]);
    const firstImage = _.first(images);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const purchaseContext = usePurchase();

    useEffect(() => {
        const images = _.cloneDeep(props.item.product.images);
        const preferredImage = _.find(images, image => image.preferred);
        //move the preferred image to the front of the array
        if (preferredImage) {
            _.remove(images, preferredImage);
            images.unshift(preferredImage);
        }

        setImages(images);
    }, [props.item.product]);

    const handleClick = () => {
        props.onVariantSelectorOpen(props.item.product, props.item.variant, props.item);
    };

    return (
        <div className={'BasketPaneItem'}>
            <div className={'BasketPaneItem__image'} onClick={handleClick}>
                {firstImage && (
                    <img
                        alt=""
                        src={firstImage?.url}
                        className={'BasketPaneItem__image__img'}
                        onError={() => {
                            //remove this image from the state
                            const newImages = _.cloneDeep(images);
                            _.remove(newImages, firstImage);
                            setImages(newImages);
                        }}
                    />
                )}
                {firstImage === undefined && <NoImagePicture />}
                <div className={'BasketPaneItem__image__quantity'}>
                    <TextTransition>{props.item.quantity}</TextTransition>
                </div>
                <DiscountLabel product={props.item.product} variant={props.item.variant} useLocation />
            </div>
            <div className={'BasketPaneItem__content'}>
                <div className={'BasketPaneItem__content__details'}>
                    <StyledText
                        size={TextSize.ExtraSmall}
                        style={TextStyle.Body}
                        className={'BasketPaneItem__content__details__text'}
                    >
                        {props.item.title}
                    </StyledText>
                    <StyledText
                        size={TextSize.Small}
                        style={TextStyle.Heading}
                        className={'BasketPaneItem__content__details__price'}
                    >
                        {CurrencyService.format(props.item.variant.price)}
                    </StyledText>
                </div>
            </div>
        </div>
    );
};

export default BasketPaneItem;
