import React, { useEffect, useState } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import ProductVariantHelperService from '../../../../../../../services/ProductServices/variant/ProductVariantHelperService';
import ProductVariantOptions from './ProductVariantOptions';
import _ from 'lodash';
import {
    getVariantAvailability,
    ProductVariantAvailability,
} from '../../../../../../../services/ProductServices/ProductVariantAvailability';
import useStateRef from 'react-usestateref';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';
import { FilterableProductVariant } from '../../../../../../../services/ProductServices/FilterableProductTypes';

export type OnVariantSelected = (variant?: FilterableProductVariant) => void;
export interface ProductVariantsProps {
    variants: FilterableProductVariant[];
    originalSelectedVariant?: FilterableProductVariant;
    onVariantSelected: OnVariantSelected;
    onMatchingPartialVariant: OnVariantSelected;
    reactKey?: string;
}

const ProductVariants = React.forwardRef<HTMLElement, ProductVariantsProps>((props, ref) => {
    const { variants, onVariantSelected, onMatchingPartialVariant } = props;
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const productVariantService = useInjection<ProductVariantHelperService>(DependencyType.ProductVariantHelperService);
    const [selectedVariantOptions, setSelectedVariantOptions, selectedVariantOptionsRef] = useStateRef<{
        [optionName: string]: string;
    }>({});
    const settings = productVariantService.getVariantSettings(variants);
    const [firstForVars, setFirstForVars] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        //get the last variant
        if (props.originalSelectedVariant) {
            const options = props.originalSelectedVariant.options.reduce((acc, option) => {
                acc[option.key] = option.value;
                return acc;
            }, {} as { [optionName: string]: string });
            setSelectedVariantOptions(options);
        } else if (variants.length > 0 && (firstForVars === undefined || JSON.stringify(variants) !== firstForVars)) {
            // let initialVariant = variants[variants.length - 1];
            let initialVariant: FilterableProductVariant | undefined = undefined;
            for (let i = variants.length - 1; i >= 0; i--) {
                const variant = variants[i];
                if (getVariantAvailability(variant) !== ProductVariantAvailability.Unavailable) {
                    initialVariant = variant;
                    break;
                }
            }

            if (initialVariant) {
                //set the selectedVariantOptions options
                const options = initialVariant.options;
                const selectedOptions: { [optionName: string]: string } = {};
                options.forEach(option => {
                    selectedOptions[option.key] = option.value;
                });
                setSelectedVariantOptions(selectedOptions);
            } else {
                setSelectedVariantOptions({});
            }

            setFirstForVars(JSON.stringify(variants));
        }
    }, [variants, props.originalSelectedVariant]);

    const onVariantOptionSelected = (optionName: string, optionValue: string): void => {
        let options = _.cloneDeep(selectedVariantOptionsRef.current);
        if (optionName in options && options[optionName] === optionValue) {
            // Remove the option if it is already selected
            delete options[optionName];
            setSelectedVariantOptions(options);
        } else {
            // Set the option
            options = { ...options, [optionName]: optionValue };
        }

        setSelectedVariantOptions(options);

        // Now we have to find the variant that matches the options most closely, and if all options are selected we set
        // the selected variant
        const optionKeys = Object.keys(options);
        const firstMatch = _.find(props.variants, variant => {
            // The variant can't possibly be selected if it's unavailable
            if (getVariantAvailability(variant) === ProductVariantAvailability.Unavailable) {
                return false;
            }

            // Get the first variant that matches all the options that have been selected so far
            const variantOptions = variant.options;
            const foundOptions = _.map(optionKeys, optionKey =>
                _.find(variantOptions, vo => vo.key === optionKey && vo.value === options[optionKey]),
            );
            return _.every(foundOptions, fo => !!fo) && foundOptions.length > 0;
        });

        const selected = productVariantService.getSelectedVariant(variants, options);
        if (!selected) {
            onMatchingPartialVariant(firstMatch);
        } else {
            onVariantSelected(selected);
        }
    };

    const optionsToUse = _.compact(
        Object.keys(settings).map(key => {
            const variantOptions = settings[key];
            if (
                variantOptions.length === 0 ||
                (variantOptions.length === 1 && variantOptions[0].toLowerCase() === 'default title')
            ) {
                return null;
            }
            return key;
        }),
    );

    if (optionsToUse.length === 0) {
        return null;
    }

    console.log('selectedVariantOptions', selectedVariantOptions);

    return (
        <section
            style={{ scrollMarginBottom: '220px' }}
            ref={ref}
            key={`product-variant-options`}
            className={`ProductVariantOptions`}
        >
            {optionsToUse.map(key => {
                const variantOptions = settings[key];
                return (
                    <ProductVariantOptions
                        reactKey={props.reactKey}
                        key={`product-variant-options-${key}`}
                        variantName={key}
                        variantOptions={variantOptions}
                        selectedVariantOptions={selectedVariantOptions}
                        variants={variants}
                        onVariantOptionSelected={onVariantOptionSelected}
                        filters={configService.config()?.filters ?? []}
                    />
                );
            })}
        </section>
    );
});

export default ProductVariants;
