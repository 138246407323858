import React, { KeyboardEventHandler, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_FILTER, NAME_FILTER_ID } from '../../../../provider/cloudshelf/filter/CloudshelfFilters';
import { useInjection } from '../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { FilterType } from '../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FilterSelection, ProductFilteringService } from '../../ProductFilteringService';
import useStateRef from 'react-usestateref';
import './NameFilterInput.scss';
import SearchIcon from '../../../../components/icons/search';
import CircledCrossIcon from '../../../../components/icons/circled_cross';
import { FunctionalComponentWithChildren } from '../../../../FCWithChildren';

export interface INameFilterInputProps {
    initialValue: string;
    onChange: () => void;
    isOnScreen: boolean;
    short?: boolean;
    commitFilters?: boolean;
    minContentLengthForCommit?: number;
    focus?: boolean;
}

export const NameFilterInput: FunctionalComponentWithChildren<INameFilterInputProps> = ({
    initialValue,
    onChange,
    isOnScreen,
    short,
    commitFilters,
    focus,
    minContentLengthForCommit = 3,
}) => {
    const { t } = useTranslation();
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const inputRef = useRef<HTMLInputElement>(null);
    const currentSelection = filteringService.getCurrentSelection();
    const [, setValue, valueRef] = useStateRef(initialValue);
    const [, setOnScreen, onScreenRef] = useStateRef(false);
    const [currentNameSelection, setCurrentNameSelection, currentNameSelectionRef] = useStateRef<string>(
        _.find(currentSelection, selection => selection.definitionId === NAME_FILTER_ID)?.values?.[0] ?? '',
    );

    useEffect(() => {
        if (initialValue.trim() === '') {
            setValue('');
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        } else {
            if (!onScreenRef.current) {
                // This is the first time the component is rendered
                setValue(initialValue);
                if (inputRef.current) {
                    inputRef.current.value = initialValue;
                }
                setOnScreen(true);
            }
        }
    }, [initialValue]);

    useEffect(() => {
        setOnScreen(isOnScreen);
    }, [isOnScreen]);

    useEffect(() => {
        if (focus) {
            inputRef.current?.focus();
        }
    }, [focus]);

    const getNameSelection = (selection: FilterSelection[]) => {
        const newNameSelection =
            _.find(selection, selection => selection.definitionId === NAME_FILTER_ID)?.values?.[0] ?? '';

        if (currentNameSelectionRef.current !== newNameSelection) {
            setCurrentNameSelection(newNameSelection);
        }
        if (valueRef.current !== newNameSelection) {
            setValue(newNameSelection);
            if (inputRef.current) {
                inputRef.current.value = newNameSelection;
            }
        }
    };
    useEffect(() => {
        const filterViewStateObserver = filteringService.observeFilterViewSelectionState();

        const filterViewStateObserverSubscription = filterViewStateObserver.subscribe(
            async (selection: FilterSelection[]) => {
                getNameSelection(selection);
            },
        );

        // const currentSelection = filteringService.getCurrentSelection();
        // getNameSelection(currentSelection);

        return () => filterViewStateObserverSubscription.unsubscribe();
    }, []);

    const handleValueUpdate = (updatedValue: string, forceCommit: boolean) => {
        if (updatedValue === '') {
            filteringService.clearSingleFilter(NAME_FILTER_ID);
            setValue('');
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        } else {
            filteringService.setStringValue(NAME_FILTER_ID, NAME_FILTER, FilterType.ProductTitle, updatedValue, true);
            setValue(updatedValue);
            if (inputRef.current) {
                inputRef.current.value = updatedValue;
            }
        }
        if (commitFilters && (updatedValue.length >= (minContentLengthForCommit ?? 3) || forceCommit)) {
            filteringService.commitSelection();
            console.log('committing');
        }
        onChange();
    };

    const handleInputKeyUp: KeyboardEventHandler<HTMLInputElement> = event => {
        if (event.which === 13) {
            inputRef.current?.blur();
        } else {
            const value = inputRef.current?.value ?? '';
            handleValueUpdate(value, false);
        }
    };

    const debouncedHandleInputKeyUp = _.debounce(handleInputKeyUp, 500);

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        handleValueUpdate(value, true);
    };

    const handleClear = useCallback(() => {
        handleValueUpdate('', true);
        filteringService.clearSearchTerm();
    }, [handleValueUpdate]);

    return (
        <div className="NameFilterInput">
            <div className="NameFilterInput__container">
                <input
                    name="NameFilterInput"
                    className={`NameFilterInput__input ${!short && 'NameFilterInput__input__expanded'}`}
                    onKeyUp={debouncedHandleInputKeyUp}
                    onBlur={handleOnBlur}
                    placeholder={t('common.search')}
                    ref={inputRef}
                    type="text"
                    enterKeyHint="done"
                    autoComplete="off"
                    autoCorrect="off"
                />

                <div className="NameFilterInput__icon SearchIcon" onClick={() => inputRef.current?.focus()}>
                    {inputRef.current?.value === '' && <SearchIcon />}
                    {inputRef.current?.value !== '' && <CircledCrossIcon onClick={handleClear} />}
                </div>
            </div>
        </div>
    );
};
