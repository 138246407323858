import React, { useEffect } from 'react';
import { BasketListItem, OnBasketItemQuantityChanged, OnBasketItemRemoved } from './BasketListItem';
import { Basket, BasketItem } from '../../../../../services/BasketService/Basket.type';
import CouponInput from '../../../../shared/inputs/Coupon/CouponInput';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { Base64 } from 'js-base64';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { StorageKey } from '../../../../../services/StorageService/StorageKeys.enum';
import { StorageService } from '../../../../../services/StorageService/StorageService';
import { useTranslation } from 'react-i18next';
import useStateRef from 'react-usestateref';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { CloudshelfPayloadStatus } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import {
    FilterableProduct,
    FilterableProductVariant,
} from '../../../../../services/ProductServices/FilterableProductTypes';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface BasketListViewProps {
    isOpen: boolean;
    basket: Basket;
    className?: string;
    onQuantityChanged: OnBasketItemQuantityChanged;
    onBasketItemRemoved: OnBasketItemRemoved;
    setLoading: (loading: boolean) => void;
    onVariantSelectorOpen: (
        product: FilterableProduct,
        variant: FilterableProductVariant,
        basketItem: BasketItem,
    ) => void;
}

export const BasketList: FunctionalComponentWithChildren<BasketListViewProps> = props => {
    const { basket, className, onQuantityChanged, onBasketItemRemoved } = props;
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const storageService = useInjection<StorageService>(DependencyType.StorageService);
    const [, setAllocatedSalesPersonId, allocatedSalesPersonIdRef] = useStateRef<string | undefined>(undefined);
    const [salesPersonName, setSalesPersonName] = React.useState<string | undefined>(undefined);
    const showShowSalesPerson = configService.config()?.retailerRules.allocateSalesToAssignedSalesPerson;

    const productCustomiserPriceModifierVariant = configService.productCustomiserPriceModifierVariant;

    const ourSetLoading = (loading: boolean) => {
        setLoading(loading);
        props.setLoading(loading);
    };

    const updateSalesPersonName = () => {
        if (allocatedSalesPersonIdRef.current) {
            const salesPerson = configService
                .config()
                ?.teamMembers.find(member => member.id === allocatedSalesPersonIdRef.current);
            setSalesPersonName(salesPerson?.displayName);
        } else {
            setSalesPersonName(undefined);
        }
    };

    useEffect(() => {
        updateSalesPersonName();
    }, [props.isOpen, allocatedSalesPersonIdRef.current]);

    useEffect(() => {
        const interval = setInterval(() => {
            const salesId = storageService.get(StorageKey.SALES_ASSOCIATE_ID);
            if (salesId !== allocatedSalesPersonIdRef.current) {
                setAllocatedSalesPersonId(salesId);
            }
        }, 250);

        return () => clearInterval(interval);
    });

    const basketListItems = basket.lineItems.map(item => (
        <BasketListItem
            key={`basket-${item.variant.id}-${Base64.encode(JSON.stringify(item.attributes))}`}
            item={item}
            onQuantityChanged={onQuantityChanged}
            onBasketItemRemoved={onBasketItemRemoved}
            setLoading={ourSetLoading}
            loading={loading}
            productCustomiserVariant={productCustomiserPriceModifierVariant}
            onVariantSelectorOpen={props.onVariantSelectorOpen}
            filters={configService.config()?.filters ?? []}
        />
    ));

    return (
        <ul className={`BasketList ${className ?? ''}`}>
            {configService.status() !== CloudshelfPayloadStatus.Cached && configService.config()?.couponsEnabled && (
                <CouponInput />
            )}
            {basketListItems}
            {showShowSalesPerson && salesPersonName && (
                <StyledText className="salesPerson" translate style={TextStyle.Subheading} size={TextSize.Small}>
                    {t('salesPerson.helpedBy')}{' '}
                    <span className="NeverTranslate">
                        <strong>{salesPersonName}</strong>
                    </span>
                </StyledText>
            )}
        </ul>
    );
};
