import { useCallback } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import ProductVariantHelperService from '../../../../../../../services/ProductServices/variant/ProductVariantHelperService';
import { SelectedVariantOptions } from '../../../../../../../types/Types';
import ButtonSelectable from '../../../../../../shared/inputs/ButtonSelectable/ButtonSelectable';
import { capitalize } from '../../../../../../../utils/String.Util';
import {
    getVariantAvailability,
    ProductVariantAvailability,
} from '../../../../../../../services/ProductServices/ProductVariantAvailability';
import _ from 'lodash';
import { ButtonSize } from '../../../../../../shared/inputs/Button/Button';
import { FunctionalComponentWithChildren } from '../../../../../../../FCWithChildren';
import { ProductVariantUtil } from '../../../../../../../utils/ProductVariant.Util';
import { CloudshelfEngineFilter } from '../../../../../../../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { FilterableProductVariant } from '../../../../../../../services/ProductServices/FilterableProductTypes';

export type OnVariantOptionSelected = (variantName: string, variantOption: string) => void;

export interface ProductVariantOptionsProps {
    variantName: string;
    variantOptions: string[];
    selectedVariantOptions: SelectedVariantOptions;
    variants: FilterableProductVariant[];
    onVariantOptionSelected: OnVariantOptionSelected;
    reactKey?: string;
    filters: CloudshelfEngineFilter[];
}

const ProductVariantOptions: FunctionalComponentWithChildren<ProductVariantOptionsProps> = ({
    variantName,
    variantOptions,
    selectedVariantOptions,
    variants,
    onVariantOptionSelected,
    reactKey,
    filters,
}) => {
    console.log('rendered', selectedVariantOptions);
    const productVariantService = useInjection<ProductVariantHelperService>(DependencyType.ProductVariantHelperService);

    const isVariantOptionSelected = useCallback(
        (name: string, option: string): boolean => {
            return selectedVariantOptions[name] === option;
        },
        [selectedVariantOptions],
    );

    const isVariantOptionUnavailable = useCallback(
        (name: string, option: string): boolean => {
            const options = _.cloneDeep(selectedVariantOptions);
            options[name] = option;
            const variantsByOptions = productVariantService.getVariantsBySelectedOption(variants, options);
            return variantsByOptions.every(
                variant => getVariantAvailability(variant) === ProductVariantAvailability.Unavailable,
            );
        },
        [variants, productVariantService, selectedVariantOptions],
    );

    return (
        <section className="ProductVariantOptions__section">
            <h3 className="ProductVariantOptions__title NeverTranslate">
                {ProductVariantUtil.getDisplayNameFromFilters(variantName, filters)}
            </h3>
            <div className="ProductVariantOptions__options">
                {variantOptions.reverse().map(variantOption => {
                    const isSelected = isVariantOptionSelected(variantName, variantOption);
                    return (
                        <ButtonSelectable
                            size={ButtonSize.SM}
                            className="ProductVariantOptions__option"
                            key={`${variantOption}-${variantName}-${reactKey}`}
                            forId={`${variantOption}-${variantName}-${reactKey}`}
                            name={variantName}
                            label={ProductVariantUtil.forceOptionLabelCorrectness(capitalize(variantOption))}
                            type="checkbox"
                            value={variantOption}
                            onSelected={() => onVariantOptionSelected(variantName, variantOption)}
                            selected={isSelected}
                            disabled={isVariantOptionUnavailable(variantName, variantOption)}
                            preventChanges={variantOptions.length === 1}
                            outlined
                        />
                    );
                })}
            </div>
        </section>
    );
};

export default ProductVariantOptions;
