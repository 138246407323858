import { injectable } from 'inversify';
import { SelectedVariantOptions, VariantSettings } from '../../../types/Types';
import _ from 'lodash';
import { FilterableProductVariant, FilterableProductVariantOption } from '../FilterableProductTypes';

@injectable()
export default class ProductVariantHelperService {
    getVariantSettings(variants: FilterableProductVariant[]): VariantSettings {
        return variants
            .map(variant => variant.options)
            .flat()
            .reduce((variantSettings: VariantSettings, { key, value }: FilterableProductVariantOption) => {
                const variantOptions = variantSettings[key];
                if (!variantOptions) {
                    variantSettings[key] = [value];
                    return variantSettings;
                }
                variantSettings[key] = _.uniq([...variantOptions, value]); //.sort(SortingService.sortAsc);
                return variantSettings;
            }, {});
    }

    getSelectedVariant(
        variants: FilterableProductVariant[],
        selectedOptions: SelectedVariantOptions,
    ): FilterableProductVariant | undefined {
        return variants.find(variant => variant.options.every(option => selectedOptions[option.key] === option.value));
    }

    getVariantsBySelectedOption(
        variants: FilterableProductVariant[],
        selectedOptions: SelectedVariantOptions,
    ): FilterableProductVariant[] {
        return variants.filter(variant =>
            Object.keys(selectedOptions).every(selectedOptionName =>
                this.hasVariantOptions(variant.options, selectedOptionName, selectedOptions[selectedOptionName]),
            ),
        );
    }

    hasVariant(variants: FilterableProductVariant[], variantName: string, variantOption: string): boolean {
        return !!variants.find(variant => this.hasVariantOptions(variant.options, variantName, variantOption));
    }

    private hasVariantOptions(
        options: FilterableProductVariantOption[],
        variantName: string,
        variantOption: string,
    ): boolean {
        return !!options.find(option => this.hasVariantOption(option, variantName, variantOption));
    }

    private hasVariantOption(
        option: FilterableProductVariantOption,
        variantName: string,
        variantOption: string,
    ): boolean {
        return option.key === variantName && option.value === variantOption;
    }
}
