import { Observable, Subject } from 'rxjs';
import { injectable } from 'inversify';

export enum BackButtonMode {
    BACK,
    CLOSE,
    NONE,
    BACK_ELSEWHERE, //The back button, but the button is handed by another control
}

export enum MenuStyle {
    BUTTON_EXPANDABLE_SEARCH = 'BUTTON_EXPANDABLE_SEARCH',
    BAR_AND_FILTER_BUTTON = 'BAR_AND_FILTER_BUTTON',
}

@injectable()
export class MenuService {
    private _backButtonTappedObservable: Subject<void> = new Subject<void>();
    private _filterButtonTappedObservable: Subject<void> = new Subject<void>();
    private _backButtonModeObservable: Subject<BackButtonMode> = new Subject<BackButtonMode>();
    private _menuStyleObservable: Subject<MenuStyle> = new Subject<MenuStyle>();
    private _filterButtonVisibleObservable: Subject<boolean> = new Subject<boolean>();
    private _searchVisibleObservable: Subject<boolean> = new Subject<boolean>();
    private _expandedSearchOpenObservable: Subject<boolean> = new Subject<boolean>();
    private _menuCurrentStyle: MenuStyle = MenuStyle.BUTTON_EXPANDABLE_SEARCH;
    private _backButtonCurrentMode: BackButtonMode = BackButtonMode.NONE;
    private _filterButtonVisible = false;
    private _searchVisible = false;
    private _expandedSearchOpen = false;

    public setExpandedSearchOpen(isOpen: boolean) {
        this._expandedSearchOpen = isOpen;
        this._expandedSearchOpenObservable.next(isOpen);
    }

    public setMenuStyle(style: MenuStyle) {
        this._menuCurrentStyle = style;
        this._menuStyleObservable.next(style);

        if (style === MenuStyle.BAR_AND_FILTER_BUTTON) {
            this.setExpandedSearchOpen(false);
        }
    }

    public setBackButtonMode(mode: BackButtonMode) {
        this._backButtonCurrentMode = mode;
        this._backButtonModeObservable.next(mode);
    }

    public setFilterButtonVisible(shouldBeVisible: boolean) {
        this._filterButtonVisible = shouldBeVisible;
        this._filterButtonVisibleObservable.next(shouldBeVisible);
    }

    public setSearchVisible(shouldBeVisible: boolean) {
        this._searchVisible = shouldBeVisible;
        this._searchVisibleObservable.next(shouldBeVisible);
    }

    public get menuStyle(): MenuStyle {
        return this._menuCurrentStyle;
    }

    public get backButtonMode(): BackButtonMode {
        return this._backButtonCurrentMode;
    }

    public get filterButtonVisible(): boolean {
        return this._filterButtonVisible;
    }

    public get searchExpanded(): boolean {
        return this._expandedSearchOpen;
    }

    public fireFilterTapped() {
        this._filterButtonTappedObservable.next();
    }

    public observeFilterTapped(): Observable<void> {
        return this._filterButtonTappedObservable.asObservable();
    }

    public observeFilterVisibilityChange(): Observable<boolean> {
        return this._filterButtonVisibleObservable.asObservable();
    }

    public observeSearchVisibilityChange(): Observable<boolean> {
        return this._searchVisibleObservable.asObservable();
    }

    public fireBackTapped() {
        this._backButtonTappedObservable.next();
    }

    public observeBackTapped(): Observable<void> {
        return this._backButtonTappedObservable.asObservable();
    }

    public observeBackButtonModeChange(): Observable<BackButtonMode> {
        return this._backButtonModeObservable.asObservable();
    }

    public observeMenuStyleChange(): Observable<MenuStyle> {
        return this._menuStyleObservable.asObservable();
    }

    public observeExpandedSearchChange(): Observable<boolean> {
        return this._expandedSearchOpenObservable.asObservable();
    }
}
