import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const NoResultIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.1548 23.4662C31.3329 21.2373 32 18.6965 32 16C32 7.16344 24.8366 0 16 0C13.3035 0 10.7627 0.667061 8.53383 1.84517L10.7794 4.09074C12.3772 3.3893 14.1432 3 16 3C23.1797 3 29 8.8203 29 16C29 17.8568 28.6107 19.6228 27.9093 21.2206L30.1548 23.4662ZM25.5084 24.8652L25.6051 24.9619L27.6308 26.9876C24.7139 30.0741 20.5819 32 16 32C7.16344 32 0 24.8366 0 16C0 11.4194 1.92484 7.28844 5.00978 4.37174L6.4146 5.77138L7.13481 6.4916C4.59073 8.86461 3 12.2465 3 16C3 23.1797 8.8203 29 16 29C19.7535 29 23.1354 27.4093 25.5084 24.8652Z"
                fill="#B7B7B7"
            />
            <path
                d="M31.527 29.2561L2.74119 0.470314C2.44005 0.169177 2.03162 -3.17299e-09 1.60575 0C1.17988 3.17299e-09 0.771451 0.169177 0.470314 0.470314C0.169177 0.771451 3.17299e-09 1.17988 0 1.60575C-3.17299e-09 2.03162 0.169177 2.44005 0.470314 2.74119L4.80417 7.05906L8.00259 10.2575L23.9947 26.2496L26.8093 29.0642L29.2561 31.527C29.4047 31.6768 29.5816 31.7958 29.7765 31.877C29.9714 31.9582 30.1804 32 30.3915 32C30.6026 32 30.8117 31.9582 31.0065 31.877C31.2014 31.7958 31.3783 31.6768 31.527 31.527C31.6768 31.3783 31.7958 31.2014 31.877 31.0065C31.9582 30.8117 32 30.6026 32 30.3915C32 30.1804 31.9582 29.9714 31.877 29.7765C31.7958 29.5816 31.6768 29.4047 31.527 29.2561Z"
                fill="#B7B7B7"
            />
        </svg>
    );
};
export default NoResultIcon;
