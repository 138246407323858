import { useEffect, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { BasketItem } from '../../../../../services/BasketService/Basket.type';
import _ from 'lodash';
import { CloudflareImageService } from '../../../../../services/CloudflareImageService/CloudflareImageService';
import {
    FilterableProduct,
    FilterableProductImage,
    FilterableProductVariant,
} from '../../../../../services/ProductServices/FilterableProductTypes';
import CurrencyService from '../../../../../services/CurrencyService/CurrencyService';
import { KeyValuePair } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import NoImagePicture from '../../../../icons/no_image';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from '../../../../shared/inputs/Button/Button';
import TextTransition from 'react-text-transition';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { CloudshelfEngineFilter } from '../../../../../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { ProductVariantUtil } from '../../../../../utils/ProductVariant.Util';

export type OnBasketItemQuantityChanged = (
    product: FilterableProduct,
    variant: FilterableProductVariant,
    customAttributes: KeyValuePair[],
    newQuantity: number,
    oldQuantity: number,
) => void;

export type OnBasketItemRemoved = (variant: FilterableProductVariant, customAttributes: KeyValuePair[]) => void;

export interface BasketListItemProps {
    item: BasketItem;
    onQuantityChanged: OnBasketItemQuantityChanged;
    onBasketItemRemoved: OnBasketItemRemoved;
    setLoading: (loading: boolean) => void;
    loading: boolean;
    productCustomiserVariant: FilterableProductVariant | undefined;
    onVariantSelectorOpen: (
        product: FilterableProduct,
        variant: FilterableProductVariant,
        basketItem: BasketItem,
    ) => void;
    filters: CloudshelfEngineFilter[];
}

export const BasketListItem: FunctionalComponentWithChildren<BasketListItemProps> = props => {
    const [images, setImages] = useState<FilterableProductImage[]>([]);
    const firstImage = _.first(images);
    const { item, onQuantityChanged, setLoading, onBasketItemRemoved } = props;

    const { t } = useTranslation();
    const { product, variant, quantity, title } = item;
    const cloudflareImageService = useInjection<CloudflareImageService>(DependencyType.CloudflareImageService);
    const optionsListItem = variant.options
        .filter(option => {
            return option.value.toLowerCase() !== variant.title.toLowerCase();
        })
        .map(option => {
            const kkKey = ProductVariantUtil.getDisplayNameFromFilters(option.key, props.filters);
            return (
                <h3
                    className="BasketListItem__optionsListItem NeverTranslate"
                    key={`${variant.id}-optionItem-${option.key}`}
                >
                    {kkKey}: {option.value}
                </h3>
            );
        });

    useEffect(() => {
        const images = _.cloneDeep(props.item.product.images);
        const preferredImage = _.find(images, image => image.preferred);
        //move the preferred image to the front of the array
        if (preferredImage) {
            _.remove(images, preferredImage);
            images.unshift(preferredImage);
        }

        setImages(images);
    }, [props.item.product]);

    const attributesListItem = item.attributes.map(attr => {
        if (attr.key.startsWith('CLOUDSHELF_')) {
            return null;
        }

        return (
            <h3 className="BasketListItem__optionsListItem" key={`${variant.id}-attribute-${attr.key}`}>
                {t(attr.key)}: {cloudflareImageService.isCloudflareUrl(attr.value) ? '[Uploaded Image]' : attr.value}
            </h3>
        );
    });

    const hasHomeDelivery = item.attributes.some(option => option.key === 'CLOUDSHELF_UPSELL');

    // const handleQuantityChange = useCallback(
    //     async (updatedQuantity: number, oldQuantity: number) => {
    //         await setLoading(true);
    //         await onQuantityChanged(item.product, item.variant, item.attributes, updatedQuantity, oldQuantity);
    //         await setLoading(false);
    //     },
    //     [item, onQuantityChanged],
    // );

    // const handleRemove = async () => {
    //     await setLoading(true);
    //     await onBasketItemRemoved(variant, item.attributes);
    //     await setLoading(false);
    // };

    let shouldAllowEdit = true;
    // let shouldShowQuantityControl = true;

    if (props.productCustomiserVariant) {
        if (variant.id === props.productCustomiserVariant.id) {
            shouldAllowEdit = false;
        }
    }

    const price = CurrencyService.format(variant.price * quantity);
    const originalPrice = CurrencyService.format(variant.originalPrice * quantity);

    return (
        <li className="BasketListItem" onClick={() => props.onVariantSelectorOpen(product, variant, item)}>
            <div className="BasketListItem__imageWrapper">
                <div className={'BasketListItem__image'}>
                    {firstImage && (
                        <img
                            alt=""
                            src={firstImage?.url}
                            className={'BasketListItem__image__img'}
                            onError={() => {
                                //remove this image from the state
                                const newImages = _.cloneDeep(images);
                                _.remove(newImages, firstImage);
                                setImages(newImages);
                            }}
                        />
                    )}
                    {firstImage === undefined && <NoImagePicture />}
                    <div className={'BasketListItem__image__quantity'}>
                        <TextTransition>{props.item.quantity}</TextTransition>
                    </div>
                </div>
                <div className="BasketListItem__details">
                    <h2 className="BasketListItem__name NeverTranslate">
                        {title}
                        {_.trim(_.toLower(variant.title)) !== 'default title' && <span>&nbsp;({variant.title})</span>}
                    </h2>
                    {optionsListItem.length > 0 && <ul className="BasketListItem__optionsList">{optionsListItem}</ul>}
                    {attributesListItem.length > 0 && (
                        <ul className="BasketListItem__optionsList">{attributesListItem}</ul>
                    )}
                    {shouldAllowEdit && (
                        <Button
                            variant={ButtonVariant.WHITE}
                            size={ButtonSize.SM}
                            className="BasketListItem__editButton"
                        >
                            {t('edit')}
                        </Button>
                    )}
                    {/* {shouldShowQuantityControl && (
                    <Quantity
                        className="BasketListItem__quantity"
                        initialQuantity={quantity}
                        onQuantityChanged={handleQuantityChange}
                        onRemove={handleRemove}
                        loading={props.loading}
                        variant={'inline'}
                        overrideQuantity={quantity}
                        maxQuantity={item.maxQuantity}
                    />
                )} */}
                </div>
            </div>
            {hasHomeDelivery && (
                <div className="BasketListItem__end" style={{ flexGrow: 1, alignItems: 'center' }}>
                    <h2 className="BasketListItem__price NeverTranslate">{t('upsell.to_be_delivered')}</h2>
                </div>
            )}
            <div className="BasketListItem__end">
                {originalPrice !== price ? (
                    <div className="BasketListItem__price">
                        <h2 className="BasketListItem__price__original NeverTranslate">{originalPrice}</h2>
                        <h2 className="BasketListItem__price__sale NeverTranslate">{price}</h2>
                    </div>
                ) : (
                    <>
                        <h2 className="BasketListItem__price NeverTranslate">{price}</h2>
                    </>
                )}
            </div>
        </li>
    );
};
