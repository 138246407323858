import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { MenuService, MenuStyle } from '../../../../../services/MenuService/MenuService';
import {
    FilterSelection,
    ProductFilteringService,
} from '../../../../../services/ProductServices/ProductFilteringService';
import { Category } from '../../../../../services/CategoryService/entities/Category';
import * as Sentry from '@sentry/react';
import { RoutesHelperService } from '../../../../../services/RoutesService/RoutesHelperService';
import { useHistory } from 'react-router-dom';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import { NAME_FILTER, NAME_FILTER_ID } from '../../../../../provider/cloudshelf/filter/CloudshelfFilters';
import { useTranslation } from 'react-i18next';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { FilterableProductWithCursor } from '../../../../../services/ProductServices/FilterableProductTypes';
import ResponsiveGrid, {
    generateGridProperties,
    ResponsiveGridItemWithKey,
} from '../../../../shared/ResponsiveGrid/ResponsiveGrid';
import { getDeviceDPI } from '../../../../..';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import ProductCard, { BannerType } from '../ProductCard/ProductCard';
import { FilterType, TileSize } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { getSizedImageURL } from '../../../../../utils/ImageURL.Util';
import { getImageTileSize, getPercentOfResponsivePoint } from '../../../../../utils/Responsive.Util';
import { getBannerType } from '../../../../../utils/BannerType.Util';
import PriceService from '../../../../../services/PriceService/PriceService';
import _ from 'lodash';
import MoreProductCard from '../ProductCard/MoreProductCard';
import GridLoader from '../../../../shared/GridLoader/GridLoader';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { SessionEventType } from '../../../../../services/SessionManagementService/SessionEvent';
import NoResultIcon from '../../../../icons/no_result';
import { AttractLoopOrchestratorService } from '../../../../../services/AttractLoopOrchestratorService/AttractLoopOrchestratorService';
import useStateRef from 'react-usestateref';
import AppConfig from '../../../../../types/AppConfig';

const AttractSearch: FunctionalComponentWithChildren = () => {
    const translationService = useTranslation();
    const history = useHistory();
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const [menuIsExpandable, setMenuIsExpandable] = useState(false);
    const [searchIsExpanded, setSearchIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [matchingProducts, setMatchingProducts] = useState<FilterableProductWithCursor[] | undefined>(undefined);
    const [totalCount, setTotalCount] = useState(0);
    const [renderTrigger, setRenderTrigger] = useState<boolean>(false);
    // const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const shouldRender = menuIsExpandable && searchIsExpanded;
    const gridParentDivRef = useRef<HTMLDivElement>(null);
    const attractLoopOrchestratorService = useInjection<AttractLoopOrchestratorService>(
        DependencyType.AttractLoopOrchestratorService,
    );
    const gridSizes = generateGridProperties(
        document.body.clientWidth - getPercentOfResponsivePoint(getDeviceDPI(), 0.8 + 0.6),
        getDeviceDPI(),
        true,
        undefined,
        undefined,
        configService.config()?.theme.productGridTileModifier,
    );

    useEffect(() => {
        if (!filteringService.searchTerm) {
            setMatchingProducts(undefined);
            setTotalCount(0);
        }
    }, [filteringService.searchTerm, renderTrigger]);

    const updateMatchingCount = async (category: Category | undefined, filters: FilterSelection[]) => {
        if (menuIsExpandable) {
            try {
                const titleFilter = filters.find(filter => filter.definitionId === NAME_FILTER_ID);
                const titleFilterValue = titleFilter?.values?.[0] ?? '';
                if (titleFilter && titleFilterValue.length >= 3) {
                    setIsLoading(true);
                    const searchResult = await filteringService.matchingProducts(
                        'Attract Search -> updateMatchingCount',
                        category,
                        filters,
                        { limit: AppConfig.PRODUCTS.PRODUCTS_PER_PAGE },
                        // { limit: gridSizes.numGridColumns },
                        true,
                    );

                    const slicedItems = searchResult.items.slice(0, gridSizes.numGridColumns);

                    setMatchingProducts(slicedItems);

                    if (configService.shouldUseOnlineSearch) {
                        setTotalCount(filteringService.onlineData?.totalCount ?? 0);
                    } else {
                        setTotalCount(searchResult.totalCount);
                    }
                }
            } catch (err) {
                setMatchingProducts(undefined);
                setTotalCount(0);
                Sentry.captureException(err, {
                    extra: {
                        operationName: 'updateMatchingCount',
                    },
                });
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        const menuStyleObserver = menuService.observeMenuStyleChange().subscribe(menuStyle => {
            setMenuIsExpandable(menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH);
        });

        const searchExpandedObserver = menuService.observeExpandedSearchChange().subscribe(isOpen => {
            setSearchIsExpanded(isOpen);
            if (filteringService.searchTerm) {
                filteringService.setStringValue(
                    NAME_FILTER_ID,
                    NAME_FILTER,
                    FilterType.ProductTitle,
                    filteringService.searchTerm,
                    true,
                );
            }
        });

        setMenuIsExpandable(menuService.menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH);
        setSearchIsExpanded(menuService.searchExpanded);

        const filterViewStateObserver = filteringService.observeFilterViewSelectionState();

        const filterViewStateObserverSubscription = filterViewStateObserver.subscribe(async filterSelection => {
            const textFilter = filterSelection.find(f => f.definitionId === NAME_FILTER_ID);

            if (textFilter) {
                if (textFilter.values.length !== 0) {
                    filteringService.storeSearchTerm(textFilter.values[0]);
                } else {
                    filteringService.clearSearchTerm();
                    setRenderTrigger(prev => !prev);
                }
            } else {
                filteringService.clearSearchTerm();
                setRenderTrigger(prev => !prev);
            }

            await updateMatchingCount(undefined, filterSelection);
        });

        const sessionEndedSub = sessionManagementService.observe().subscribe(event => {
            if (event.type === SessionEventType.Ended) {
                menuService.setExpandedSearchOpen(false);
            }
        });

        return () => {
            menuStyleObserver.unsubscribe();
            searchExpandedObserver.unsubscribe();
            filterViewStateObserverSubscription.unsubscribe();
            sessionEndedSub.unsubscribe();
        };
    });

    const handleBackgroundClick = () => {
        if (shouldRender) {
            menuService.setExpandedSearchOpen(false);
        }
    };

    const handleShowOne = (url: string) => {
        attractLoopOrchestratorService.requestStopFromInteraction();
        eventsService.requestBannersClose();
        if (filteringService.searchTerm) {
            filteringService.setStringValue(
                NAME_FILTER_ID,
                NAME_FILTER,
                FilterType.ProductTitle,
                filteringService.searchTerm,
                true,
            );
        }
        filteringService.commitSelection();
        history.push(url);
    };

    const handleShowMore = () => {
        attractLoopOrchestratorService.requestStopFromInteraction();
        eventsService.requestBannersClose();
        if (filteringService.searchTerm) {
            filteringService.setStringValue(
                NAME_FILTER_ID,
                NAME_FILTER,
                FilterType.ProductTitle,
                filteringService.searchTerm,
                true,
            );
        }
        filteringService.commitSelection();
        const categoryProductsRoute = RoutesHelperService.toCategoryProductsViaHandle('INTERNAL_ALL');
        history.push(categoryProductsRoute);
    };

    const items = useMemo((): ResponsiveGridItemWithKey[] => {
        if (!matchingProducts) {
            return [];
        }
        const tileSize: TileSize = TileSize.Square;

        const items: ResponsiveGridItemWithKey[] = [];
        let mappableItems = matchingProducts;
        let imageForPlusMore: string | undefined = undefined;

        const hasTooManyToShow = gridSizes.numGridColumns <= matchingProducts.length;

        if (hasTooManyToShow) {
            mappableItems = _.slice(mappableItems, 0, gridSizes.numGridColumns - 1);

            const lastItem = matchingProducts.pop();
            if (lastItem) {
                let preferredImage = lastItem.images.find(image => image.preferred);
                if (!preferredImage && lastItem.images.length !== 0) {
                    preferredImage = lastItem.images[0];
                }

                const imageSizeFloored = Math.floor(getImageTileSize());
                if (preferredImage) {
                    imageForPlusMore = getSizedImageURL(
                        preferredImage.url,
                        imageSizeFloored,
                        imageSizeFloored,
                        configService.imageAnchor,
                    );
                }
            }
        }

        mappableItems.map((product: FilterableProductWithCursor, index: number) => {
            const url = RoutesHelperService.toCategoryProductDetails(product);
            const originalPrice = priceService.getFromPriceFromProduct(product, true);
            const price = priceService.getFromPriceFromProduct(product);

            const bannerType: BannerType | undefined = getBannerType(product);

            let preferredImage = product.images.find(image => image.preferred);
            if (!preferredImage && product.images.length !== 0) {
                preferredImage = product.images[0];
            }

            let imageUrl: string | undefined = undefined;
            const imageSizeFloored = Math.floor(getImageTileSize());
            if (preferredImage) {
                imageUrl = getSizedImageURL(
                    preferredImage.url,
                    imageSizeFloored,
                    imageSizeFloored,
                    configService.imageAnchor,
                );
            }

            items.push({
                element: (
                    <ProductCard
                        key={product.id}
                        handle={product.handle}
                        imageUrl={imageUrl}
                        brand={product.vendor}
                        title={product.title}
                        price={price}
                        originalPrice={originalPrice}
                        onClicked={() => {
                            handleShowOne(url);
                        }}
                        bannerType={bannerType}
                        imageWidth={gridSizes.columnSize}
                        metadata={product.metadata}
                        tags={product.tags}
                        discounts={product.discounts}
                    />
                ),
                flipId: product.id,
                size: tileSize,
            });
        });

        if (hasTooManyToShow) {
            items.push({
                element: (
                    <MoreProductCard
                        overlayText={`+ ${totalCount - items.length}`}
                        imageUrl={imageForPlusMore}
                        imageWidth={gridSizes.columnSize}
                        onClicked={handleShowMore}
                    />
                ),
                flipId: 'PlusMore',
                size: tileSize,
            });
        }

        return items;
    }, [history, priceService, matchingProducts]);

    return (
        <div className={`AttractSearch ${shouldRender && 'AttractSearch__show'}`} onClick={handleBackgroundClick}>
            {shouldRender && (
                <div className="AttractSearch__container">
                    <div
                        className={`AttractSearch__card ${
                            matchingProducts !== undefined || isLoading ? 'AttractSearch__card__show' : ''
                        }`}
                        style={{ height: gridSizes.rowSize + getPercentOfResponsivePoint(getDeviceDPI(), 0.4) }}
                    >
                        {isLoading && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        height: gridSizes.columnSize / 3,
                                        width: gridSizes.columnSize / 3,
                                    }}
                                >
                                    <GridLoader />
                                </div>
                            </div>
                        )}
                        {!isLoading && matchingProducts && matchingProducts.length === 0 && (
                            <div className="AttractSearch__noResults">
                                <NoResultIcon />
                                <p>{translationService.t('no_results')}</p>
                            </div>
                        )}
                        {!isLoading && matchingProducts && matchingProducts.length > 0 && (
                            <div className="AttractSearch__results " ref={gridParentDivRef}>
                                <ResponsiveGrid
                                    additionalClassName="AttractSearch__GridItem"
                                    gridSizes={gridSizes}
                                    items={items}
                                    hasMoreItems={false}
                                    onMoreItemsRequested={() => {}}
                                    shouldUseProductAnimations={false}
                                    // temp remove all animations to fix speed issues
                                    // shouldUseProductAnimations={configService.shouldUseProductAnimations}
                                    scrollableTarget="AttractSearch__results"
                                    setRowHeight
                                    noPadding
                                />
                            </div>
                        )}
                    </div>

                    {/* <Button
                        className={'AttractSearch__button'}
                        size={ButtonSize.LG}
                        onClick={handleShowSelectionClick}
                        disabled={matchingProductsCount === 0}
                        style={{ marginTop: `calc(3 * var(--responsive-reference-point))` }}
                    >
                        {isLoading ? (
                            'Loading...'
                        ) : (
                            <>
                                {matchingProductsCount === 0 && translationService.t('filters_view.no_products')}
                                {matchingProductsCount > 0 &&
                                    translationService.t('filters_view.show', { productsCount: matchingProductsCount })}
                                {matchingProductsCount === -1 && translationService.t('filters_view.show_all')}
                            </>
                        )}
                    </Button> */}
                </div>
            )}
        </div>
    );
};

export default AttractSearch;
