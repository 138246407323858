import React, { useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { SessionManagementService } from '../SessionManagementService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import Modal from '../../../components/shared/Modal/Modal';
import Button, { ButtonVariant } from '../../../components/shared/inputs/Button/Button';
import { DEFAULT_TIMEOUT } from './SessionManager.constants';
import StyledText, { TextSize, TextStyle } from '../../../components/shared/StyledText/StyledText';
import { useTranslation } from 'react-i18next';
import './SessionManager.scss';
import useStateRef from 'react-usestateref';
import { ConfigurationService } from '../../ConfigurationService/ConfigurationService';
import { StorageKey } from '../../StorageService/StorageKeys.enum';
import { StorageService } from '../../StorageService/StorageService';
import { BasketService } from '../../BasketService/BasketService';
import { useFavicon } from 'react-use';
import { CheckoutService } from '../../CheckoutService/CheckoutService';
import { IFrameEventUtil } from '../../../utils/iFrameEvent.Util';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';
import _ from 'lodash';

export const SessionManager: FunctionalComponentWithChildren = ({ children }) => {
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const configurationService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const storageService = useInjection<StorageService>(DependencyType.StorageService);
    const checkoutService = useInjection<CheckoutService>(DependencyType.CheckoutService);

    const lastBasketValue = useRef(0);
    const lastInteractions = useRef(0);
    const lastSalesAssistantId = useRef<string | undefined>(undefined);
    const history = useHistory();
    const [remaining, setRemaining] = useStateRef(0);
    const expiringSoon = sessionManagementService.isSessionActive && remaining < 10000 && remaining !== 0;
    const { t } = useTranslation();
    const { inactivityTimeout } = configurationService.config() || {};
    const oneDay = 60 * 1000 * 60 * 24;

    const timer = useIdleTimer({
        eventsThrottle: 500,
        passive: true,
        capture: true,
        events: ['mousedown', 'touchstart', 'MSPointerDown'],
        onIdle: () => {
            if (sessionManagementService.isSessionActive) {
                void sessionManagementService.endSession();
                basketService.empty();
                setRemaining(0);

                IFrameEventUtil.sendIFrameEvent(IFrameEventUtil.SESSION_END_EVENT);

                console.error('Session Ended, routing to "/"');
                history.replace('/');
            }
        },
        onAction: async (e: Event) => {
            if (!sessionManagementService.currentSessionId) {
                await sessionManagementService.newSession();

                IFrameEventUtil.sendIFrameEvent(IFrameEventUtil.SESSION_START_EVENT);
            }

            sessionManagementService.onInteract();
        },
        timeout: process.env.REACT_APP_DISABLE_SESSIONS === `true` ? oneDay : inactivityTimeout ?? DEFAULT_TIMEOUT,
    });

    useEffect(() => {
        const interval = setInterval(async () => {
            if (sessionManagementService.isSessionActive) {
                const salesAssistantId = storageService.get(StorageKey.SALES_ASSOCIATE_ID, true);
                const lastInteraction = sessionManagementService.interactionCount();
                const basketValue = checkoutService.totalPriceAsNumber;

                if (
                    lastInteractions.current !== lastInteraction ||
                    lastSalesAssistantId.current !== salesAssistantId ||
                    lastBasketValue.current !== basketValue
                ) {
                    await sessionManagementService.updateSession();
                    lastInteractions.current = lastInteraction;
                    lastSalesAssistantId.current = salesAssistantId;
                    lastBasketValue.current = basketValue;
                }
                setRemaining(timer.getRemainingTime());
            }
        }, 1250);

        return () => clearInterval(interval);
    }, [timer, sessionManagementService]);

    return (
        <>
            {children}

            {expiringSoon && (
                <Modal topMost isOpen displayVariant="compact" overrideZIndex={99999} dontChangeMenu>
                    <div className="SessionModal">
                        <StyledText style={TextStyle.Subheading} size={TextSize.Large} translate>
                            Your selection will clear in{' '}
                            <span className="NeverTranslate">{(timer.getRemainingTime() / 1000).toFixed(0)}</span>
                        </StyledText>
                        <Button
                            className="Session__continue"
                            text={t('session.continue_button')}
                            variant={ButtonVariant.PRIMARY}
                            translate
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};
