import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { PanelHeader } from '../PanelHeader/PanelHeader';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import {
    ContinuePurchaseFlowCallback,
    PurchaseStep,
    usePurchase,
} from '../../../../../hooks/contexts/Purchase/Purchase';
import { Category } from '../../../../../services/CategoryService/entities/Category';
import ResponsiveGrid, {
    generateGridProperties,
    ResponsiveGridItemWithKey,
} from '../../../../shared/ResponsiveGrid/ResponsiveGrid';
import { getDeviceDPI } from '../../../../../index';
import ProductCard, { BannerType } from '../ProductCard/ProductCard';
import { getSizedImageURL } from '../../../../../utils/ImageURL.Util';
import PriceService from '../../../../../services/PriceService/PriceService';
import {
    ProductFilteringService,
    ProductsFetchOptions,
} from '../../../../../services/ProductServices/ProductFilteringService';
import _ from 'lodash';
import AppConfig from '../../../../../types/AppConfig';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import {
    FilterableProduct,
    FilterableProductVariant,
    FilterableProductWithCursor,
} from '../../../../../services/ProductServices/FilterableProductTypes';
import { useTranslation } from 'react-i18next';
import { VariantSelectorModal } from '../VariantSelectorModal/VariantSelectorModal';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { getBannerType } from '../../../../../utils/BannerType.Util';

export interface UpsellViewProps {
    isOpen: boolean;
    onClose: () => void;
    onContinue: ContinuePurchaseFlowCallback;
    category: Category;
    avoidProducts: string[];
}

export const UpsellView: FunctionalComponentWithChildren<UpsellViewProps> = ({
    onClose,
    category,
    onContinue,
    isOpen,
    avoidProducts,
}) => {
    const limit = AppConfig.PRODUCTS.PRODUCTS_PER_PAGE;
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const gridParentDivRef = useRef<HTMLDivElement>(null);

    const gridSizes = generateGridProperties(
        document.body.clientWidth,
        getDeviceDPI(),
        true,
        undefined,
        undefined,
        configService.config()?.theme.productGridTileModifier,
    );
    const [upsellProducts, setUpsellProducts] = useState<FilterableProductWithCursor[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const purchaseContext = usePurchase();
    // const [selectedVariant, setSelectedVariant] = useState<LocalProductVariant | undefined>(undefined);
    const { t } = useTranslation();
    const [variantSelectorLocalProduct, setVariantSelectorLocalProduct] = useState<FilterableProduct | undefined>(
        undefined,
    );
    const [variantSelectorCurrentVariant, setVariantSelectorCurrentVariant] = useState<
        FilterableProductVariant | undefined
    >(undefined);
    // const [variantSelectorBasketItem, setVariantSelectorBasketItem] = useState<BasketItem | undefined>(undefined);

    useEffect(() => {
        void handleFetch({ limit: limit }).then(matching => {
            setHasMore(matching.hasMore);
            setUpsellProducts(matching.items);
        });
    }, [category, avoidProducts]);

    // useEffect(() => {
    //     if (productForVariantModal && productForVariantModal.variants.length >= 1) {
    //         setSelectedVariant(productForVariantModal.variants[0]);
    //     } else {
    //         setSelectedVariant(undefined);
    //     }
    // }, [productForVariantModal]);

    const handleFetch = async (options: ProductsFetchOptions) => {
        const matching = await filteringService.matchingProducts(
            'CategoryProductPage -> handleFetchProducts',
            category,
            [],
            options,
            false,
            avoidProducts,
        );

        return matching;
    };

    const loadMoreProducts = async () => {
        if (!hasMore) {
            return;
        }

        let cursor = _.last(upsellProducts)?.cursor;

        if (_.isNumber(cursor)) {
            cursor = cursor + 1;
        }
        const options = { limit, cursor };

        const nextProducts = await handleFetch(options);
        setHasMore(nextProducts.hasMore);
        setUpsellProducts(upsellProducts.concat(nextProducts.items));
    };

    const handleAddToBasket = useCallback(
        async (product: FilterableProduct, variant: FilterableProductVariant, qtyToAdd = 1, updateUI = true) => {
            const existingQty = await basketService.getItemQuantity(variant.id, [
                {
                    key: 'CLOUDSHELF_UPSELL',
                    value: 'true',
                },
            ]);

            await basketService.setItemQuantity(
                product,
                variant,
                existingQty + qtyToAdd,
                [
                    {
                        key: 'CLOUDSHELF_UPSELL',
                        value: 'true',
                    },
                ],
                product?.title ?? variant,
            );

            if (updateUI) {
                const newUpsellProducts = upsellProducts.filter(p => p.id !== product.eCommercePlatformProvidedId);
                setUpsellProducts(newUpsellProducts);
            }
        },
        [upsellProducts, basketService],
    );

    const handleOnDirectBasketAddClicked = async (product: FilterableProductWithCursor) => {
        const allProductDetails = await filteringService.getFilterableProductByHandle(
            product.handle,
            // configService.config()?.includeProductsOutOfStock ?? false,
        );

        if (allProductDetails && allProductDetails.variants.length > 0) {
            setVariantSelectorLocalProduct(allProductDetails);
            setVariantSelectorCurrentVariant(allProductDetails.variants[0]);
        }

        // if (allProductDetails?.variants.length === 1) {
        // const theVariant = allProductDetails.variants[0];

        // await handleAddToBasket(allProductDetails, theVariant, 1, true);
        // } else {

        // setVariantSelectorBasketItem(undefined);
        // }
    };

    const items = useMemo(
        (): ResponsiveGridItemWithKey[] =>
            upsellProducts.map(product => {
                const originalPrice = priceService.getFromPriceFromProduct(product, true);
                const price = priceService.getFromPriceFromProduct(product);

                const bannerType: BannerType | undefined = getBannerType(product);

                let preferredImage = product.images.find(image => image.preferred);
                if (!preferredImage && product.images.length !== 0) {
                    preferredImage = product.images[0];
                }

                let imageUrl: string | undefined = undefined;
                const imageSizeFloored = Math.floor(gridSizes.columnSize);
                if (preferredImage) {
                    imageUrl = getSizedImageURL(
                        preferredImage.url,
                        imageSizeFloored,
                        imageSizeFloored,
                        configService.imageAnchor,
                    );
                }

                return {
                    element: (
                        <ProductCard
                            key={product.id}
                            handle={product.handle}
                            imageUrl={imageUrl}
                            title={product.title}
                            price={price}
                            originalPrice={originalPrice}
                            onClicked={() => {
                                purchaseContext.continue(PurchaseStep.NONE);
                                eventsService.setOpenProduct(product.handle);
                            }}
                            bannerType={bannerType}
                            imageWidth={gridSizes.columnSize}
                            allowDirectBasketAdd={true}
                            onIconClicked={() => handleOnDirectBasketAddClicked(product)}
                            metadata={product.metadata}
                            tags={product.tags}
                        />
                    ),
                    flipId: product.id,
                    size: configService.defaultTileSize,
                };
            }),
        [history, priceService, upsellProducts],
    );

    return (
        <div className={'UpsellView'}>
            <PanelHeader
                className={'UpsellView__header'}
                title={t('upsell.last_minute')}
                onCloseButtonClicked={() => onClose()}
                bottomBorder
                translate
                // leftAlignTitle
            />
            <div id="UpsellView__ContentWrapper" className={'UpsellView__ContentWrapper'}>
                <div className={'UpsellView__ContentWrapper_Inner'} ref={gridParentDivRef}>
                    <ResponsiveGrid
                        additionalClassName="UpsellView__GridItem"
                        gridSizes={gridSizes}
                        items={items}
                        hasMoreItems={hasMore}
                        onMoreItemsRequested={loadMoreProducts}
                        shouldUseProductAnimations={false}
                        // temp remove all animations to fix speed issues
                        // shouldUseProductAnimations={configService.shouldUseProductAnimations}
                        scrollableTarget="UpsellView__ContentWrapper"
                        setRowHeight
                    />
                </div>
            </div>
            <VariantSelectorModal
                onClose={() => {
                    setVariantSelectorLocalProduct(undefined);
                    setVariantSelectorCurrentVariant(undefined);
                    // setVariantSelectorBasketItem(undefined);
                }}
                forProduct={variantSelectorLocalProduct}
                currentVariant={variantSelectorCurrentVariant}
                basketItem={undefined}
                addToBasket={handleAddToBasket}
            />
        </div>
    );
};
