import { useCallback, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { ProductsPage } from './ProductsPage';
import { FilterType } from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import {
    CATEGORY_FILTER,
    CATEGORY_FILTER_ID,
    NAME_FILTER_ID,
} from '../../../../../../provider/cloudshelf/filter/CloudshelfFilters';
import {
    FilterSelection,
    ProductFilteringService,
    ProductsFetchOptions,
} from '../../../../../../services/ProductServices/ProductFilteringService';
import { EventsService } from '../../../../../../services/EventsService/EventsService';
import { CategoryProductsRouteParams, Routes } from '../../../../../../services/RoutesService/Routes';
import { RoutesHelperService } from '../../../../../../services/RoutesService/RoutesHelperService';
import { CategoryService } from '../../../../../../services/CategoryService/CategoryService';
import { BackButtonMode, MenuService, MenuStyle } from '../../../../../../services/MenuService/MenuService';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';
import {
    FilterableProduct,
    FilterableProductWithCursor,
    SearchResult,
} from '../../../../../../services/ProductServices/FilterableProductTypes';

export const CategoryProductsPage: FunctionalComponentWithChildren = () => {
    const history = useHistory();
    const routeMatch = useRouteMatch(Routes.CATEGORY_PRODUCTS);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const categoryService = useInjection<CategoryService>(DependencyType.CategoryService);
    const productFilteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const { category: categoryHandle } = useParams<CategoryProductsRouteParams>();
    const [category] = useState(categoryService.getByHandle(categoryHandle));

    useEffect(() => {
        if (category) {
            eventsService.setOpenCategory(category);
            productFilteringService.toggleSingleValue(
                CATEGORY_FILTER_ID,
                CATEGORY_FILTER_ID,
                CATEGORY_FILTER,
                FilterType.CategoryHandle,
                categoryHandle,
            );

            if (!category.isInternalAllCategory) {
                productFilteringService.clearSingleFilter(NAME_FILTER_ID);
            }
            productFilteringService.commitSelection();
        }
    }, [category]);

    const handleFetchProducts = async (
        filters: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
    ): Promise<SearchResult<FilterableProductWithCursor>> => {
        return productFilteringService.matchingProducts(
            'CategoryProductPage -> handleFetchProducts',
            category,
            filters,
            options,
            setMeaningful,
        );
    };

    useEffect(() => {
        if (routeMatch?.isExact) {
            menuService.setMenuStyle(MenuStyle.BAR_AND_FILTER_BUTTON);
            menuService.setBackButtonMode(BackButtonMode.BACK);
        }

        const subscriber = menuService.observeBackTapped().subscribe(() => {
            if (routeMatch?.isExact) {
                history.push(RoutesHelperService.toCategories());
            }
        });
        return () => {
            subscriber?.unsubscribe();
        };
    }, [menuService, history, routeMatch]);

    const generateProductDetailsUrl = useCallback(
        (product: FilterableProduct) => {
            return RoutesHelperService.toCategoryProductDetails(product, category);
        },
        [category],
    );

    return (
        <ProductsPage
            category={category}
            handleFetchProducts={handleFetchProducts}
            productDetailsRoute={Routes.CATEGORY_PRODUCT}
            productDetailsUrl={generateProductDetailsUrl}
            productListingRoute={RoutesHelperService.toCategoryProducts(category)}
            loading={false}
        />
    );
};
