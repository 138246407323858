import { inject, injectable } from 'inversify';
import { Observable } from 'react-use/lib/useObservable';
import { BehaviorSubject } from 'rxjs';
import DependencyType from '../../dependancyInjection/DependencyType';
import { Basket, BasketSessionInfo } from './Basket.type';
import _ from 'lodash';
import { LogUtil } from '../../utils/Logging.Util';
import CurrencyService from '../CurrencyService/CurrencyService';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import {
    AcquisitionOptionAvailability,
    KeyValuePair,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { CheckoutService } from '../CheckoutService/CheckoutService';
import { dependenciesContainer } from '../../dependancyInjection/DependenciesInitializer';
import { CheckoutFlowAcquisitionOption } from '../ConfigurationService/types/config/CloudshelfEngineConfig';
import { CloudshelfBridge } from '../../utils/CloudshelfBridge.Utils';
import { FilterableProduct, FilterableProductVariant } from '../ProductServices/FilterableProductTypes';

@injectable()
export class BasketService {
    private _hasEverAddedToBasket = false;
    private readonly basketSubject = new BehaviorSubject<Basket | undefined>(undefined);
    private _basket: Basket = {
        lineItems: [],
    };

    constructor(@inject(DependencyType.ConfigurationService) private readonly configService: ConfigurationService) {}

    private static filterOptionsMap(options: KeyValuePair[]): KeyValuePair[] {
        //filter out any empty values
        return options.filter(option => option.key && option.value && option.value.length > 0);
    }

    public static attributesMatchArr(a: KeyValuePair[], b: { key?: string | null; value?: string | null }[]) {
        // We have to filter out "empty" values. For now I am not trimming - as there is a chance that some retailers
        // want untrimmed customisations
        const filteredA = this.filterOptionsMap(a);

        // Check length of arrays is same
        if (filteredA.length !== b.length) {
            return false;
        }

        // Check each attribute individually
        for (const attrA of filteredA) {
            const attrB = b.find(b => b.key === attrA.key && b.value === attrA.value);
            if (!attrB) {
                return false;
            }
        }
        return true;
    }

    public static attributesMatch(a: KeyValuePair[], b: KeyValuePair[]) {
        // Filtering as per above
        const filteredA = this.filterOptionsMap(a);
        const filteredB = this.filterOptionsMap(b);

        // Check lengths
        if (filteredA.length !== filteredB.length) {
            return false;
        }

        for (const attrA of filteredA) {
            const attrB = filteredB.find(b => b.key === attrA.key && b.value === attrA.value);
            if (!attrB) {
                return false;
            }
            const matches = attrB.value === attrA.value;
            if (!matches) {
                return false;
            }
        }

        return true;
    }

    async getLineItems(): Promise<Basket['lineItems']> {
        return this._basket.lineItems;
    }

    async getItemQuantity(variantId: string, customAttributes: KeyValuePair[]): Promise<number> {
        const item = this._basket.lineItems.find(
            item => item.variant.id === variantId && BasketService.attributesMatch(customAttributes, item.attributes),
        );

        return item ? item.quantity : 0;
    }

    async forceUpdateQuantityOrRemoveWithoutPropagate(
        basket: Basket,
        variantId: string,
        customOptions: KeyValuePair[],
        quantity: number,
    ): Promise<Basket> {
        const existingLineItem = basket.lineItems.find(
            item =>
                (item.variant.id === variantId || item.variant.eCommercePlatformProvidedId === variantId) &&
                BasketService.attributesMatch(customOptions, item.attributes),
        );

        if (quantity === 0) {
            this._basket.lineItems = basket.lineItems.filter(
                item =>
                    item.variant.id !== variantId ||
                    item.variant.eCommercePlatformProvidedId !== variantId ||
                    !BasketService.attributesMatch(customOptions, item.attributes),
            );
        } else {
            if (existingLineItem) {
                existingLineItem.quantity = quantity;
            }
        }

        return basket;
    }

    async forcePropagateChanges(basket: Basket): Promise<void> {
        this._basket = basket;
        this.basketSubject.next(this._basket);
    }

    async setItemQuantity(
        product: FilterableProduct,
        variant: FilterableProductVariant,
        quantity: number,
        customOptions: KeyValuePair[],
        title?: string,
        maxQuantity?: number,
    ): Promise<void> {
        const existingLineItem = this._basket.lineItems.find(
            item => item.variant.id === variant.id && BasketService.attributesMatch(customOptions, item.attributes),
        );
        if (existingLineItem) {
            existingLineItem.quantity = quantity;
        } else {
            this._basket.lineItems.push({
                product: product,
                variant: variant,
                quantity,
                attributes: customOptions,
                maxQuantity: maxQuantity ?? Number.MAX_VALUE,
                title: title || variant.title,
            });
        }

        this._hasEverAddedToBasket = true;
        LogUtil.LogObject(this._basket);
        await this.propagateChanges();
    }

    async removeItem(variant: FilterableProductVariant, customAttributes: KeyValuePair[]): Promise<void> {
        // Remove from our local basket info
        const newBasket = _.cloneDeep(this._basket);
        newBasket.lineItems = newBasket.lineItems.filter(
            item => item.variant.id !== variant.id || !BasketService.attributesMatch(customAttributes, item.attributes),
        );
        await this.propagateChanges(newBasket);
    }

    get basket(): Basket | undefined {
        return this._basket;
    }

    empty(): void {
        this._basket = {
            lineItems: [],
        };
        this.basketSubject.next(this.basket);
    }

    totalQuantity(): number {
        const productCustomiserPriceModifierVariant = this.configService.productCustomiserPriceModifierVariant;

        return _.sumBy(this._basket.lineItems, lineItem => {
            if (
                productCustomiserPriceModifierVariant &&
                lineItem.variant.id === productCustomiserPriceModifierVariant.id
            ) {
                return 0;
            } else {
                return lineItem.quantity;
            }
        });
    }

    sessionInfo(): BasketSessionInfo {
        return {
            currencyCode: CurrencyService.currencyCode,
            addedToBasket: this._hasEverAddedToBasket,
        };
    }

    observeBasket(): Observable<Basket | undefined> {
        return this.basketSubject.asObservable();
    }

    async propagateChanges(inputBasket?: Basket) {
        if (inputBasket) {
            this._basket = inputBasket;
        }

        this._basket = _.cloneDeep(this._basket);
        const checkoutService = dependenciesContainer.get<CheckoutService>(DependencyType.CheckoutService);
        await checkoutService.handleBasketChange(this._basket);

        this.basketSubject.next(this._basket);
    }

    getAvailableCheckoutAcquisitionOptions(): CheckoutFlowAcquisitionOption[] {
        const returnableOptions: CheckoutFlowAcquisitionOption[] = [];
        const currentBasket = this._basket;
        const allOptions = this.configService.checkoutFlow();

        allOptions.acquisitionOptions.forEach(option => {
            // BEfore we check availability and if the option is usable for the products in the basket,
            // we need to check if the acquisitionType is compatiable with the user's payment method

            let hasAtLeastOnePaymentMethod = false;
            const acquisitionType = option.acquisitionType;

            if (allOptions.paymentViaQRCodeAvailableForAcquisitionTypes.includes(acquisitionType)) {
                if (allOptions.allowPaymentsViaQRCode) {
                    hasAtLeastOnePaymentMethod = true;
                }
            }

            if (allOptions.paymentViaCardsAvailableForAcquisitionTypes.includes(acquisitionType)) {
                if (allOptions.allowPaymentsViaCards) {
                    if (
                        allOptions.paymentViaCardsProviderSecret ||
                        (CloudshelfBridge.isAvailable() && CloudshelfBridge.canUseOnDevicePayments)
                    ) {
                        hasAtLeastOnePaymentMethod = true;
                    }
                }
            }

            if (!hasAtLeastOnePaymentMethod) {
                return;
            }

            switch (option.availability) {
                case AcquisitionOptionAvailability.AllProducts:
                    //Easy, this option is always avaiable so we can add it to the list
                    returnableOptions.push(option);
                    break;
                case AcquisitionOptionAvailability.TaggedProducts:
                    console.log('option for tagged products', option, currentBasket.lineItems);
                    // If all items in the basket have the tag, then we can add this option to the list
                    if (
                        option.availabilityTag &&
                        currentBasket.lineItems.every(item =>
                            item.product.tags.some(
                                tag => tag.toLowerCase() === (option.availabilityTag ?? '').toLowerCase(),
                            ),
                        )
                    ) {
                        returnableOptions.push(option);
                    }
                    break;
                //TODO: the api doesnt support metadata yet... oops, think we can get away with this fore decathlon
                // case 'metadata_products':
                //     // If all items in the basket have the metadata key and value, then we can add this option to the list
                //     if (
                //         option.metadataKey &&
                //         option.metadataValue &&
                //         currentBasket.lineItems.every(item =>
                //             item.product.metadata.some(
                //                 attr => attr.key === option.metadataKey && attr.data === option.metadataValue,
                //             ),
                //         )
                //     ) {
                //         returnableOptions.push(option);
                //     }
                //     break;
                // TODO: We will need to handle stock, but we don't have live stock counts yet
                // case 'in_stock_products':
                //     // If all items in the basket are in stock, then we can add this option to the list
                //     if (currentBasket.lineItems.every(item => item.product.inStock)) {
                //         returnableOptions.push(option);
                //     }
                //     break;
            }
        });

        return returnableOptions;
    }
}
