import { injectable } from 'inversify';
import CurrencyService from '../CurrencyService/CurrencyService';
import { FilterableProduct, FilterableProductVariant } from '../ProductServices/FilterableProductTypes';

@injectable()
export default class PriceService {
    convertLowestDenominationToFull(price: number): string {
        return CurrencyService.format(price / 100);
    }

    getPriceFromVariant(variant: FilterableProductVariant, originalPrice = false, quantity = 1): string {
        if (originalPrice) {
            return CurrencyService.format(variant.originalPrice * quantity);
        } else {
            return CurrencyService.format(variant.price * quantity);
        }
    }

    getPriceRangeFromProduct(product: FilterableProduct, originalPrice = false): string {
        let minPrice = Number.MAX_VALUE;
        let maxPrice = Number.MIN_VALUE;

        for (const variant of product.variants) {
            if (originalPrice) {
                minPrice = Math.min(minPrice, variant.originalPrice);
                maxPrice = Math.max(maxPrice, variant.originalPrice);
            } else {
                minPrice = Math.min(minPrice, variant.price);
                maxPrice = Math.max(maxPrice, variant.price);
            }
        }

        if (minPrice === maxPrice) {
            return CurrencyService.format(minPrice);
        } else {
            return `${CurrencyService.format(minPrice)} - ${CurrencyService.format(maxPrice)}`;
        }
    }

    getFromPriceFromProduct(product: FilterableProduct, originalPrice = false): string {
        const prices: number[] = [];
        let minPrice = Number.MAX_VALUE;

        for (const variant of product.variants) {
            if (originalPrice) {
                minPrice = Math.min(minPrice, variant.originalPrice);
                if (!prices.includes(variant.originalPrice)) {
                    prices.push(variant.originalPrice);
                }
            } else {
                minPrice = Math.min(minPrice, variant.price);
                if (!prices.includes(variant.price)) {
                    prices.push(variant.price);
                }
            }
        }

        if (prices.length > 1) {
            return `From ${CurrencyService.format(minPrice)}`;
        } else {
            return CurrencyService.format(minPrice);
        }
    }
}
