import React, { CSSProperties } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { Skeleton } from '../../../../shared/Skeleton/Skeleton';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import NoImagePicture from '../../../../icons/no_image';
import { ImageAnchor } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export type OnClicked = () => void;

export interface MoreProductCardProps {
    imageUrl?: string;
    onClicked: OnClicked;
    imageWidth: number;
    overlayText: string;
}

const MoreProductCard: FunctionalComponentWithChildren<MoreProductCardProps> = React.memo(
    ({ imageUrl, onClicked, imageWidth, overlayText }) => {
        const [firstImageLoaded, setFirstImageLoaded] = React.useState<boolean>(false);
        const [firstImageError, setFirstImageError] = React.useState<boolean>(false);
        const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

        const imageContainerStyle: CSSProperties = {
            width: '100%',
            height: '100%',
            minWidth: imageWidth !== 0 ? `${imageWidth}px` : undefined,
            minHeight: imageWidth !== 0 ? `calc(${imageWidth}px - var(--responsive-reference-point)* 0.5)` : undefined,
        };

        const style: CSSProperties = {
            width: '100%',
            height: imageContainerStyle.height,
            minWidth: imageWidth !== 0 ? `${imageWidth}px` : '100%',
            minHeight: imageWidth !== 0 ? `calc(${imageWidth}px - var(--responsive-reference-point)* 0.5)` : '100%',
        };

        let objectPos: string | undefined = undefined;

        if (configService.imageAnchor === ImageAnchor.Top) {
            objectPos = 'top';
        } else if (configService.imageAnchor === ImageAnchor.Bottom) {
            objectPos = 'bottom';
        } else if (configService.imageAnchor === ImageAnchor.Left) {
            objectPos = 'left';
        } else if (configService.imageAnchor === ImageAnchor.Right) {
            objectPos = 'right';
        } else if (configService.imageAnchor === ImageAnchor.Center) {
            objectPos = 'center';
        }
        return (
            <>
                <div
                    className={`ProductCard MoreProductCart`}
                    onClick={onClicked}
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex={0}
                >
                    <div className="ProductCard__imageContainer" style={imageContainerStyle}>
                        {imageUrl !== undefined && !firstImageError ? (
                            <>
                                <div className="ProductCard__image" style={style}>
                                    {!firstImageLoaded && <Skeleton type={'rectangle'} />}
                                    <img
                                        alt=""
                                        src={imageUrl}
                                        style={{
                                            display: firstImageLoaded ? 'inherit' : 'none',
                                            width: '100%',
                                            height: '100%',
                                            objectFit:
                                                configService.imageAnchor === ImageAnchor.None ? 'contain' : 'cover',
                                            objectPosition: objectPos,
                                        }}
                                        onLoad={() => {
                                            setFirstImageLoaded(true);
                                            setFirstImageError(false);
                                        }}
                                        onError={() => {
                                            setFirstImageLoaded(true);
                                            setFirstImageError(true);
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="ProductCard__image" style={style}>
                                {(firstImageError || imageUrl === undefined) && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '10%',
                                        }}
                                    >
                                        <NoImagePicture />
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="ProductCard__moreOverlay">{overlayText}</div>
                    </div>
                </div>
            </>
        );
    },
);

export default MoreProductCard;
