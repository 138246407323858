import {
    CapitalisationStyle,
    FilterOrderType,
    FilterType,
} from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import {
    CloudshelfEngineFilter,
    EngineAttributeValue,
} from '../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { FilterValueType } from '../services/ConfigurationService/types/filters/FilterValueType';
import { FilterableProduct } from '../services/ProductServices/FilterableProductTypes';
import i18n from 'i18next';

export function getUniqAttributeItems(thisFilter: CloudshelfEngineFilter): EngineAttributeValue[] {
    return _.uniqBy(thisFilter.attributeValues, av => av.value);
}

export function getPriceRange(values: string[], places: number): string {
    if (values.length < 2) {
        if (values.length === 1) {
            return `£${parseFloat(values[0]).toFixed(places)}`;
        } else {
            return 'Unknown Price';
        }
    } else {
        return `£${parseFloat(values[0]).toFixed(places)}-£${parseFloat(values[1]).toFixed(places)}`;
    }
}

export function buildStockLevelFilter(
    includeInStock: boolean,
    includeProductsLimitedAvailability: boolean,
    includeOutOfStock: boolean,
    priority: number,
    name?: string,
): CloudshelfEngineFilter {
    const attributeValues: EngineAttributeValue[] = [];

    if (includeInStock) {
        attributeValues.push({
            priority: 0,
            value: 'In Stock',
            parentFilterId: null,
        });
    }

    if (includeProductsLimitedAvailability) {
        attributeValues.push({
            priority: 1,
            value: 'Order Only',
            parentFilterId: null,
        });
    }

    if (includeOutOfStock) {
        attributeValues.push({
            priority: 2,
            value: 'Out of Stock',
            parentFilterId: null,
        });
    }

    return {
        attributeValues: attributeValues,
        displayName: name === undefined ? i18n.t('filters.overrides.stock_level') : name,
        hiddenAttributeValues: [],
        ecommProviderFieldName: 'Stock',
        expandedByDefault: true,
        id: 'stock-level',
        type: FilterType.StockLevel,
        isHidden: false,
        isMergedChild: false,
        parentId: undefined,
        valueType: FilterValueType.DISCRETE,
        options: {
            __typename: 'FilterOptions',
            swatches: null,
            baseShoesizeUnit: null,
            capitalisationStyle: CapitalisationStyle.Original,
            orderType: FilterOrderType.Custom,
        },
        valueOverrides: [
            {
                displayValue: i18n.t('filters.overrides.in_stock'),
                originalValue: 'relevance',
                parentFilterId: null,
            },
            {
                displayValue: i18n.t('filters.overrides.order_only'),
                originalValue: 'price-desc',
                parentFilterId: null,
            },
            {
                displayValue: i18n.t('filters.overrides.out_of_stock'),
                originalValue: 'price-asc',
                parentFilterId: null,
            },
        ],
        priority: priority,
    };
}
export function buildOrderByFilter(): CloudshelfEngineFilter {
    return {
        attributeValues: [
            {
                priority: 0,
                value: 'relevance',
                parentFilterId: null,
            },
            {
                priority: 1,
                value: 'price-desc',
                parentFilterId: null,
            },
            {
                priority: 1,
                value: 'price-asc',
                parentFilterId: null,
            },
        ],
        displayName: i18n.t('filters.overrides.sort_by'),
        hiddenAttributeValues: [],
        ecommProviderFieldName: 'Sort By',
        expandedByDefault: true,
        id: 'sort-by',
        type: FilterType.SortOrder,
        isHidden: false,
        isMergedChild: false,
        parentId: undefined,
        valueType: FilterValueType.DISCRETE,
        options: {
            __typename: 'FilterOptions',
            swatches: null,
            baseShoesizeUnit: null,
            capitalisationStyle: CapitalisationStyle.Original,
            orderType: FilterOrderType.Custom,
        },
        valueOverrides: [
            {
                displayValue: i18n.t('filters.overrides.relevance'),
                originalValue: 'relevance',
                parentFilterId: null,
            },
            {
                displayValue: i18n.t('filters.overrides.price_high_low'),
                originalValue: 'price-desc',
                parentFilterId: null,
            },
            {
                displayValue: i18n.t('filters.overrides.price_low_high'),
                originalValue: 'price-asc',
                parentFilterId: null,
            },
        ],
        priority: -10,
    };
}

export function buildBuiltInSortOrder_Relevance(categoryHandle?: string): [keyof FilterableProduct, boolean][] {
    if (categoryHandle === undefined || categoryHandle === 'INTERNAL_ALL') {
        // console.log('using original relevance');
        return [['remoteUpdatedAt', true]];
    } else {
        // console.log('using shopify order for handle', `categoryOrderByHandles.${categoryHandle}`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return [[`categoryOrderByHandles.${categoryHandle}`, false]];
    }
}

export function buildBuiltInSortOrder_RelevanceAllProducts(
    obj1: FilterableProduct,
    obj2: FilterableProduct,
    ascending: boolean,
) {
    const obj1AnyAvailableForSale = obj1.variants.some(v => v.availableForSale);
    const obj2AnyAvailableForSale = obj2.variants.some(v => v.availableForSale);

    if (ascending) {
        if (obj1AnyAvailableForSale && !obj2AnyAvailableForSale) {
            return -1;
        } else if (!obj1AnyAvailableForSale && obj2AnyAvailableForSale) {
            return 1;
        } else {
            return new Date(obj1.remoteUpdatedAt).getTime() - new Date(obj2.remoteUpdatedAt).getTime();
        }
    } else {
        if (obj1AnyAvailableForSale && !obj2AnyAvailableForSale) {
            return 1;
        } else if (!obj1AnyAvailableForSale && obj2AnyAvailableForSale) {
            return -1;
        } else {
            return new Date(obj2.remoteUpdatedAt).getTime() - new Date(obj1.remoteUpdatedAt).getTime();
        }
    }
}

export function buildBuiltInSortOrder_PriceAsc(obj1: FilterableProduct, obj2: FilterableProduct) {
    const obj1Price = obj1.variants.reduce((min, v) => (v.price < min ? v.price : min), obj1.variants[0].price);
    const obj2Price = obj2.variants.reduce((min, v) => (v.price < min ? v.price : min), obj2.variants[0].price);

    return obj1Price - obj2Price;
}

export function buildBuiltInSortOrder_PriceDesc(obj1: FilterableProduct, obj2: FilterableProduct) {
    const obj1Price = obj1.variants.reduce((min, v) => (v.price < min ? v.price : min), obj1.variants[0].price);
    const obj2Price = obj2.variants.reduce((min, v) => (v.price < min ? v.price : min), obj2.variants[0].price);

    return obj2Price - obj1Price;
}

export function buildBuiltInSortOrder_Random(obj1: FilterableProduct, obj2: FilterableProduct) {
    return Math.random() > 0.5 ? 1 : -1;
}

// export function buildBuiltInSortOrder_RelevanceAllProducts(): [keyof FilterableProduct, boolean][] {
//     return [
//         ['variants.availableForSale', true],
//         ['remoteUpdatedAt', true],
//     ];
// }

// export function buildBuiltInSortOrder_PriceAsc(): [NestedKeyOf<FilterableProduct>, boolean][] {
//     return [['variants.price', false]];
// }

// export function buildBuiltInSortOrder_PriceDesc(): [NestedKeyOf<FilterableProduct>, boolean][] {
//     return [['variants.price', true]];
// }

// export function buildBuiltInSortOrder_MostRecent(): [NestedKeyOf<FilterableProduct>, boolean][] {
//     return [['remoteUpdatedAt', true]];
// }
