import React, { CSSProperties, FC, memo, useEffect, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { BackButtonMode, MenuService, MenuStyle } from '../../../../../services/MenuService/MenuService';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { ProductFilteringService } from '../../../../../services/ProductServices/ProductFilteringService';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import { PurchaseStep, usePurchase } from '../../../../../hooks/contexts/Purchase/Purchase';
import { NameFilterInput } from '../../../../../services/ProductServices/filters/inputs/NameFilterInput';
import { getRefRule } from '../../../../../utils/Responsive.Util';
import CloseWhiteIcon from '../../../../icons/close_white';
import FilterIcon from '../../../../icons/filter';
import ShoppingCartIcon from '../../../../icons/shopping_cart';
import {
    CheckoutExperience,
    CloudshelfPayloadStatus,
    EngineType,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import TextTransition from 'react-text-transition';
import { useLocation } from 'react-router';
import ArrowBackIcon from '../../../../icons/arrow_back';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import SearchIcon from '../../../../icons/search';

export const Menu: FunctionalComponentWithChildren = memo(() => {
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);

    const purchaseContext = usePurchase();
    const location = useLocation();

    const [menuStyle, setMenuStyle] = useState(MenuStyle.BUTTON_EXPANDABLE_SEARCH);
    const [backButtonMode, setBackButtonMode] = useState(BackButtonMode.NONE);
    const [searchVisible, setSearchVisible] = useState(true);
    const [filterButtonVisible, setFilterButtonVisible] = useState(true);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [searchBarExtended, setSearchBarExtended] = useState(false);

    const isHandoff = configService.status() === CloudshelfPayloadStatus.MobileHandoff;

    let shouldUseClassicBasketMenu = configService.config()?.checkoutExperience === CheckoutExperience.Classic;

    if (configService.config()?.checkoutExperience === CheckoutExperience.Basket) {
        if (location.pathname.endsWith('/categories')) {
            shouldUseClassicBasketMenu = true;
        }
    }

    const contentStyle: CSSProperties = {
        justifyContent: 'space-between',
    };

    if (menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH) {
        contentStyle.justifyContent = 'flex-end';
    }
    const rightStyle: CSSProperties = {};

    if (menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH) {
        rightStyle.width = `${searchBarExtended ? '100%' : totalQuantity > 0 ? '45%' : '30%'}`;
        rightStyle.transition = '0.5s width ease';
        rightStyle.marginLeft = '0';
    }

    useEffect(() => {
        const filterVisibilitySubscriber = menuService.observeFilterVisibilityChange().subscribe(shouldBeVisible => {
            setFilterButtonVisible(shouldBeVisible);
        });
        const searchVisibilitySubscriber = menuService.observeSearchVisibilityChange().subscribe(shouldBeVisible => {
            setSearchVisible(shouldBeVisible);
        });

        const backButtonModeSubscriber = menuService.observeBackButtonModeChange().subscribe(mode => {
            setBackButtonMode(mode);
        });
        const menuStyleSubscriber = menuService.observeMenuStyleChange().subscribe(style => {
            setMenuStyle(style);
        });
        const expandedSearchSubscriber = menuService.observeExpandedSearchChange().subscribe(expanded => {
            setSearchBarExtended(expanded);
        });
        return () => {
            searchVisibilitySubscriber.unsubscribe();
            filterVisibilitySubscriber.unsubscribe();
            backButtonModeSubscriber.unsubscribe();
            menuStyleSubscriber.unsubscribe();
            expandedSearchSubscriber.unsubscribe();
        };
    }, [menuService]);

    useEffect(() => {
        const subscriber = basketService.observeBasket().subscribe(() => {
            setTotalQuantity(basketService.totalQuantity());
        });
        return () => {
            subscriber.unsubscribe();
        };
    }, [basketService]);

    const onBackButtonClicked = () => {
        if (backButtonMode === BackButtonMode.BACK) {
            eventsService.setOpenCategory(undefined);
            filteringService.clearSelection(true);
        }
        menuService.fireBackTapped();
    };

    const onFilterButtonClicked = () => {
        menuService.fireFilterTapped();
    };

    const onSearchButtonClicked = () => {
        menuService.setExpandedSearchOpen(true);
    };

    if (configService.deviceMode === EngineType.DisplayOnly || isHandoff) {
        return null;
    }

    return (
        <nav className="Menu">
            <div className="Menu__content" style={contentStyle}>
                {menuStyle === MenuStyle.BAR_AND_FILTER_BUTTON && (
                    <>
                        <div className="Menu__content__left">
                            {backButtonMode !== BackButtonMode.BACK_ELSEWHERE &&
                                backButtonMode !== BackButtonMode.NONE && (
                                    <button className="Menu__roundButton" onClick={onBackButtonClicked}>
                                        <ArrowBackIcon />
                                        {/* {backButtonMode === BackButtonMode.CLOSE ? (
                                            <CloseWhiteIcon />
                                        ) : (
                                            <ArrowBackIcon />
                                        )} */}
                                    </button>
                                )}
                        </div>

                        <div className="Menu__content__center Menu__fixed">
                            {searchVisible && (
                                <NameFilterInput
                                    initialValue={filteringService.searchValue}
                                    onChange={() => {}}
                                    isOnScreen={true}
                                    short={false}
                                    commitFilters
                                />
                            )}
                        </div>
                    </>
                )}
                <div className="Menu__content__right" style={rightStyle}>
                    {filterButtonVisible && menuStyle === MenuStyle.BAR_AND_FILTER_BUTTON && (
                        <button
                            className="Menu__roundButton Menu__roundButton__retailerStyled"
                            onClick={onFilterButtonClicked}
                        >
                            <FilterIcon />
                        </button>
                    )}
                    {filterButtonVisible && searchVisible && menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH && (
                        <div className="Menu__fixed" onClick={onSearchButtonClicked}>
                            {!searchBarExtended && (
                                <button
                                    className="Menu__roundButton AttractLoop__Top__HomeButton AttractLoopScreen__NewButtonStyle SearchIcon"
                                    onClick={() => setSearchBarExtended(true)}
                                >
                                    <SearchIcon />
                                </button>
                            )}
                            {searchBarExtended && (
                                <NameFilterInput
                                    initialValue={filteringService.searchTerm ?? ''}
                                    onChange={() => {}}
                                    isOnScreen={true}
                                    short={false}
                                    focus={searchBarExtended}
                                />
                            )}
                        </div>
                    )}
                    {totalQuantity > 0 && shouldUseClassicBasketMenu && (
                        <button
                            className="Menu__roundButton Menu__roundButton__smallerIcon Menu__roundButton__retailerStyled Menu__checkout SVGFillContrast"
                            onClick={() => purchaseContext.continue(PurchaseStep.BASKET)}
                        >
                            <ShoppingCartIcon />
                            <TextTransition>{totalQuantity}</TextTransition>
                        </button>
                    )}
                </div>
            </div>
        </nav>
    );
});

export function getMenuHeight(): number {
    const ruleSizes = getRefRule();
    let menuHeight = ruleSizes.minHeight;
    if (ruleSizes.smallestDimension > menuHeight) {
        menuHeight = ruleSizes.smallestDimension;
    }

    return menuHeight;
}
