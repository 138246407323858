import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useStateRef from 'react-usestateref';
import { useHistory } from 'react-router-dom';
import ProductTitle from './buildingBlocks/ProductTitle';
import _ from 'lodash';
import ProductImages from './buildingBlocks/ProductImages';
import ProductCartOptions from './buildingBlocks/ProductCartOptions';
import * as Sentry from '@sentry/react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import ProductSKU from './buildingBlocks/ProductSKU';
import ProductVendor from './buildingBlocks/ProductVendor';
import PriceService from '../../../../../services/PriceService/PriceService';
import ProductCustomiserSection from './buildingBlocks/customisation/ProductCustomiserSection';
import {
    FilterSelection,
    ProductFilteringService,
} from '../../../../../services/ProductServices/ProductFilteringService';
import ProductVariants from './buildingBlocks/variants/ProductVariants';
import ProductAvailability from './buildingBlocks/ProductAvailability';
import { Skeleton } from '../../../../shared/Skeleton/Skeleton';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import ProductPrice from './buildingBlocks/ProductPrice';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { SlidingModal, SlidingModalOrigin } from '../SlidingModal/SlidingModal';
import { Routes } from '../../../../../services/RoutesService/Routes';
import { RoutesHelperService } from '../../../../../services/RoutesService/RoutesHelperService';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { SessionEvent, SessionEventType } from '../../../../../services/SessionManagementService/SessionEvent';
import { smoothScrollWrapper } from '../../../../../utils/ScrollIntoView.Util';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import {
    FilterType,
    KeyValuePair,
    PdpBlockStyle,
    PdpProductDataType,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import Accordion from '../../../../shared/Accordion/Accordion';
import { ConditionalWrapper } from '../../../../shared/ConditionalWrapper';
import ProductDescription from './buildingBlocks/ProductDescription';
import ProductMetafield from './buildingBlocks/ProductMetafield';
import { BackButtonMode, MenuService, MenuStyle } from '../../../../../services/MenuService/MenuService';
import { useRouteMatch } from 'react-router';
import ProductBookThatAppSection from './buildingBlocks/bta/ProductBookThatAppSection';
import {
    getVariantAvailability,
    ProductVariantAvailability,
} from '../../../../../services/ProductServices/ProductVariantAvailability';
import ProductBarcode from './buildingBlocks/ProductBarcode';
import { ProductLabels } from '../ProductLabels/ProductLabels';
import ProductImageCarousel from './buildingBlocks/ProductImagesCarousel';
import ShrinkIcon from '../../../../icons/shrinkIcon';
import ExpandIcon from '../../../../icons/expandIcon';
import ProductCard, { BannerType } from '../ProductCard/ProductCard';
import { getImageTileSize } from '../../../../../utils/Responsive.Util';
import { getSizedImageURL } from '../../../../../utils/ImageURL.Util';
import { generateGridProperties } from '../../../../shared/ResponsiveGrid/ResponsiveGrid';
import { getDeviceDPI } from '../../../../..';
import { StringUtils } from '../../../../../utils/String.Utils';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import {
    FilterableProduct,
    FilterableProductImage,
    FilterableProductVariant,
    FilterableProductWithCursor,
} from '../../../../../services/ProductServices/FilterableProductTypes';
import { getBannerType } from '../../../../../utils/BannerType.Util';
import { generateMatchingAllInputRegex } from '../../../../../utils/String.Util';

export interface ProductDisplayViewProps {
    blocks?: string[];
    handoffCustomisationFieldId?: number;
    hideCartOptions?: boolean;
}

const ProductDetailsView: FunctionalComponentWithChildren<ProductDisplayViewProps> = React.memo(props => {
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const routeMatch = useRouteMatch(Routes.CATEGORY_PRODUCT);
    const [isOpen, setIsOpen, isOpenRef] = useStateRef(false);
    const history = useHistory();
    const { t } = useTranslation();
    const [openProductHandle, setOpenProductHandle] = React.useState<string>();
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const filterService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const [productDetails, setProductDetails] = useState<FilterableProduct>();
    const [selectedVariant, setSelectedVariant] = useState<FilterableProductVariant>();
    const [loading, setLoading] = useState<boolean>(true);
    const [formValid, setFormValid] = useState<boolean>(true);
    const [btaFormValid, setBTAFormValid] = useState<boolean>(true);
    const [isLandscape, setIsLandscape] = useState<boolean>(false);
    const [forceShowWarnings, setForceShowWarnings] = useState<boolean>(false);
    const [, setCustomOptions, customOptions] = useStateRef<KeyValuePair[]>([]);
    const [, setBTAOptions, btaOptions] = useStateRef<KeyValuePair[]>([]);
    const [, setProductCustomiserPriceModifier, productCustomiserPriceModifier] = useStateRef<number>(0);
    const variantOptionsRef = useRef<HTMLElement>(null);
    const customiserRef = useRef<HTMLElement>(null);
    const btaRef = useRef<HTMLElement>(null);
    const imagesRef = useRef<HTMLElement>(null);
    const [imageIndex, setImageIndex] = useState(0);
    const [galleryExpanded, setGalleryExpanded] = useState<boolean>(false);
    const [maxPurchaseQuantity, setMaxPurchaseQuantity] = useState<number>(Number.MAX_VALUE);
    const [suggestedProducts, setSuggestedProducts] = useState<FilterableProductWithCursor[]>([]);
    const gridParentDivRef = useRef<HTMLDivElement>(null);
    const gridSizes = generateGridProperties(document.body.clientWidth, getDeviceDPI(), true);
    const [productImages, setProductImages] = useState<FilterableProductImage[]>([]);

    const overrideSuggestedImageSize = 0.8;

    const mergedCustomAttributes: KeyValuePair[] = [
        ...customOptions.current,
        ...btaOptions.current,
        ...configService.builtInCustomAttributes,
    ];

    useEffect(() => {
        const handleOrientationChange = () => {
            const isLandscape = window.innerWidth > window.innerHeight;
            // Perform any actions needed on orientation change
            console.log(`Orientation changed. Landscape: ${isLandscape}`);
            setIsLandscape(isLandscape);
        };

        window.addEventListener('resize', handleOrientationChange);

        handleOrientationChange();
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    const handleSetMaxPurchaseQuantity = (quantity: number) => {
        setMaxPurchaseQuantity(quantity);
    };

    const [configProvidedBuildingBlockOrder] = useState(
        _.orderBy(configService.config()?.pdpBlocks ?? [], b => b.position),
    );

    useEffect(() => {
        const sessionObserver = sessionManagementService.observe();

        const sessionObserverSubscription = sessionObserver.subscribe((event: SessionEvent) => {
            if (event.type === SessionEventType.Ended) {
                if (isOpenRef.current) {
                    setIsOpen(!isOpenRef.current);
                    setProductDetails(undefined);
                    setSelectedVariant(undefined);
                    setProductImages([]);
                    eventsService.setOpenProduct(undefined);
                    history.push(Routes.CATEGORIES);
                }
            }
        });

        return () => sessionObserverSubscription.unsubscribe();
    }, []);

    const metafields = React.useMemo(() => {
        if (openProductHandle) {
            return productDetails?.metadata ?? [];
        }
        return [];
    }, [openProductHandle]);

    const productTags = !productDetails ? [] : productDetails.tags ?? [];

    let originalPriceString: string | undefined;
    let priceString: string | undefined;
    if (selectedVariant) {
        // We have a selected variant so need to determine if it's on sale
        if (selectedVariant.hasSalePrice) {
            originalPriceString = priceService.getPriceFromVariant(selectedVariant, true);
        }
        priceString = priceService.getPriceFromVariant(selectedVariant);
    } else {
        // We don't have a selected variant, so need to show price ranges

        if (productDetails) {
            originalPriceString = priceService.getPriceRangeFromProduct(productDetails, true);
            priceString = priceService.getPriceRangeFromProduct(productDetails);
        }
    }

    const closeProductDetailsView = useCallback(
        (timeout = 1) => {
            //wait 1 second

            setTimeout(() => {
                if (!isOpenRef.current) {
                    return;
                }
                let shouldShowFilterMenu = true;

                const category = eventsService.openCategory;
                if (!category || category.isInternalAllCategory) {
                    if (configService.shouldUseOnlineSearch) {
                        if (filterService.onlineData) {
                            shouldShowFilterMenu = false;
                        }
                    }
                }

                if (routeMatch?.isExact) {
                    menuService.setBackButtonMode(BackButtonMode.CLOSE);
                    menuService.setFilterButtonVisible(shouldShowFilterMenu);
                    menuService.setSearchVisible(true);
                } else {
                    menuService.setMenuStyle(MenuStyle.BUTTON_EXPANDABLE_SEARCH);
                    menuService.setBackButtonMode(BackButtonMode.CLOSE);
                    menuService.setFilterButtonVisible(shouldShowFilterMenu);
                    menuService.setSearchVisible(true);
                }

                setIsOpen(!isOpenRef.current);
                setProductDetails(undefined);
                setSelectedVariant(undefined);
                setProductImages([]);
                eventsService.setOpenProduct(undefined);
                const openCategory = eventsService.openCategory;

                if (openCategory) {
                    history.push(RoutesHelperService.toCategoryProducts(openCategory));
                } else {
                    if (filterService.searchValue !== '') {
                        history.push(RoutesHelperService.toCategoryProducts(undefined));
                    } else {
                        history.push(Routes.CATEGORIES);
                    }
                }
            }, timeout);
        },
        [setIsOpen, menuService],
    );

    const handleSetVariant = (variant: FilterableProductVariant | undefined) => {
        setSelectedVariant(variant);
        setMaxPurchaseQuantity(Number.MAX_VALUE);
    };

    useEffect(() => {
        (async () => {
            console.log('openProductHandle', openProductHandle);
            if (openProductHandle) {
                try {
                    setIsOpen(true);
                    setLoading(true);

                    const localProductDetails = await filterService.getFilterableProductByHandle(
                        openProductHandle,
                        // configService.config()?.includeProductsOutOfStock ?? false,
                    );

                    if (!localProductDetails) {
                        // Product could not be found
                        closeProductDetailsView();
                        return;
                    }

                    setProductDetails(localProductDetails);
                    console.log('PDP Product', localProductDetails);
                    const images = _.cloneDeep(localProductDetails?.images ?? []);
                    const preferredImage = _.find(images, image => image.preferred);
                    if (preferredImage) {
                        _.remove(images, preferredImage);
                        images.unshift(preferredImage);
                    }

                    setProductImages(images);

                    const variantsProductVariants = localProductDetails.variants;

                    if (variantsProductVariants.length > 0) {
                        //loop over variantsProductVariants backwards and find the first available variant
                        for (let i = variantsProductVariants.length - 1; i >= 0; i--) {
                            const variant = variantsProductVariants[i];
                            if (getVariantAvailability(variant) !== ProductVariantAvailability.Unavailable) {
                                handleSetVariant(variant);
                                break;
                            }
                        }
                    }

                    const openProductGroup = eventsService.openCategory;

                    if (configService.config()?.pdpIncludeSuggestedItems) {
                        const suggestedProductFilters: FilterSelection[] = [];
                        if (localProductDetails.type.trim() !== '') {
                            console.log(
                                `Product has a type (${localProductDetails.type}), so we will use that as a filter for suggested products`,
                            );
                            suggestedProductFilters.push({
                                type: FilterType.ProductType,
                                values: [localProductDetails.type],
                                mergeDefinitionId: '',
                                definitionId: '',
                                name: 'Product Type',
                            });
                        } else if (openProductGroup && !openProductGroup.isInternalAllCategory) {
                            suggestedProductFilters.push(
                                {
                                    type: FilterType.CategoryId,
                                    values: [openProductGroup.id],
                                    mergeDefinitionId: '',
                                    definitionId: '',
                                    name: 'Collection Id',
                                },
                                {
                                    type: FilterType.CategoryHandle,
                                    values: [openProductGroup.handle],
                                    mergeDefinitionId: '',
                                    definitionId: '',
                                    name: 'Collection Handle',
                                },
                            );
                        } else if (localProductDetails.vendor.trim() !== '') {
                            console.log(
                                `Product has a vendor (${localProductDetails.vendor}), so we will use that as a filter for suggested products`,
                            );
                            suggestedProductFilters.push({
                                type: FilterType.Vendor,
                                values: [localProductDetails.vendor],
                                mergeDefinitionId: '',
                                definitionId: '',
                                name: 'Vendor',
                            });
                        } else if (localProductDetails.tags.length > 0) {
                            console.log(
                                `Product has tags (${localProductDetails.tags}), so we will use that as a filter for suggested products`,
                            );
                            suggestedProductFilters.push({
                                type: FilterType.Tag,
                                values: localProductDetails.tags,
                                mergeDefinitionId: '',
                                definitionId: '',
                                name: 'Product Tags',
                            });
                        }

                        if (suggestedProductFilters.length > 0) {
                            const suggestedProducts = await filterService.matchingProducts(
                                'Suggested Products',
                                undefined,
                                suggestedProductFilters,
                                { limit: 8 },
                                false,
                                ['product-customizer-item-customizations', localProductDetails.handle],
                            );

                            setSuggestedProducts(suggestedProducts.items);
                        } else {
                            setSuggestedProducts([]);
                        }
                    } else {
                        setSuggestedProducts([]);
                    }

                    setLoading(false);
                } catch (e) {
                    Sentry.captureException(e, {
                        extra: {
                            operationName: 'PDP useeffect',
                        },
                    });
                    closeProductDetailsView();
                }
            }
        })();
    }, [openProductHandle]);

    useEffect(() => {
        const subscriber = menuService.observeBackTapped().subscribe(() => {
            closeProductDetailsView();
        });
        return () => {
            subscriber?.unsubscribe();
        };
    }, [menuService, closeProductDetailsView]);

    useEffect(() => {
        if (isOpen) {
            if (routeMatch?.isExact) {
                menuService.setBackButtonMode(BackButtonMode.CLOSE);
                menuService.setFilterButtonVisible(false);
                menuService.setSearchVisible(false);
            } else {
                menuService.setMenuStyle(MenuStyle.BAR_AND_FILTER_BUTTON);
                menuService.setBackButtonMode(BackButtonMode.CLOSE);
                menuService.setFilterButtonVisible(false);
                menuService.setSearchVisible(false);
            }
        }
    }, [menuService, isOpen, routeMatch]);

    useEffect(() => {
        const openProductObservable = eventsService.observeOpenProduct();
        const subscriber = openProductObservable.subscribe(handle => {
            console.log('openProductObservable', handle);
            setForceShowWarnings(false);
            setOpenProductHandle(handle);
            setImageIndex(0);
            setGalleryExpanded(false);
            setMaxPurchaseQuantity(Number.MAX_VALUE);

            if (handle === undefined) {
                closeProductDetailsView();
            }
        });

        const currentHandle = eventsService.openProduct;
        if (currentHandle) {
            setForceShowWarnings(false);
            setOpenProductHandle(currentHandle);
            setImageIndex(0);
            setGalleryExpanded(false);
        }

        return () => {
            subscriber?.unsubscribe();
        };
    }, []);

    console.log('selectedVar', selectedVariant);
    const handleScroll = (to: 'variants' | 'customiser' | 'images') => {
        setTimeout(() => {
            if (to === 'variants') {
                smoothScrollWrapper(variantOptionsRef.current, { behavior: 'smooth', block: 'end' });
                toast(() => (
                    <StyledText
                        style={TextStyle.Subheading}
                        size={TextSize.Small}
                        className={'productDisplayView__toast'}
                        translate
                    >
                        <i className={`fa-solid fa-triangle-exclamation`} />
                        <p>{t('product_view.toasts.variant_warning')}</p>
                    </StyledText>
                ));
            } else if (to === 'customiser') {
                smoothScrollWrapper(customiserRef.current, { behavior: 'smooth', block: 'end' });
                toast(() => (
                    <StyledText
                        style={TextStyle.Subheading}
                        size={TextSize.Small}
                        className={'productDisplayView__toast'}
                        translate
                    >
                        <i className={`fa-solid fa-triangle-exclamation`} />
                        <p>{t('product_view.toasts.customiser_warning')}</p>
                    </StyledText>
                ));
            } else if (to === 'images') {
                smoothScrollWrapper(imagesRef.current, { behavior: 'smooth', block: 'end' });
            }
        }, 50);

        return;
    };

    const handleSetSelectedVar = (selectedVar: FilterableProductVariant | undefined) => {
        handleSetVariant(selectedVar);
        let index = imageIndex;
        if (selectedVar) {
            index = productImages.findIndex(image => image.variantId === selectedVar?.id && !image.preferred);

            if (index === -1) {
                index = productImages.findIndex(image => image.variantId === selectedVar?.id);
            }
        }

        if (imageIndex !== index && index !== -1) {
            //Avoid setting the state if it is not needed
            setImageIndex(index);
        } else {
            console.info(
                'Image not being changed by handleSetSelectedVar. Found Index:',
                index,
                'VariantId: ',
                selectedVar?.id,
                productImages,
            );
        }
    };

    const handleSetSelectedPartialVar = (selectedVar: FilterableProductVariant | undefined) => {
        let index = imageIndex;
        if (selectedVar) {
            index = productImages.findIndex(image => image.variantId === selectedVar?.id && !image.preferred);
            if (index === -1) {
                index = productImages.findIndex(image => image.variantId === selectedVar?.id);
            }
            handleSetVariant(selectedVar);
        }

        if (imageIndex !== index && index !== -1) {
            //Avoid setting the state if it is not needed
            setImageIndex(index);
        } else {
            console.info(
                'Image not being changed by handleSetSelectedPartialVar. Found Index:',
                index,
                'VariantId: ',
                selectedVar?.id,
                productImages,
            );
        }
    };

    const suggestedItemsContent = useMemo(() => {
        if (suggestedProducts.length === 0) {
            return null;
        }

        return (
            <div className="productDisplayView__sections__one__content__child productDisplayView__sections__suggestedItems">
                <StyledText
                    style={TextStyle.Subheading}
                    size={TextSize.Medium}
                    className="productDisplayView__sections__suggestedItems__title"
                >
                    {t('you_might_like')}
                </StyledText>
                {suggestedProducts.length > 0 && (
                    <div
                        className="productDisplayView__sections__suggestedItems__list"
                        style={
                            {
                                // display: 'grid',
                                // gridGap: `${gridSizes.gutterSize * 1.15}px ${gridSizes.gutterSize}px`,
                                // gridAutoRows: `${Math.floor(gridSizes.rowSize)}px`,
                                // gridTemplateColumns: `repeat(auto-fit, ${Math.floor(gridSizes.columnSize)}px)`,
                                // paddingTop: gridSizes.gutterSize * 1.15,
                                // paddingBottom: gridSizes.gutterSize * 1.15,
                                // justifyContent: 'center',
                            }
                        }
                        ref={gridParentDivRef}
                    >
                        {suggestedProducts.map((product: FilterableProductWithCursor, index: number) => {
                            const overrideSize = isLandscape ? overrideSuggestedImageSize : 1;

                            const originalPrice = priceService.getFromPriceFromProduct(product);
                            const price = priceService.getFromPriceFromProduct(product);

                            let preferredImage = product.images.find(image => image.preferred);
                            if (!preferredImage && product.images.length !== 0) {
                                preferredImage = product.images[0];
                            }

                            let imageUrl: string | undefined = undefined;
                            const imageSizeFloored = Math.floor(getImageTileSize());
                            if (preferredImage) {
                                imageUrl = getSizedImageURL(
                                    preferredImage.url,
                                    imageSizeFloored,
                                    imageSizeFloored,
                                    configService.imageAnchor,
                                );
                            }

                            const bannerType: BannerType | undefined = getBannerType(product);

                            return (
                                <div
                                    style={{
                                        width: `${gridSizes.columnSize * overrideSize}px`,
                                        height: `${gridSizes.rowSize * overrideSize}px`,
                                    }}
                                >
                                    <ProductCard
                                        outsideGrid
                                        key={product.id}
                                        handle={product.handle}
                                        imageUrl={imageUrl}
                                        title={product.title}
                                        price={price}
                                        originalPrice={originalPrice}
                                        onClicked={() => {
                                            eventsService.setOpenProduct(product.handle);
                                        }}
                                        bannerType={bannerType}
                                        imageWidth={gridSizes.columnSize * overrideSize}
                                        tags={product.tags}
                                        metadata={product.metadata}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }, [suggestedProducts, gridSizes, isLandscape]);

    const viewContent = useMemo(() => {
        return (
            <>
                {_.compact(
                    _.map(configProvidedBuildingBlockOrder, (buildingBlock, index) => {
                        if (buildingBlock.__typename === 'PDPVariantsBlock') {
                            return (
                                <div key={buildingBlock + (productDetails?.handle ?? '') + 'container'}>
                                    <ProductVariants
                                        key={'VARS_' + buildingBlock + (productDetails?.handle ?? '')}
                                        variants={productDetails?.variants ?? []}
                                        onVariantSelected={handleSetSelectedVar}
                                        onMatchingPartialVariant={handleSetSelectedPartialVar}
                                        ref={variantOptionsRef}
                                    />
                                    <ProductCustomiserSection
                                        key={'PRODCUST_' + buildingBlock + (productDetails?.handle ?? '')}
                                        productHandle={openProductHandle ?? ''}
                                        metadata={metafields}
                                        onValidate={isValid => setFormValid(isValid)}
                                        onChange={(options, priceModifier) => {
                                            setCustomOptions(options);
                                            setProductCustomiserPriceModifier(priceModifier);
                                        }}
                                        showWarning={!formValid}
                                        ref={customiserRef}
                                        handoffFieldId={props.handoffCustomisationFieldId}
                                        showWarningsWithPristineData={forceShowWarnings}
                                    />
                                    <ProductBookThatAppSection
                                        key={'BTA_' + buildingBlock + (productDetails?.handle ?? '')}
                                        productHandle={openProductHandle ?? ''}
                                        metadata={metafields}
                                        onValidate={isValid => setBTAFormValid(isValid)}
                                        onChange={options => {
                                            setBTAOptions(options);
                                        }}
                                        showWarning={!formValid}
                                        ref={btaRef}
                                        showWarningsWithPristineData={forceShowWarnings}
                                        variantId={selectedVariant?.eCommercePlatformProvidedId}
                                        onLimitPurchaseQuantity={value => handleSetMaxPurchaseQuantity(value)}
                                    />
                                </div>
                            );
                        } else if (buildingBlock.__typename === 'PDPLabelsBlock') {
                            return (
                                <ProductLabels
                                    // product={productDetails!}
                                    variant={selectedVariant}
                                    metadata={metafields}
                                    tags={productTags}
                                />
                            );
                        } else if (buildingBlock.__typename === 'PDPSpacerBlock') {
                            return (
                                <StyledText
                                    style={TextStyle.Body}
                                    size={TextSize.Small}
                                    key={`PDPBlockSpacer:${index}` + (productDetails?.handle ?? '')}
                                >
                                    <br />
                                </StyledText>
                            );
                        } else if (buildingBlock.__typename === 'PDPDescriptionBlock') {
                            const cleanedContent = StringUtils.domSanitize(
                                productDetails?.description ?? '',
                                buildingBlock.removeThemeShortcodes,
                            );

                            if (_.isEmpty(cleanedContent)) {
                                return null;
                            }

                            return (
                                <ConditionalWrapper
                                    key={'PDPBlockDescription' + productDetails?.handle}
                                    existenceCondition={true}
                                    wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                    wrapper={children => (
                                        <Accordion
                                            title={buildingBlock.displayText}
                                            openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                            partialClose={buildingBlock.style === PdpBlockStyle.Expandable}
                                        >
                                            {children}
                                        </Accordion>
                                    )}
                                >
                                    <>
                                        {buildingBlock.style === PdpBlockStyle.Static && (
                                            <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                                {buildingBlock.displayText}
                                            </StyledText>
                                        )}
                                        <ProductDescription content={cleanedContent ?? ''} />
                                    </>
                                </ConditionalWrapper>
                            );
                        } else if (buildingBlock.__typename === 'PDPProductDataBlock') {
                            if (buildingBlock.productDataType === PdpProductDataType.Sku) {
                                return (
                                    <ConditionalWrapper
                                        key={'PDPBlockProductData:SKU' + productDetails?.handle}
                                        existenceCondition={!_.isEmpty(selectedVariant?.sku)}
                                        wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                        wrapper={children => (
                                            <Accordion
                                                title={buildingBlock.displayText}
                                                openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                            >
                                                {children}
                                            </Accordion>
                                        )}
                                    >
                                        <>
                                            {buildingBlock.style === PdpBlockStyle.Static && (
                                                <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                                    {buildingBlock.displayText}
                                                </StyledText>
                                            )}
                                            <ProductSKU content={selectedVariant?.sku} />
                                        </>
                                    </ConditionalWrapper>
                                );
                            }
                            if (buildingBlock.productDataType === PdpProductDataType.Barcode) {
                                return (
                                    <ConditionalWrapper
                                        key={'PDPBlockProductData:Barcode' + productDetails?.handle}
                                        existenceCondition={!_.isEmpty(selectedVariant?.barcode)}
                                        wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                        wrapper={children => (
                                            <Accordion
                                                title={buildingBlock.displayText}
                                                openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                            >
                                                {children}
                                            </Accordion>
                                        )}
                                    >
                                        <>
                                            {buildingBlock.style === PdpBlockStyle.Static && (
                                                <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                                    {buildingBlock.displayText}
                                                </StyledText>
                                            )}
                                            <ProductBarcode content={selectedVariant?.barcode} />
                                        </>
                                    </ConditionalWrapper>
                                );
                            } else if (buildingBlock.productDataType === PdpProductDataType.Vendor) {
                                return (
                                    <ConditionalWrapper
                                        key={'PDPBlockProductData:Vendor' + productDetails?.handle}
                                        existenceCondition={!_.isEmpty(productDetails?.vendor)}
                                        wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                        wrapper={children => (
                                            <Accordion
                                                title={buildingBlock.displayText}
                                                openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                            >
                                                {children}
                                            </Accordion>
                                        )}
                                    >
                                        <>
                                            {buildingBlock.style === PdpBlockStyle.Static && (
                                                <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                                    {buildingBlock.displayText}
                                                </StyledText>
                                            )}
                                            <ProductVendor content={productDetails?.vendor} />
                                        </>
                                    </ConditionalWrapper>
                                );
                            }
                        } else if (buildingBlock.__typename === 'PDPMetadataBlock') {
                            const metafieldForBlock = _.find(
                                metafields,
                                metafield => metafield.key === buildingBlock.key,
                            );
                            return (
                                <ConditionalWrapper
                                    key={`PDPBlockMetafield:${buildingBlock.key}` + productDetails?.handle}
                                    existenceCondition={metafieldForBlock !== undefined}
                                    wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                    wrapper={children => (
                                        <Accordion
                                            title={buildingBlock.displayText}
                                            openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                        >
                                            {children}
                                        </Accordion>
                                    )}
                                >
                                    <>
                                        {buildingBlock.style === PdpBlockStyle.Static && (
                                            <StyledText style={TextStyle.Subheading} size={TextSize.Medium}>
                                                {buildingBlock.displayText}
                                            </StyledText>
                                        )}
                                        <ProductMetafield
                                            content={metafieldForBlock?.data}
                                            displayType={buildingBlock.metafieldDisplayType}
                                        />
                                    </>
                                </ConditionalWrapper>
                            );
                        } else {
                            console.error('Unknown PDP block type', buildingBlock.__typename);
                        }
                    }),
                )}
            </>
        );
    }, [productDetails, selectedVariant, metafields, forceShowWarnings]);

    let allVariantsUnavailable = true;
    if (productDetails?.variants.some(v => v.availableForSale)) {
        allVariantsUnavailable = false;
    }
    return (
        <SlidingModal
            className={'productDisplayView__zIndex'}
            isOpen={isOpen}
            origin={SlidingModalOrigin.BOTTOM}
            fullHeight
            fullWidth
        >
            <Toaster
                position="bottom-center"
                toastOptions={{
                    style: {
                        backgroundColor: '#FB9600',
                        border: '1px solid #E8E8E8',
                        borderRadius: 100,
                        padding: '1rem',
                    },
                    duration: 2500,
                }}
                containerClassName={'productDisplayView__toasterContainer'}
                containerStyle={{
                    bottom: props.hideCartOptions ? 32 : 140,
                }}
            />
            <div className={`productDisplayView ${galleryExpanded ? 'productDisplayView__vertical__noScroll' : ''}`}>
                <div className="productDisplayView__sections">
                    <div className="productDisplayView__sections__sideBySide">
                        <div
                            className={`productDisplayView__sections__sideBySide__left ${
                                suggestedItemsContent === null
                                    ? 'productDisplayView__sections__sideBySide__left__full'
                                    : ''
                            }  ${galleryExpanded ? 'productDisplayView__sections__sideBySide__left__full' : ''}`}
                        >
                            <div
                                className={`productDisplayView__sections__one ${
                                    galleryExpanded ? 'productDisplayView__sections__one__fullscreen' : ''
                                }`}
                            >
                                <div className={`productDisplayView__sections__one__content`}>
                                    <div
                                        className={`productDisplayView__sections__one__content__child productDisplayView__sections__images ${
                                            galleryExpanded ? 'productDisplayView__sections__images__fullscreen' : ''
                                        }`}
                                    >
                                        <ProductImageCarousel
                                            images={productImages}
                                            currentImageIndex={imageIndex}
                                            isExpanded={galleryExpanded}
                                            setIsExpanded={isExpanded => {
                                                if (loading) {
                                                    return;
                                                }
                                                setGalleryExpanded(isExpanded);
                                            }}
                                            scroll={handleScroll}
                                            loading={loading}
                                            controlBar={
                                                <div className="productDisplayView__sections__controlBar">
                                                    <div className="productDisplayView__sections__controlBar__section">
                                                        {/* {!galleryExpanded && (
                                                        <button
                                                            className="Menu__roundButton"
                                                            onClick={() => menuService.fireBackTapped()}
                                                        >
                                                            <ArrowBackIcon />
                                                        </button>
                                                    )} */}
                                                        <button
                                                            className="Menu__roundButton"
                                                            onClick={() => {
                                                                setGalleryExpanded(!galleryExpanded);
                                                            }}
                                                        >
                                                            {galleryExpanded ? <ShrinkIcon /> : <ExpandIcon />}
                                                        </button>
                                                    </div>
                                                    <div className="productDisplayView__sections__controlBar__section">
                                                        {props.hideCartOptions ? null : (
                                                            <ProductCartOptions
                                                                product={productDetails!}
                                                                selectedVariant={selectedVariant}
                                                                allVariantsUnavailable={allVariantsUnavailable}
                                                                scroll={to => handleScroll(to)}
                                                                formValid={formValid && btaFormValid}
                                                                customOptions={mergedCustomAttributes}
                                                                productCustomiserPriceModifier={
                                                                    productCustomiserPriceModifier.current
                                                                }
                                                                onCheckoutPressed={() => setForceShowWarnings(true)}
                                                                maxQuantity={maxPurchaseQuantity}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        ></ProductImageCarousel>
                                    </div>
                                    {isLandscape && suggestedItemsContent}
                                </div>
                                <div
                                    className={`productDisplayView__sections__top ${
                                        galleryExpanded ? 'productDisplayView__sections__top__hidden' : ''
                                    }`}
                                >
                                    <div className="productDisplayView__sections__top__split">
                                        <div className="productDisplayView__sections__top__split__content"></div>
                                        <div className="productDisplayView__sections__top__split__content">
                                            <ProductTitle
                                                className={'productDisplayView__Title'}
                                                content={productDetails?.title}
                                            />
                                            <ProductPrice
                                                priceString={priceString}
                                                originalPriceString={originalPriceString}
                                            />
                                            <ProductAvailability
                                                selectedVariant={selectedVariant}
                                                productDetails={productDetails}
                                                allVariantsUnavailable={
                                                    !productDetails?.variants.every(variant => variant.availableForSale)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`productDisplayView__sections__two ${
                                    galleryExpanded ? 'productDisplayView__sections__two__hidden' : ''
                                }`}
                            >
                                <div className="productDisplayView__sections__two__container">{viewContent}</div>
                            </div>
                        </div>

                        {!isLandscape && suggestedItemsContent && (
                            <div
                                className={`productDisplayView__sections__sideBySide__right  ${
                                    galleryExpanded ? 'productDisplayView__sections__sideBySide__right__hidden' : ''
                                }`}
                            >
                                {suggestedItemsContent}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </SlidingModal>
    );
});

export default ProductDetailsView;
